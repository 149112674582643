import React, { useEffect, useState } from "react";
import {
  useNavigate,
  Route,
  Routes,
  Link,
  BrowserRouter,
} from "react-router-dom";
import { TabTitle } from "../../../shared/GenralFunctioin/TableTitle";
import BubbleChart from "@testboxlab/react-bubble-chart-d3";
import ReactFC from "react-fusioncharts";
import { Bar } from "react-chartjs-2";
// Include the fusioncharts library
import FusionCharts from "fusioncharts";
import { ErrorNotify, SuccessNotify } from "../../../shared/Toast";
// Include the chart type
import Column2D from "fusioncharts/fusioncharts.charts";
// Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import Widgets from "fusioncharts/fusioncharts.widgets";
ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme, Widgets);

const Dashboard4 = ({
  custEffectScoreAttr,
  chart12status,
  typesOfExcalations,
  setAppDataForTable,
  setLoaderSubCat,
  fromDate1,
  toDate1,
}) => {
  
  console.log("custEffectScoreAttr", typesOfExcalations?.data);
  
  const base_url = process.env.REACT_APP_AUTH_BASE_URL;
  TabTitle("Dashboard :: NEQQO");
  var Bar = {
    type: "radar",
    renderAt: "chart-container",
    width: "100%",
    height: "384",
    dataFormat: "json",
    dataSource: {
      chart: {
        // caption: "Customer Effort Score Attribute",
        // subcaption: "For 2017",
        // showLegend: true,
        theme: "fusion",
        legendNumRows: 2,
        legendNumColumns: 3,
        legendShadow: 1,
        numbersuffix: "%",
        plotfillalpha: "1",
        plotBorderAlpha: "90",
        plotBorderThickness: "3",
        showPlotBorder: 1,
        showRadarBorder: 1,
      },
      categories:
        custEffectScoreAttr?.categories?.length > 0
          ? custEffectScoreAttr?.categories
          : [{ category: { label: "No data Available" } }],
      dataset:
        custEffectScoreAttr?.dataset?.length > 0
          ? custEffectScoreAttr?.dataset
          : [],
      // {
      // "categories": [
      //     {
      //         "category": [
      //             {
      //                 "label": "High Call Duration"
      //             },
      //             {
      //                 "label": "High Silence"
      //             },
      //             {
      //                 "label": "Repeat Callers"
      //             },
      //             {
      //                 "label": "Broken Promises"
      //             },
      //             {
      //                 "label": "Transfers"
      //             },
      //             {
      //                 "label": "Escalations"
      //             }
      //         ]
      //     }
      // ],
      // "dataset": [
      //     {
      //         "data": [
      //             {
      //                 "value": 25.05
      //             },
      //             {
      //                 "value": 25.05
      //             },
      //             {
      //                 "value": 0
      //             },
      //             {
      //                 "value": 0
      //             },
      //             {
      //                 "value": 0
      //             },
      //             {
      //                 "value": 0.2
      //             }
      //         ]
      //     }
      // ]
    },
  };
  var Bar2 = {
    type: "treemap",
    width: "100%",
    height: "440",
    dataFormat: "json",
    dataSource: {
      chart: {
        caption:
          typesOfExcalations?.label !== undefined
            ? typesOfExcalations?.label
            : "",
        // captionOnTop:applicationCategory?.label !== undefined ? 1 : 0,
        captionAlignment:
          typesOfExcalations?.label !== undefined ? "" : "center",
        animation: "1",
        plotToolText: `<div><b>$label</div>`,
      
        

       

        horizontalPadding: "1",
        verticalPadding: "1",
        plotborderthickness: ".5",
        plotbordercolor: "b3b3b3",
        showParent: 0,
        chartBottomMargin: "0",
        labelGlow: "0",
        labelFontColor: "#fffff",
        labelFont: "Source Sans Pro",
        labelFontSize: "12",
        showchildlabels: "1",
        algorithm: "squarified",
        slicingmode: "vertical",
        theme: "fusion",
      },
      data: typesOfExcalations !== undefined ? [typesOfExcalations] : [],
      colorrange: {
        code: "0889d6",
        color: [
          {
            code: "0889d6",
            maxvalue:
              typesOfExcalations?.value !== undefined
                ? typesOfExcalations?.value
                : "100",
          },
          {
            code: "032c45",
            maxvalue:
              typesOfExcalations?.value !== undefined
                ? typesOfExcalations?.value / 2
                : "1",
          },
        ],
      },
    },
    events: {
      dataplotClick: async function (eventObj, dataObj) {
        setAppDataForTable && setAppDataForTable([]);
        window.scroll({
          top: 3082,
          behavior: "smooth",
        });
        // Handle the click event here
        var clickedData = dataObj.categoryLabel;



        let url =""
        let payloadForTable={}
        if(window.location.href?.split("/")[window.location.href?.split("/")?.length-1]==="smartspeech-dashboard-new"){
           url = `${base_url}smartspeechtags/tag-file-list`;
        
        
         payloadForTable = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("loginToken"),
          },
          body: JSON.stringify({
            tag: typesOfExcalations?.data?.filter(
              (i) => i?.label === dataObj?.name
            )[0]?.tag_id,
            category_id: typesOfExcalations?.data?.filter(
              (i) => i?.label === dataObj?.name
            )[0]?.cat_id,
            folder_id: typesOfExcalations?.folder_id,
            overall_from_date: fromDate1,
            overall_to_date: toDate1,
          }),
        };
      }
      else{
        url = `${base_url}smartspeech/application-category-file-list`;
        payloadForTable = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("loginToken"),
          },
          body: JSON.stringify({
            application: typesOfExcalations?.application_id,
            category_id: typesOfExcalations?.data?.filter(
              (i) => i?.label === dataObj?.name
            )[0]?.cat_id,
            folder_id: typesOfExcalations?.folder_id,
            overall_from_date: fromDate1,
            overall_to_date: toDate1,
          }),
        };
      }

        await fetch(url, payloadForTable)
          .then((res) => res.json())
          .then((data) => {
            if (data?.status == 200 || data?.status == 201) {
              if (data?.data?.length > 0) {
                setAppDataForTable(data?.data);
                setLoaderSubCat(true);
                setTimeout(() => {
                  window.scroll({
                    top: 4582,
                    behavior: "smooth",
                  });
                }, 0);
              }
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.error, {
                  icon: "error",
                });
                return;
              }
            } else {
              ErrorNotify(data.error, {
                icon: "error",
              });
              return;
            }
            console.log("RSSSSSSSSSSS", data);
          })
          .catch((err) => {
            return ErrorNotify(err);
          });
        console.log(
          "Clicked Data: ",
          typesOfExcalations?.data?.filter((i) => i?.label === dataObj?.name)
        );
        // Add your logic to do something with the clicked data
      },
    },
  };
  const navigate = useNavigate();

  const url = new URL(window.location.href);
  const pathname = url.pathname; // contains "/register"

  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    // localStorage.clear()
    const loggedInUser = localStorage.getItem("loginToken");
    // console.log('dashboard loggedInUser===========', loggedInUser)
    if (loggedInUser) {
      // navigate("/");
      // console.log('=======', loggedInUser);
      setAuthenticated(loggedInUser);
    }
  }, []);

  const bubbleClick = (label) => {
    console.log("Custom bubble click func");
  };
  const legendClick = (label) => {
    console.log("Customer legend click func");
  };
  return (
    <>
      <div class="ip-section mt-5">
        <div class="container-fluid">
          <div class="form-row">
            {console.log("BCHJFIURUIURUIUIRUIRIU",Object.keys(typesOfExcalations)?.length)
            }
            <div className="col-md-6 col-lg-6 col-sm-6">
              {typesOfExcalations !== undefined &&
              Object.keys(typesOfExcalations)?.length > 0 ? (
                <ReactFC {...Bar2} />
              ) : typesOfExcalations !== undefined &&
                Object.keys(typesOfExcalations)?.length === 0 && 
               
                
                <div style={{ backgroundColor: "white", height: "440px" }}>
                  {console.log("DJDJKJDJHFHFHFHFHJHFF")
                  }

                  <div className="row">
                    <div className="col-md-6 col-lg-6 col-sm-6"></div>

                    <div className="col-md-1 col-lg-1 col-sm-1">
                      <div
                        class="spinner-border"
                        role="status"
                        style={{ marginTop: "240px" }}
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>

                    <div className="col-md-5 col-lg-5 col-sm-5"></div>
                  </div>
                </div>
              }
            </div>
            <div className="col-md-6 col-lg-6 col-sm-6">
              <div style={{ background: "#fff", height: "440px" }}>
                <div className="chart-heading">
                  Customer Effort Score Attributes
                </div>

                <div className="chart-main">
                  {chart12status && (
                    <div className="chart-loader">
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  )}
                  <ReactFC {...Bar} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
    // <div className="page-wrapper" style={{ backgroundImage: "none",height:"530px" }}>
    //   <div className="container-fluid row">
    //     <div className="col-md-6 col-lg-6 col-sm-6">
    //     <ReactFC {...Bar2} />

    //     </div>
    //     <div className="col-md-6 col-lg-6 col-sm-6">
    //     <ReactFC {...Bar} />
    //     </div>
    //   </div>
    //   <div style={{marginTop:"22px"}}>

    //   </div>
    // </div>
  );
};

export default Dashboard4;
