import React, { useEffect, useState } from "react";
import {
  useNavigate,
  Route,
  Routes,
  Link,
  BrowserRouter,
} from "react-router-dom";
import { TabTitle } from "../../../shared/GenralFunctioin/TableTitle";
import Dashboard1 from "./Dashboard1";
import Dashboard2 from "./Dashboard2";
import Dashboard3 from "./Dashboard3";
import Dashboard4 from "./Dashboard4";
import Dashboard5 from "./Dashboard5";
import Dashboard6 from "./Dashboard6";
import globeIconImg from "./globe-icon-white.png";
import NewDashboardFilter from "./NewDashboardFilter";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { he } from "faker/lib/locales";
import OverlayPopover from "../../../controls/OverlayPopover";
import Popover from "react-bootstrap/Popover";

// import { Popover } from "bootstrap";

const BaseDashboard = (props) => {
  TabTitle("Dashboard :: NEQQO");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [emotionsData, setEmotionsData] = useState([]);
  const [duratioData, setDurationData] = useState([]);
  const [overallData, setOverallData] = useState([]);
  const [applicationCategory, setApplicationCategory] = useState([]);
  const [custEffectScoreAttr, setCustEffectScoreAttr] = useState([]);
  const [dynamicEmotionHeading, setDynamicEmotionHeading] = useState("All");
  const [categorizationData, setCategorizationData] = useState("");
  const [customerScoreData, setCustomerScoreData] = useState([]);
  const [detractorsData, setDetractorsData] = useState([]);
  const [escalationsData, setEscalationsData] = useState([]);
  const [IdOfFolder, setIdOfFolder] = useState("");
  const [appListData, setAppListData] = useState([]);
  const [callDriverTrendData, setCallDriverTrendData] = useState([]);

  const [catListData, setCatListData] = useState([]);
  const [catReportData, setCatReportData] = useState([]);

  const [typesOfExcalations, setTypesOfExcalations] = useState([]);
  const [detractorsHotWordsData, setDetractorsHotWordsData] = useState(undefined);
  const [excalationsHotWordsData, setExcalationsHotWordsData] = useState(undefined);
  const [filterPayload, setFilterPayload] = useState([]);
  const [chart1status, setChart1status] = useState(true);
  const [chart5status, setChart5status] = useState(true);
  const [chart6status, setChart6status] = useState(true);
  const [chart12status, setChart12status] = useState(true);

  const [fromDateForApp, setFromDateForApp] = useState(
    moment().clone().startOf("month").format("YYYY-MM-DD")
  );
  const [toDateForApp, setToDateForApp] = useState(
    moment().format("YYYY-MM-DD")
  );

  const [fromDate1, setFromDate1] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [toDate1, setToDate1] = useState(moment().format("YYYY-MM-DD"));

  const [appDataForTable, setAppDataForTable] = useState([]);

  const [loaderSubCat, setLoaderSubCat] = useState(false);

  const [endDuration, setEndDuration] = useState("");
  const [startDuration, setStartDuration] = useState("");
  const [filteredFolders, setFilteredFolders] = useState([]);

  const navigate = useNavigate();

  const url = new URL(window.location.href);
  const pathname = url.pathname; // contains "/register"

  const [authenticated, setAuthenticated] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const toggleDrawer = (val) => {
    console.log("toggleDrawer", val);
    setIsDrawerOpen(val);
  };

  return (
    <div
      className="page-wrapper"
      style={{ backgroundImage: "none", height: "auto" }}
    >
      <main>
        <ToastContainer />
        <div className="main-contents">
          <div className="ip-header sc-head sc-collapse">
            <div className="container-fluid">
              <div className="sc-header d-flex justify-content-between align-items-center">
                <h3 className="sc-title d-flex align-items-center">
                <span>SmartSpeech Tag Dashboard</span>
                </h3>
                <div className="sc-controls">
                <OverlayPopover
                    trigger="hover"
                    key="bottom"
                    placement="bottom"
                    rootClose={true}
                    overlay={
                      <Popover>
                        <Popover.Header as="h2">
                          Aplication Dashboard
                        </Popover.Header>
                      </Popover>
                    }
                  >
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => navigate("/smartspeech-app-dashboard")}
                    >
                      Switch to Application Dashboard &nbsp;
                       {/* <img
                        src={globeIconImg}
                        style={{ height: "17px" }}
                        alt="Redirect to Tag Dashboard"
                      /> */}
                      <i class="las la-random" style={{fontSize:"15px"}}></i>
                    </button>
                  </OverlayPopover>
                  <OverlayPopover
                    trigger="hover"
                    key="bottom"
                    placement="bottom"
                    rootClose={true}
                    overlay={
                      <Popover>
                        <Popover.Header as="h2">
                          Universal Dashboard
                        </Popover.Header>
                      </Popover>
                    }
                  >
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => navigate("/universal-dashboard")}
                    >
                      Switch to &nbsp;
                      <img
                        src={globeIconImg}
                        style={{ height: "17px" }}
                        alt="Redirect to Universal Form Dashboard"
                      />
                    </button>
                  </OverlayPopover>

                  <button
                    type="button"
                    className="btn btn-circle-primary filter-btn drawer-open"
                    onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                    data-target="filter_drawer"
                  >
                    <i className="las la-filter"></i>
                  </button>
                  {/* <button className="btn btn-primary">
                  Export
                </button> */}
                </div>
              </div>
            </div>
          </div>
          <NewDashboardFilter
            toggleDrawer={toggleDrawer}
            isDrawerOpen={isDrawerOpen}
            setEmotionsData={setEmotionsData}
            setDurationData={setDurationData}
            setOverallData={setOverallData}
            setApplicationCategory={setApplicationCategory}
            setDynamicEmotionHeading={setDynamicEmotionHeading}
            setCategorizationData={setCategorizationData}
            setCustEffectScoreAttr={setCustEffectScoreAttr}
            setCustomerScoreData={setCustomerScoreData}
            setDetractorsData={setDetractorsData}
            setFilterPayload={setFilterPayload}
            setCallDriverTrendData={setCallDriverTrendData}
            setEscalationsData={setEscalationsData}
            setFilterData={setFilterData}
            setIdOfFolder={setIdOfFolder}
            setAppListData={setAppListData}
            setTypesOfExcalations={setTypesOfExcalations}
            setDetractorsHotWordsData={setDetractorsHotWordsData}
            setExcalationsHotWordsData={setExcalationsHotWordsData}
            setChart1status={setChart1status}
            setChart5status={setChart5status}
            setChart6status={setChart6status}
            setChart12status={setChart12status}
            setFromDateForApp={setFromDateForApp}
            setToDateForApp={setToDateForApp}
            setFromDate1={setFromDate1}
            setToDate1={setToDate1}
            fromDate1={fromDate1}
            toDate1={toDate1}
            endDuration={endDuration}
            startDuration={startDuration}
            setEndDuration={setEndDuration}
            setStartDuration={setStartDuration}
            filteredFolders={filteredFolders}
            setFilteredFolders={setFilteredFolders}
          />
          <Dashboard1
            filterData={filterData}
            chart1status={chart1status}
            overallData={overallData}
            emotionsData={emotionsData}
            duratioData={duratioData}
            dynamicEmotionHeading={dynamicEmotionHeading}
          />
          <Dashboard2
            filterData={filterData}
            setAppDataForTable={setAppDataForTable}
            setLoaderSubCat={setLoaderSubCat}
            chart5status={chart5status}
            fromDate1={fromDate1}
            toDate1={toDate1}
            chart6status={chart6status}
            callDriverTrendData={callDriverTrendData}
            applicationCategory={applicationCategory}
            categorizationData={categorizationData}
          />
          <Dashboard3
            filterData={filterData}
            customerScoreData={customerScoreData}
            detractorsData={detractorsData}
            escalationsData={escalationsData}
          />
          <Dashboard4
            filterData={filterData}
            setAppDataForTable={setAppDataForTable}
            setLoaderSubCat={setLoaderSubCat}
            fromDate1={fromDate1}
            toDate1={toDate1}
            chart12status={chart12status}
            custEffectScoreAttr={custEffectScoreAttr}
            typesOfExcalations={typesOfExcalations}
          />
          <Dashboard5
            filterData={filterData}
            detractorsHotWordsData={detractorsHotWordsData}
            excalationsHotWordsData={excalationsHotWordsData}
          />
          <Dashboard6
            filterData={filterData}
            filterPayload={filterPayload}
            loaderSubCat={loaderSubCat}
            setLoaderSubCat={setLoaderSubCat}
            appDataForTable={appDataForTable}
            IdOfFolder={IdOfFolder}
            fromDate1={fromDate1}
            toDate1={toDate1}
            endDuration={endDuration}
            startDuration={startDuration}
            appListData={appListData}
            setFromDateForApp={setFromDateForApp}
            setToDateForApp={setToDateForApp}
            setAppListData={setAppListData}
            fromDateForApp={fromDateForApp}
            toDateForApp={toDateForApp}
            filteredFolders={filteredFolders}
          />
        </div>
      </main>
    </div>
  );
};

export default BaseDashboard;
