import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import CustomLevel from "./CustomLevel";
import Select from "react-select";

import moment from 'moment';
import { ErrorNotify, SuccessNotify } from "./Toast";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;

const FilterRecords = (props) => {

    const [monthDate, setMonthDate] = useState((new Date()).toLocaleString());

    const [hierarchyData, setHierarchyData] = useState([]);
    const [formData, setFormData] = useState([]);
    const [toDateDisabled, setToDateDisabled] = useState(true);
    const [defaultFormVal, setDefaultFormVal] = useState("");

    const [c1Data, setC1Data] = useState([]);
    const [c2Data, setC2Data] = useState([]);
    const [c3Data, setC3Data] = useState([]);
    const [c4Data, setC4Data] = useState([]);

    const [defaultValueC1, setdefaultValueC1] = useState('');
    const [defaultValueC2, setdefaultValueC2] = useState('');
    const [defaultValueC3, setdefaultValueC3] = useState('');
    const [defaultValueC4, setdefaultValueC4] = useState('');
    const [timeRange, setTimeRange] = useState();

    const [fromDate, setFormDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [dateType, setDateType] = useState({ value: 'call_date', label: 'Call Date' });
    const [dateRange, setDateRange] = useState({ value: 'custom', label: 'Custom' });
    const [dateRangeValue, setDateRangeValue] = useState([]);
    const [formDefaultVal, setFormDefaultVal] = useState();


    //
    const [affiliation, setAffiliation] = useState('');
    const [c1, setC1] = useState('');
    const [c2, setC2] = useState('');
    const [c3, setC3] = useState('');
    const [c4, setC4] = useState('');
    const [form, setForm] = useState('');
    const [manager2, setManager2] = useState([]);
    const [manager1, setManager1] = useState([]);
    const [supervisor, setSupervisor] = useState([]);
    const [supervisorId, setSupervisorId] = useState([]);
    const [formValVersion, setFormValVersion] = useState("");
    const [defaultForm, setDefaultForm] = useState("");

    const [dayWise, setDayWise] = useState([{ value: "Select", label: "Select" }, { value: "today", label: "Today" }, { value: "last_1_day", label: "Last 1 Day" }, { value: "last_2_days", label: "Last 2 Days" }]);
    const [weekly, setWeekly] = useState([{ value: "Select Week", label: "Select Week" }, { value: "week_to_date", label: "Week To Date" }, { value: "last_week", label: "Last Week" }, { value: "last_2_week", label: "Last 2 Week" }, { value: "last_3_weeks", label: "Last 3 Weeks" }]);
    const [monthly, setMonthly] = useState([{ value: "Select Month", label: "Select Month" }, { value: "month_to_date", label: "Month To Date" }, { value: "last_month", label: "Last Month" }, { value: "last_2_months", label: "Last 2 Months" }, { value: "last_3_months", label: "Last 3 Months" }]);

    const [agent, setAgent] = useState([]);
    const [callId, setCallId] = useState('');
    const [agentId, setAgentid] = useState([]);

    const [manager2Val, setManager2Val] = useState('');
    const [manager1Val, setManager1Val] = useState('');

    const [isDataFound, setIsDataFound] = useState(false);
    const [myEvaluation, setMyEvaluation] = useState(false);
    const [isSetAsDefault, setIsSetAsDefault] = useState(false);
    const [defaultFormData, setDefaultFormData] = useState("");
    

    




    useEffect(() => {

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
            })
        };

        // var url = `${base_url}calls/getallcalls`;
        // callApi(url, requestOptions, 'call-data');

        var url = `${base_url}hierarchy/get-custom-hierarchy`;
        callApi(url, requestOptions, 'hierarchy-data');

        var url = `${base_url}custom/1`;
        callApi(url, requestOptions, 'custom-1');

    }, [fromDate, toDate]);

    const callApi = (url, requestOptions, purpose) => {
        console.log("KKKKKKKKKKKKKKKKK");
        fetch(url, requestOptions).then(res => res?.json())?.then(data => {

            if (data?.status != 200) {
                swal(data?.message);
                return;
            }
            

            // if (purpose === 'call-data') {
            //     setCallListData(data.data);
            // }

            if (purpose === 'hierarchy-data') {

                var arr = [];

                data.data.map((i, j) => {
                    arr[i.custom1_id] = i.custom1_name;
                    arr[i.custom2_id] = i.custom2_name;
                    arr[i.custom3_id] = i.custom3_name;
                    arr[i.custom4_id] = i.custom4_name;
                });

                setHierarchyData(arr);

            }
            if (purpose === 'custom-1') {
                // setIsC1Data(true);
                setC1Data(data.data);
                // setIsSupDisabled(false);                
            }
        }).catch(err => {
            console.log(err);
        });

    }

    const handleC2Change = (e) => {

        var url = `${base_url}custom/2`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                custom1: e,
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('c2 data======', data);
            if (data.status != 200) {
                ErrorNotify(data.message);
                return;
            }
            setdefaultValueC1(e);
            setC2Data(data.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const handleC3Change = (e) => {

        var url = `${base_url}custom/3`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                custom1: defaultValueC1,
                custom2: e
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('c3 data======', data);
            if (data.status != 200) {
                ErrorNotify(data.message);
                return;
            }
            setdefaultValueC2(e);
            setC3Data(data.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const handleC4Change = (e) => {

        var url = `${base_url}custom/4`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                custom1: defaultValueC1,
                custom2: defaultValueC2,
                custom3: e,
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('c3 data======', data);
            if (data.status != 200) {
                ErrorNotify(data.message);
                return;
            }
            setdefaultValueC3(e);
            setC4Data(data.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const handleC5Change = (e) => {

        var url = `${base_url}calls/get-form-name`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                c1: defaultValueC1,
                c2: defaultValueC2,
                c3: defaultValueC3,
                c4: e,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
            
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('form data======', data);
            // if (data.status != 200) {
            //     alert(data.message);
            //     return;
            // }
            // setdefaultValueC3(e);
            setFormData(prevState => [
                ...prevState,
                data.data
            ]);
            setdefaultValueC4(e);
            // console.log('-------------', data.data)
        }).catch(err => {
            console.log(err);
        });
    }

    const getC1Value = (val) => {
        setdefaultValueC1(val);
    }

    const getC2Value = (val) => {
        setdefaultValueC2(val);
    }

    const getC3Value = (val) => {
        setdefaultValueC3(val);
    }

    const getC4Value = (val) => {
        console.log('getC4Value val==========', val)
        setdefaultValueC4(val);
    }

    const getFormData = (val) => {
        setForm(val);
        setFormData(val);
    }

    const getManager2 = (val) => {
        setManager2Val(val);
        // setManager2(val);
    }

    const getManager1 = (val) => {
        setManager1Val(val);
    }

    const getSupervisor = (val) => {
        setSupervisor(val);

    }

    const getAgent = (val) => {
        setAgent(val);
    }

    const handleDateType = (data) => {
        setDateType(data)
    }

    const handleFilter = (e) => {
        setIsDataFound(true);
        e.preventDefault();

        if (fromDate === '') {
            ErrorNotify('Please select from date');
            return;
        }

        if (toDate === '') {
            ErrorNotify('Please select to date');
            return;
        }

        if (dateType.value === '') {
            ErrorNotify('Please select date type');
            return;
        }

        // if (defaultValueC1 === '') {
        //     ErrorNotify('Please select LOB value');
        //     return
        // }

        // if (defaultValueC2 === '') {
        //     ErrorNotify('Please select Campaign value');
        //     return
        // }


        // if (defaultValueC3 === '') {
        //     ErrorNotify('Please select Vendor value');
        //     return
        // }

        // if (defaultValueC4 === '') {
        //     ErrorNotify('Please select Location value');
        //     return
        // }


        // if(!manager2Val.length || manager2Val === '') {
        //     ErrorNotify('Please select manager2 value');
        //     return;
        // }

        var url = `${base_url}${props?.uri}`;

        let payload = {
            quick_search:
              props.call_id !== undefined || props.agent_id !== undefined
                ? true
                : false,
            from_date:
              props.call_id !== undefined || props.agent_id !== undefined
                ? ""
                : fromDate,
            to_date:
              props.call_id !== undefined || props.agent_id !== undefined
                ? ""
                : toDate,
            date_type:
              props.call_id !== undefined || props.agent_id !== undefined
                ? ""
                : dateType.value,
            affiliation: "",
            custom1: defaultValueC1,
            custom2: defaultValueC2,
            custom3: defaultValueC3,
            custom4: defaultValueC4,
            form: form?.length > 0 ? typeof form[0] === "object" || "string" ? [] : form?.map((i) => i) : '',
            manager2: manager2Val,
            manager1: manager1Val,
            supervisor: supervisorId,
            summary_type:((props?.uri == "uk-dispute-listing" && props?.fromPage == "dispute") || (props?.uri == "uk-dispute-qamanager-listing" && props?.fromPage == "dispute") || (props?.uri == "uk-dispute-reviewer-listing" && props?.fromPage == "dispute")) ? 0 : props?.uri == "uk-dispute-listing" && props?.fromPage == "eval-summary" ? 1 : props?.uri == "available-audits" || props?.uri == "my-audits" || props?.uri == "audits-history" ? 1 : undefined,
            card_type: "",
            agent: agentId === "" ? [] : agentId,
            call_id: props.call_id !== "" ? props.call_id : "",
            agent_id: props.agent_id !== "" ? props.agent_id : "",
            proxy_user_id:
              localStorage.getItem("settedProxy") !== null &&
              localStorage.getItem("settedProxy") !== undefined
                ? JSON.parse(localStorage.getItem("settedProxy"))?.value
                : "",
            proxy_by:
              localStorage.getItem("settedProxy") !== null &&
              localStorage.getItem("settedProxy") !== undefined
                ? localStorage.getItem("loginUserUniqueid")
                : "",
          };
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("loginToken"),
            },
            body: JSON.stringify(payload)
          };
          props?.setFilterPayload && props?.setFilterPayload(payload)
        fetch(url, requestOptions).then(res => res.json()).then(data => {

            console.log('filter data======', data);
            if (data.status != 200) {
                ErrorNotify(data.message);
                setTimeout(() => {
                    setIsDataFound(false);
                    props?.setIsDrawerOpen && props?.setIsDrawerOpen(false)
                }, 6000);
                return;
            }
            if (data.status == 200) {
                props?.setCardData && props?.setCardData(data.card_data);
                props?.setListingDataFunc && props?.setListingDataFunc(data.data);
                setTimeout(() => {
                    props?.setMyAuditData && props?.setMyAuditData(data?.data)
                    props?.setAuditHistoryData && props?.setAuditHistoryData(data?.data)
                    setIsDataFound(false);
                    props?.setIsDrawerOpen && props?.setIsDrawerOpen(false)

                    //document.querySelector('#filter_drawer').style.display='none'
                }, 2000);
            }
            setTimeout(() => {
                setIsDataFound(false);
                props?.setIsDrawerOpen && props?.setIsDrawerOpen(false)
                // document.querySelector('#filter_drawer').style.display='block'
            }, 2000);
            // if (data.status != 200) {
            //     alert(data.message);
            //     return;
            // }
            // setdefaultValueC3(e);
            // setFormData(prevState => [
            //     ...prevState,
            //     data.data
            // ]);
            // setdefaultValueC4(e);
            // console.log('-------------', data.data)
        }).catch(err => {
            setIsDataFound(false);
            console.log(err);
        });
    }


    const dashBoardFilter = (e) => {
        console.log("formValVersion",formValVersion);
        console.log("defaultFormData",defaultFormData);
        setIsDataFound(true)
        let cartUrl = `${base_url}dashboard/card-data`
        let qaScoreUrl = `${base_url}dashboard/qa-score`
        let evaluationSummaryUrl = `${base_url}dashboard/evaluation-summary`
        let autofailURL = `${base_url}dashboard/auto-fail`
        let categoryPerformanceUrl = `${base_url}dashboard/category-performance`
        let agentRangeUrl = `${base_url}dashboard/agent-range`
        let locationSiteUrl = `${base_url}dashboard/location-wise`
        let agentPerformanceUrl = `${base_url}dashboard/agent-performace`
        let lobWisePerformanceUrl = `${base_url}dashboard/lob-wise`
        console.log("formData111", defaultFormData);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                quick_search: ((props.call_id !== undefined || props.agent_id !== undefined) ? true : false),
                start_date: (props.call_id !== undefined || props.agent_id !== undefined ? '' : fromDate),
                end_date: (props.call_id !== undefined || props.agent_id !== undefined ? '' : toDate),
                date_type: (props.call_id !== undefined || props.agent_id !== undefined ? '' : dateType.value),
                daterange: dateRange?.value,
                affiliation: '',
                custom1: defaultValueC1 === '' ? [] : defaultValueC1,
                custom2: defaultValueC2 === '' ? [] : defaultValueC2,
                custom3: defaultValueC3 === '' ? [] : defaultValueC3,
                custom4: defaultValueC4 === '' ? [] : defaultValueC4,

                //form: formValVersion !== "" ? [formValVersion?.form_name === undefined ? formValVersion : `${formValVersion?.form_name}||${formValVersion?.form_version}`] : `${defaultFormVal?.form_name}||${defaultFormVal?.form_version}`,
                form:formValVersion!=="" && !Object.values(formValVersion).includes(undefined) ?[`${formValVersion?.form_name}||${formValVersion?.form_version}`]:[`${defaultFormData?.form_name}||${defaultFormData?.form_version}`],
                manager2: manager2Val === '' ? [] : manager2Val,
                manager1: manager1Val === '' ? [] : manager1Val,
                supervisor: supervisorId === '' ? [] : supervisorId,
                agent: agentId === '' ? [] : agentId,
                call_id: props.call_id !== '' ? props.call_id : '',
                agent_id: props.agent_id !== '' ? props.agent_id : '',
                myEvaluation: myEvaluation,
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
            
            })
        };

        dashBoardFilterdData(cartUrl, requestOptions, 'cart-data');
        dashBoardFilterdData(qaScoreUrl, requestOptions, 'qa-score-data');
        dashBoardFilterdData(evaluationSummaryUrl, requestOptions, 'evaluation-summary-data');
        dashBoardFilterdData(autofailURL, requestOptions, 'auto-fail-data');
        dashBoardFilterdData(categoryPerformanceUrl, requestOptions, 'category-performance-data');
        dashBoardFilterdData(agentRangeUrl, requestOptions, 'agent-range-data');
        dashBoardFilterdData(locationSiteUrl, requestOptions, 'location-site-data');
        dashBoardFilterdData(agentPerformanceUrl, requestOptions, 'agent-performance-data');
        dashBoardFilterdData(lobWisePerformanceUrl, requestOptions, 'lob-wise-data');
    }

    const dashBoardFilterdData = (url, requestOptions, purpose) => {
        fetch(url, requestOptions).then(res => res?.json())?.then(data => {
            if (purpose === "cart-data") {
                if (data?.status === 200 || data?.status === 201) {
                    props?.setFilterStatus && props?.setFilterStatus(true)
                    props?.setCardData && props?.setCardData(data?.data)
                }
                else if (data?.status >= 400 && data?.status <= 422) {
                    if(data?.status === 401){
                        localStorage.clear()
                    }
                    setIsDataFound(false)
                    return ErrorNotify(data.message, {
                        icon: "error",
                    });
                    
                }
            }
            if (purpose === "qa-score-data") {
                if (data?.status === 200) {
                    props?.setFilterStatus && props?.setFilterStatus(true)
                    props?.setQAScoreData && props?.setQAScoreData(data?.qa_score)
                }
                else if (data?.status >= 400 && data?.status <= 422) {
                    if(data?.status === 401){
                        localStorage.clear()
                    }
                    setIsDataFound(false)
                    return ErrorNotify(data.message, {
                        icon: "error",
                    });
                    
                }

            }
            if (purpose === "evaluation-summary-data") {
                if (data?.status === 200) {
                    console.log("EVAVAVVAVA",data?.data);
                    props?.setFilterStatus && props?.setFilterStatus(true)
                    props?.setEvFilterData && props?.setEvFilterData(data?.data)
                }
                else if (data?.status >= 400 && data?.status <= 422) {
                    if(data?.status === 401){
                        localStorage.clear()
                    }
                    setIsDataFound(false)
                    return ErrorNotify(data.message, {
                        icon: "error",
                    });
                    
                }
            }

            if (purpose === "auto-fail-data") {
                if (data?.status === 200) {
                    props?.setFilterStatus && props?.setFilterStatus(true)
                    props?.setAutoFailScoreData && props?.setAutoFailScoreData(data?.data)
                }
                else if (data?.status >= 400 && data?.status <= 422) {
                    if(data?.status === 401){
                        localStorage.clear()
                    }
                    setIsDataFound(false)
                    return ErrorNotify(data.message, {
                        icon: "error",
                    });
                    
                }


            }
            if (purpose === "category-performance-data") {
                if (data?.status === 200) {
                    props?.setFilterStatus && props?.setFilterStatus(true)
                    props?.setCatPerformanceData && props?.setCatPerformanceData(data?.data)
                }
                else if (data?.status >= 400 && data?.status <= 422) {
                    if(data?.status === 401){
                        localStorage.clear()
                    }
                    setIsDataFound(false)
                    return ErrorNotify(data.message, {
                        icon: "error",
                    });
                    
                }
            }
            if (purpose === "agent-range-data") {
                if (data?.status === 200) {
                    props?.setFilterStatus && props?.setFilterStatus(true)
                    props?.setAQRData && props?.setAQRData(data?.data)
                }
                else if (data?.status >= 400 && data?.status <= 422) {
                    if(data?.status === 401){
                        localStorage.clear()
                    }
                    setIsDataFound(false)
                    return ErrorNotify(data.message, {
                        icon: "error",
                    });
                    
                }
            }
            if (purpose === "location-site-data") {
                if (data?.status === 200) {
                    props?.setFilterStatus && props?.setFilterStatus(true)
                    props?.setLSPerformanceData && props?.setLSPerformanceData(data?.data)
                }
                else if (data?.status >= 400 && data?.status <= 422) {
                    if(data?.status === 401){
                        localStorage.clear()
                    }
                    setIsDataFound(false)
                    return ErrorNotify(data.message, {
                        icon: "error",
                    });
                    
                }
            }
            if (purpose === "agent-performance-data") {
                if (data?.status === 200) {
                    props?.setFilterStatus && props?.setFilterStatus(true)
                    props?.setAgentPerformanceData && props?.setAgentPerformanceData(data.data)

                }
                else if (data?.status >= 400 && data?.status <= 422) {
                    if(data?.status === 401){
                        localStorage.clear()
                    }
                    setIsDataFound(false)
                    return ErrorNotify(data.message, {
                        icon: "error",
                    });
                    
                }
            }
            if (purpose === "lob-wise-data") {
                if (data?.status === 200) {
                    props?.setFilterStatus && props?.setFilterStatus(true)
                    props?.setLOBWisePerformanceData && props?.setLOBWisePerformanceData(data?.data)
                }
                else if (data?.status >= 400 && data?.status <= 422) {
                    if(data?.status === 401){
                        localStorage.clear()
                    }
                    setIsDataFound(false)
                    return ErrorNotify(data.message, {
                        icon: "error",
                    });
                    
                }
            }
            setTimeout(() => {
                setIsDataFound(false)
            }, 2000);
            
        }).catch(err => {
            setIsDataFound(false)
            return ErrorNotify("Something went wrong.Please contact to Administrator !", {
                icon: "error",
            });
        });
    }

    useEffect(() => {
        var url = `${base_url}dashboard/get-default-form`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                userId: localStorage.getItem('loginUserUniqueid'),
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
            
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('get_DEFAULT');
            if (data?.status == 200) {
                setIsSetAsDefault(false)
                setIsDataFound(false)
                setFormValVersion({form_name:data?.data?.form_name,form_version:data?.data?.form_version})
                setDefaultForm(data?.data)
            }
            else if (data?.status >= 400 && data?.status <= 422) { 
                if(data?.status===401){ 
                    localStorage.clear() 
                }
                setIsSetAsDefault(false)
                setIsDataFound(false)
                return ErrorNotify(data.message, { icon: "error" , }); 
            } 
        }).catch(err=> {
            setIsSetAsDefault(false)
            setIsDataFound(false)
            return ErrorNotify("Something went wrong.Please contact to Administrator !", {
            icon: "error",
                });
            });
    }, [])

    const handleToDateChange = (e) => {
        const selectedDate = e.target.value;
        setToDate(selectedDate);
    };


    useEffect(() => {
        // var url = `${base_url}dashboard/get-default-form`;
        // const requestOptions = {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
        //     },
        //     body: JSON.stringify({
        //         userId:localStorage.getItem("loginUserUniqueid")
        //     })
        // };

        // callApi(url, requestOptions, 'get-default-forms');

    }, [])

    function getCurrentDate() {
        const today = new Date();
        let month = (today.getMonth() + 1).toString().padStart(2, '0');
        let day = today.getDate().toString().padStart(2, '0');
        const year = today.getFullYear();

        return `${year}-${month}-${day}`;
    }

    const getRangeValue = (e) => {
        const value = e.value;
        let startDate, endDate;
        const today = moment().startOf("day").format("YYYY-MM-DD");
        if (e.value === "today") {
            startDate = today;
            endDate = moment(today).endOf("day").format("YYYY-MM-DD");

        }
        if (e.value === "last_1_day") {
            startDate = moment(today).subtract(1, "day").format("YYYY-MM-DD");
            endDate = today;
        }
        if (e.value === "last_2_days") {
            startDate = moment(today).subtract(2, "day").format("YYYY-MM-DD");
            endDate = today;


        }
        if (e.value === "week_to_date") {
            startDate = moment(today).startOf("week").format("YYYY-MM-DD");
            endDate = today;

        }
        if (e.value === "last_week") {
            startDate = moment(today).subtract(1, "week").startOf("week").format("YYYY-MM-DD");
            endDate = moment(startDate).endOf("week").format("YYYY-MM-DD");

        }
        if (e.value === "last_2_week") {
            startDate = moment(today).subtract(2, "week").startOf("week").format("YYYY-MM-DD");
            endDate = moment(startDate).add(15, 'days').format("YYYY-MM-DD");

        }
        if (e.value === "last_3_weeks") {
            startDate = moment(today).subtract(3, "week").startOf("week").format("YYYY-MM-DD");
            endDate = moment(startDate).add(21, 'days').format("YYYY-MM-DD");
        }
        if (e.value === "month_to_date") {
            startDate = moment(today).startOf("month").format("YYYY-MM-DD");
            endDate = today;
        }
        if (e.value === "last_month") {
            startDate = moment(today).subtract(1, "month").startOf("month").format("YYYY-MM-DD");
            endDate = moment(startDate).endOf("month").format("YYYY-MM-DD");

        }
        if (e.value === "last_2_months") {
            startDate = moment(today).subtract(1, "month").startOf("month").format("YYYY-MM-DD");
            endDate = moment().format("YYYY-MM-DD");

        }
        if (e.value === "last_3_months") {
            startDate = moment(today).subtract(2, "month").startOf("month").format("YYYY-MM-DD");
            endDate = moment().format("YYYY-MM-DD");
            console.log("last_3_months", startDate, endDate);
        }
        setFormDate(startDate);
        setToDate(endDate)
    }


    const handleDefault = (e) => {
        setIsSetAsDefault(true)
        setIsDataFound(false)
        var url = `${base_url}dashboard/set-default-form`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                form_name: formValVersion !== "" ? formValVersion?.form_name:defaultFormData?.form_name,
                form_version: formValVersion !== "" ? formValVersion?.form_version:defaultFormData?.form_version,
                userId: localStorage.getItem('loginUserUniqueid'),
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
            
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('c2 data======', data);
            if(data?.status===200){
                setTimeout(() => {
                    setIsSetAsDefault(false)
                    SuccessNotify("Default Form Successfully Added.");
                    return;
                }, 2000);
            }
            else if (data?.status >= 400 && data?.status <= 422) {
                if(data?.status === 401){
                    localStorage.clear()
                }
                setIsSetAsDefault(false)
                return ErrorNotify(data.message, {
                    icon: "error",
                });
                
            }
            // if (data.status != 200) {
            //     setIsSetAsDefault(false)
            //     ErrorNotify(data.message);
            //     return;
            // }
            // else {
            //     setTimeout(() => {
            //         setIsSetAsDefault(false)
            //         SuccessNotify("Default Form Successfully Added.");
            //         return;
            //     }, 2000);

            // }
        }).catch(err => {
            setIsSetAsDefault(false)
            return ErrorNotify("Something went wrong.Please contact to Administrator !", {
                icon: "error",
            });
        });
    }

    return (
        <>
            <div className="filter-area">


                <div id="filter_drawer" className={`san-drawer right ${props?.isDrawerOpen ? 'open' : ''}`} data-reference="filter_drawer">
                    <form action="" className="filter_page_form" method="post" acceptCharset="utf-8">
                        <div className="drawer-card">
                            <div className="drawer-header d-flex align-items-center justify-content-between">
                                <h4>Filter Settings</h4>
                                <button type="button" className="drawer-close"></button>
                            </div>
                            <div className="drawer-body">
                                <input type="hidden" className="txt_csrfname" value="" />
                                {
                                    !props?.dashboardFilter &&
                                    <>
                                        <div className="form-group">
                                            <label className="form-label"><span>From</span><span className="mandatory">*</span></label>
                                            <input type="date" className="form-control" max={getCurrentDate()} id="fromDateInput" placeholder="From Date" required onChange={(e) => [setFormDate(e.target.value), setToDateDisabled(false), props.sendfromdate && props?.sendfromdate(e.target.value)]} value={fromDate} />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label"><span>To</span><span className="mandatory">*</span></label>
                                            <input type="date" min={fromDate} className="form-control" max={getCurrentDate()} id="toDateInput" disabled={toDateDisabled} placeholder="To Date" required onChange={(e) => [setToDate(e.target.value), handleToDateChange(e), props.sendtodate && props?.sendtodate(e.target.value)]} value={toDate} />
                                        </div>
                                    </>
                                }

                                <div className="form-group">
                                    <label className="form-label"><span>Date Type</span><span className="mandatory">*</span></label>
                                    <Select
                                        options={[
                                            { value: 'call_date', label: 'Call Date' },
                                            { value: 'evaluation_date', label: 'Evaluation Date' },
                                        ]}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                borderRadius: '100px', // Adjust the border-radius value as per your needs
                                            }),
                                            indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                        }}
                                        placeholder="Select Date Type"
                                        isSearchable={true}
                                        value={dateType}
                                        onChange={handleDateType}
                                    />
                                </div>
                                {
                                    props?.dashboardFilter &&
                                    <>
                                        <div className="form-group">
                                            <label className="form-label"><span>Date Range</span><span className="mandatory">*</span></label>
                                            <Select
                                                options={[
                                                    { value: 'custom', label: 'Custom' },
                                                    { value: 'day_wise', label: 'Day Wise' },
                                                    { value: 'weekly', label: 'Weekly' },
                                                    { value: 'Monthly', label: 'Monthly' },
                                                ]}
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        borderRadius: '100px', // Adjust the border-radius value as per your needs
                                                    }),
                                                    indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                                }}
                                                placeholder="Select Date Type"
                                                isSearchable={true}
                                                value={dateRange}
                                                onChange={(e) => setDateRange(e)}
                                            />
                                        </div>
                                        {
                                            dateRange.value !== "custom" ?

                                                <div className="form-group">
                                                    <label className="form-label"><span>{dateRange?.label}</span><span className="mandatory">*</span></label>
                                                    <Select
                                                        // options={[
                                                        //     { value: 'month_to_date', label: 'Month To Date' },
                                                        //     { value: 'last_month', label: 'Last Month'},
                                                        //     { value: 'last_two_month', label: 'Last 2 Months' },
                                                        //     { value: 'last_three_months', label: 'Last 3 Months' },
                                                        // ]}
                                                        options={dateRange?.value === "day_wise" ? dayWise : dateRange?.value === "weekly" ? weekly : monthly}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                borderRadius: '100px', // Adjust the border-radius value as per your needs
                                                            }),
                                                            indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                                        }}
                                                        placeholder={dateRange?.value === "day_wise" ? dayWise[0]?.label : dateRange?.value === "weekly" ? weekly[0]?.label : monthly[0]?.label}
                                                        isSearchable={true}
                                                        value={dateRangeValue}
                                                        onChange={(e) => [setDateRangeValue(e), getRangeValue(e)]}
                                                    />
                                                </div>
                                                :
                                                <>
                                                    <div className="form-group">
                                                        <label className="form-label"><span>Start Date</span><span className="mandatory">*</span></label>
                                                        <input type="date" className="form-control" max={getCurrentDate()} id="fromDateInput" placeholder="From Date" required onChange={(e) => [setFormDate(e.target.value), setToDateDisabled(false)]} value={fromDate} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="form-label"><span>End Date</span><span className="mandatory">*</span></label>
                                                        <input type="date" min={fromDate} className="form-control" max={getCurrentDate()} id="toDateInput" disabled={toDateDisabled} placeholder="To Date" required onChange={(e) => [setToDate(e.target.value), handleToDateChange(e)]} value={toDate} />
                                                    </div>
                                                </>
                                        }
                                    </>
                                }
                                {/* <option>Select</option> */}
                                {/* <div className="form-group">
                                    <label className="form-label"><span>Affiliation</span><span className="mandatory">*</span></label>
                                    <select className="form-control " required onChange={(e) => setAffiliation(e.target.value)} >
                                        <option>Select</option>
                                        <option value="All">All</option>
                                        <option value="CenturyLink">CenturyLink</option>
                                        <option value="MattsenKumar">MattsenKumar</option>
                                    </select>
                                </div> */}

                                <CustomLevel dashboardFilter={props?.dashboardFilter} setDefaultFormData={setDefaultFormData} statusShow={props?.statusShow} setDefaultFormVal={setDefaultFormVal} myEvaluation={myEvaluation} setMyEvaluation={setMyEvaluation} agent={agent} setSupervisorId={setSupervisorId} setAgent={setAgent} setAgentid={setAgentid} setFormValVersion={setFormValVersion} formValVersion={formValVersion} otherFilter={props?.otherFilter} unAssignedCallsData={props?.unAssignedCallsData} callListData={props?.callListData} getC1Value={getC1Value} getC2Value={getC2Value} getC3Value={getC3Value} getC4Value={getC4Value} getFormData={getFormData} getManager2={getManager2} getManager1={getManager1} getSupervisor={getSupervisor} fromDate={fromDate} toDate={toDate} dateType={dateType} manager2={manager2Val} manager1={manager1Val} getAgent={getAgent} />

                                {/* <div className="form-group">
                                    <label><span>Form</span></label>
                                    <select className="form-control " required onChange={(e) => setForm(e.target.value)} >
                                        <option value="">Select</option>
                                        {formData.map((i, j) => {
                                            return (
                                                <option key={j} value={i.form_name}>{i.form_name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="form-group d-none">
                                    <label className="form-label">Manager 3</label>
                                    <select className="form-control ">
                                        <option value="">Select</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Manager 2</label>
                                    <select className="form-control " onChange={(e) => setManager2Val(prevState => [...prevState, e.target.value])} >
                                        <option>Select</option>
                                        {manager2.map((i, j) => {
                                            return (
                                                <option key={j} value={i.userId}>{i.name}</option>
                                            )
                                        })}
                                    </select>
                                </div> */}
                                {/* <div className="form-group">
                                    <label className="form-label">Manager 1</label>
                                    <select className="form-control " onChange={(e) => setManager1Val(e.target.value)} >
                                        <option value="">Select</option>
                                        {manager1.map((i,j) => {
                                            return(
                                                <></>
                                            )
                                        })}
                                    </select>
                                </div> */}
                                {/* <div className="form-group">
                                    <label className="form-label">Supervisor</label>
                                    <select className="form-control " onChange={(e) => setSupervisor(e.target.value)} >
                                        <option value="">Select</option>
                                    </select>
                                </div> */}
                                {/* <div className="form-group">
                                    <label className="form-label">Agent</label>
                                    <select className="form-control " onChange={(e) => setAgent(e.target.value)} >
                                        <option value="">Select</option>
                                    </select>
                                </div> */}

                                <div className="form-group d-none">
                                    <label className="form-label">Team</label>
                                    <select className="form-control" required>
                                        <option value="my_team">MY Team</option>
                                        <option value="overall">Overall</option>
                                    </select>
                                </div>
                            </div>
                            <div className="drawer-footer">
                                {
                                    !props?.dashboardFilter &&

                                    <button type="button" id="filter_sbmtBtn" className={`btn btn-primary btn-sm alpha filter_submit ${isDataFound ? 'loading' : ''} `} onClick={(e) => { handleFilter(e) }}>
                                        <span>Submit</span>
                                        <span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span>
                                    </button>
                                }
                                {
                                    props?.dashboardFilter &&
                                    <>
                                        <button type="button" id="filter_sbmtBtn" className={`btn btn-primary btn-sm alpha filter_submit ${isDataFound ? 'loading' : ''} `} onClick={(e) => { dashBoardFilter(e) }}>
                                            <span>Submit</span>
                                            <span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span>
                                        </button>

                                        <button type="button" id="filter_sbmtBtn" className={`btn btn-primary btn-sm mx-2 alpha filter_submit ${isSetAsDefault ? 'loading' : ''} `} onClick={(e) => handleDefault(e)}>
                                            <span>Set As Default</span>
                                            <span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span>
                                        </button>
                                    </>
                                }
                            </div>

                        </div>
                    </form>

                </div>

            </div>

            {isDataFound && <div className="page-loader align-items-center justify-content-center loading">
                <div className="loader-dots d-flex align-items-center justify-content-center">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>}
            {isSetAsDefault && <div className="page-loader align-items-center justify-content-center loading">
                <div className="loader-dots d-flex align-items-center justify-content-center">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>}
        </>
    )
}

export default FilterRecords;