import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import MultiSelection from "../../../controls/MultiSelect";
import Switch from "react-switch";
import { components } from "react-select";
import DataTable from "react-data-table-component";
import {
  useNavigate,
  Route,
  Routes,
  Link,
  BrowserRouter,
  useFetcher,
} from "react-router-dom";
import Horizontal from "../../../shared/Slider";
import HorizontalCustomLabels from "../../../shared/Slider";
import DataTableView from "../../../shared/DataTable";
import moment from "moment";
import $ from "jquery";
import MultiRangeSlider from "../../../shared/multiRangeSlider/MultiRangeSlider";
import {
  ErrorNotify,
  SuccessNotify,
  DismissToast,
} from "../../../shared/Toast";
import { ToastContainer } from "react-toastify";
import Select from "react-select";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../../shared/GenralFunctioin/TableTitle";
import { end } from "@popperjs/core";
import { ChartData } from "../../../shared/Chart";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;

var csvReport = {
  data: [],
};
var pdfReport = {
  data: [],
};
var xlsReport = {
  data: [],
};

const headers = [
  { label: "File Name", key: "i.wav" },
  { label: "Agent ID", key: "i.agentid" },
  { label: "Form Name", key: "i.form_name" },
  { label: "Datetime", key: "i.datetime" },
];

var tagsArr = [];
var lastid = "";
var lastTagId = "";
// var tagsArr = [];
var tagsDetailsArr = [];
// var termDataArray = [];
var apiFlag = false;

const NewDashboardFilter = (props) => {
  console.log("props", props);
  const today = new Date().toISOString().split("T")[0];

  var optionsAppList = [];
  var optionsFolderList = [];
  let emotionTypeData = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Positive",
      value: "Positive",
    },
    {
      label: "Negative",
      value: "Negative",
    },
    {
      label: "Improving",
      value: "Improving",
    },
    {
      label: "Worsening",
      value: "Worsening",
    },
  ];
  const navigate = useNavigate();
  const [lobData, setLobData] = useState([]);
  const [compaignData, setCompaignData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [locData, setLocData] = useState([]);
  //   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedLobData, setSelectedLobData] = useState();
  const [selectedCampData, setSelectedCampData] = useState();
  const [selectedVendorData, setSelectedVendorData] = useState();
  const [selectedLocData, setSelectedLocData] = useState();
  const [timeFormat, setTimeFormat] = useState({
    value: "daily-tag-data",
    label: "Day Wise",
  });
  const [dateType, setDateType] = useState({
    value: "call_date",
    label: "Call Date",
  });
  const [folderValue, setFolderValue] = useState([]);
  const [folderId, setFolderId] = useState([]);

  const [emotionTypValue, setEmotionTypValue] = useState(emotionTypeData);
  const [emotionType, setEmotionType] = useState(["all"]);
  const [defaultData, setDefaultData] = useState({});
  const [isFilter, setIsFilter] = useState(false);

  const [formData, setFormData] = useState([]);

  const [termCreator, setTermCreator] = useState("Speaker");
  const [termsArr, setTermsArr] = useState([]);
  const [termWord, setTermWord] = useState("");

  const [endSlTime, setEndSlTime] = useState("");
  const [startSlTime, setStartSlTime] = useState("");

  const [genderOF, setGenderOF] = useState("all_gender");
  const [genderType, setGenderType] = useState("all");

  const [emotionOF, setEmotionOF] = useState("client");
  const [emotionVal, setEmotionVal] = useState("");

  const [callSearchData, setCallSearchData] = useState([]);

  const [isLoading, setIsLoading] = React.useState(true);

  const [callID, setCallId] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [allBookMarksData, setAllBookMarksData] = useState([]);
  const [allBookTagsData, setAllTagssData] = useState([]);

  const [switchBtn, setSwitchBtn] = useState(false);
  const [tagsOrAndValue, setTagsOrAndValue] = useState();
  const [bookmarkBooleanValue, setBookmarkBooleanValue] = useState();

  const [manager2, setManager2] = useState([]);
  const [manager1Val, setManager1Val] = useState([]);
  const [tagsArrData, setTagsArrData] = useState({
    tags: [
      { operator: "OR", tag_details: [{ terms_text: "", terms_phrase: "" }] },
    ],
  });

  const [silenceTimeValue, setSilenceTime] = useState("");
  const [durationValue, setDuration] = useState("");

  const [agent_id, setAgentId] = useState("");
  const [call_id, setCallID] = useState("");

  const [termVal, setTerm] = useState();
  const [termText, setTermText] = useState();

  const [searchTermValue, setSearchTermValue] = useState([]);
  const [termDataArray, setTermDataArray] = useState([]);
  const [bookMarkValue, setBookMarkValue] = useState("");

  const [tagListData, setTagListData] = useState([]);

  const [folderListData, setFolderListData] = useState([]);
  const [backgroundColor, setBackgroundColor] = useState(false);

  // const [appID, setAppID] = useState([]);
  const [appName, setAppName] = useState("");

  const [tagID, setTagID] = useState([]);
  const [tagName, setTagName] = useState("");
  const [folderID, setFolderID] = useState("");
  const [folderName, setFolderName] = useState("");

  const [clearApp, setClearApp] = useState(false);
  const [showBookMark, setShowBookMark] = useState("d-block");
  const [showKeyWord, setShowKeyword] = useState("d-block");

  const [c1Data, setC1Data] = useState([]);
  // const [c2Data, setC2Data] = useState([]);
  // const [c3Data, setC3Data] = useState([]);
  // const [c4Data, setC4Data] = useState([]);

  const [searchLoader, setSearchLoader] = useState(false);

  const [defaultValueC1, setdefaultValueC1] = useState([]);
  // const [defaultValueC2, setdefaultValueC2] = useState([]);
  // const [defaultValueC3, setdefaultValueC3] = useState([]);
  // const [defaultValueC4, setdefaultValueC4] = useState([]);

  const [hierarchyData, setHierarchyData] = useState([]);

  useEffect(() => {
    if (
      localStorage.getItem("loginToken") === "" ||
      localStorage.getItem("loginToken") === null
    ) {
      localStorage.clear();
      navigate("/");
    }
  }, []);

  const Logout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  useEffect(() => {
    setIsLoading(false);
    apiFlag = false
    getAllFolders();
  }, []);

  useEffect(() => {
    props?.setIdOfFolder && props?.setIdOfFolder(folderID);
  }, [folderID]);

  // useEffect(() => {
  //   console.log("folderIDDDD", folderID);
  //   folderID !== "" && folderID && getFoldersApplications(folderID);
  // }, [folderID]);
  useEffect(() => {
    // props?.setFilterPayload &&
    props?.setFilterPayload({
      // lob: defaultValueC2,
      campaign: defaultValueC1,
      // vendor: defaultValueC3,
      // location: defaultValueC4,
      gender: genderOF,
      gender_type: genderType,
      emotion: emotionOF ? emotionOF : "",
      emotion_type: emotionType ? emotionType : [],
      overall_from_date: props?.fromDate1,
      overall_to_date: props?.toDate1,
      silent_time: endSlTime,
      start_silent_time: startSlTime,
      duration: props?.endDuration,
      start_duration: props?.startDuration,
      folder_id: folderID,
    });
  }, []);

  useEffect(() => {
    if (folderListData?.length > 0 && !apiFlag) {
      props?.setDynamicEmotionHeading &&
        props?.setDynamicEmotionHeading(emotionOF ? emotionOF : "All");
      let values = {
        // lob: defaultValueC2,
        campaign: defaultValueC1,
        // vendor: defaultValueC3,
        // location: defaultValueC4,
        gender: genderOF,
        gender_type: genderType,
        emotion: emotionOF ? emotionOF : "",
        emotion_type: emotionType ? emotionType : [],
        overall_from_date: props?.fromDate1,
        overall_to_date: props?.toDate1,
        silent_time: endSlTime,
        start_silent_time: startSlTime,
        duration: props?.endDuration,
        start_duration: props?.startDuration,
        folder_id: folderID,
      };
      props?.setFilterPayload(values);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify(values),
      };
      var dashboard_filter_url = `${base_url}smartspeechtags/${timeFormat?.value}`;
      // var line_chart_url = `${base_url}smartspeechtags/tag-trend-chart-${
      //   timeFormat?.value == "daily-tag-data"
      //     ? "daily-data"
      //     : timeFormat?.value == "monthly-tag-data"
      //     ? "monthly-data"
      //     : "weekly-data"
      // }`;
      // var agent_emotions_url = `${base_url}smartspeechtags/emotion-tag-avg`;
      // var average_call_duration_url = `${base_url}smartspeechtags/emotion-tag-duration`;
      var overall_data_url = `${base_url}smartspeechtags/overall-tag-data`;

      callApi(dashboard_filter_url, requestOptions, "dashboard-filter");
      // callApi(agent_emotions_url, requestOptions, "agent-emotions-data");
      // callApi(
      //   average_call_duration_url,
      //   requestOptions,
      //   "average-call-duration"
      // );
      callApi(overall_data_url, requestOptions, "overall-tag-data");
      // callApi(line_chart_url, requestOptions, "line-chart-data");
      // var app_cat_url = `${base_url}smartspeechtags/tag-data-chart`;

      // const catRequestOptions = {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: "Bearer " + localStorage.getItem("loginToken"),
      //   },
      //   body: JSON.stringify({
      //     from_date: props?.fromDate1,
      //     to_date: props?.toDate1,
      //     folder_id: folderID,
      //     silent_time: endSlTime,
      //     start_silent_time: startSlTime,
      //     duration: props?.endDuration,
      //     start_duration: props?.startDuration,
      //     campaign: defaultValueC1,
      //     gender: genderOF,
      //     gender_type: genderType,
      //     emotion: emotionOF ? emotionOF : "",
      //     emotion_type: emotionType ? emotionType : [],
      //   }),
      // };
      // callApi(app_cat_url, catRequestOptions, "category-chart");

      // var url = `${base_url}folder/all-application-folder-wise/${folderID}`;

      // var requestOptions2 = {
      //   method: "GET",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: "Bearer " + localStorage.getItem("loginToken"),
      //   },
      // };
      // callApi(url, requestOptions2, "folder-wise-app-list");

      // const requestOptionsForDetractorsEscalationsData = {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: "Bearer " + localStorage.getItem("loginToken"),
      //   },
      //   body: JSON.stringify({
      //     from_date: props?.fromDate1,
      //     to_date: props?.toDate1,
      //     folder_id: folderID,
      //     silent_time: endSlTime,
      //     start_silent_time: startSlTime,
      //     duration: props?.endDuration,
      //     start_duration: props?.startDuration,
      //     campaign: defaultValueC1,
      //     gender: genderOF,
      //     gender_type: genderType,
      //     emotion: emotionOF ? emotionOF : "",
      //     emotion_type: emotionType ? emotionType : [],
      //   }),
      // };

      // var detractors_app_data = `${base_url}smartspeechtags/detractors-app-tag-data`;

      // callApi(
      //   detractors_app_data,
      //   requestOptionsForDetractorsEscalationsData,
      //   "detractors-app-data"
      // );

      // var escalations_app_data = `${base_url}smartspeechtags/escalations-app-tag-data`;
      // callApi(
      //   escalations_app_data,
      //   requestOptionsForDetractorsEscalationsData,
      //   "escalations-app-data"
      // );

      // const requestOptionsForDetractorHotWordsChart = {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: "Bearer " + localStorage.getItem("loginToken"),
      //   },
      //   body: JSON.stringify({
      //     application: "",
      //     folder_id: folderID,
      //     overall_from_date: props?.fromDate1,
      //     overall_to_date: props?.toDate1,
      //     chart: "Detractors Hot Words",
      //     silent_time: endSlTime,
      //     start_silent_time: startSlTime,
      //     duration: props?.endDuration,
      //     start_duration: props?.startDuration,
      //     campaign: defaultValueC1,
      //     gender: genderOF,
      //     gender_type: genderType,
      //     emotion: emotionOF ? emotionOF : "",
      //     emotion_type: emotionType ? emotionType : [],
      //   }),
      // };

      // const requestOptionsForEscalationsHotWordsChart = {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: "Bearer " + localStorage.getItem("loginToken"),
      //   },
      //   body: JSON.stringify({
      //     application: "",
      //     folder_id: folderID,
      //     overall_from_date: props?.fromDate1,
      //     overall_to_date: props?.toDate1,
      //     chart: "Escalations Hot Words",
      //     silent_time: endSlTime,
      //     start_silent_time: startSlTime,
      //     duration: props?.endDuration,
      //     start_duration: props?.startDuration,
      //     campaign: defaultValueC1,
      //     gender: genderOF,
      //     gender_type: genderType,
      //     emotion: emotionOF ? emotionOF : "",
      //     emotion_type: emotionType ? emotionType : [],
      //   }),
      // };

      // var detractors_hot_words_data = `${base_url}smartspeechtags/detractors-tag-bubble-chart-phrase-count`;
      // var escalations_hot_words_data = `${base_url}smartspeechtags/escalation-tag-bubble-chart-phrase-count`;
      // callApi(
      //   detractors_hot_words_data,
      //   requestOptionsForDetractorHotWordsChart,
      //   "detractors-hot-words"
      // );
      // callApi(
      //   escalations_hot_words_data,
      //   requestOptionsForEscalationsHotWordsChart,
      //   "escalations-hot-words"
      // );

      // const requestOptionsForCategorizarionData = {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: "Bearer " + localStorage.getItem("loginToken"),
      //   },
      //   body: JSON.stringify({
      //     // application_id: ["169592827540659"],
      //     from_date: props?.fromDate1,
      //     to_date: props?.toDate1,
      //     folder_id: folderID,
      //   }),
      // };

      // var application_percentage_chart = `${base_url}smartspeechtags/tag-percentage-chart`;

      // callApi(
      //   application_percentage_chart,
      //   requestOptionsForCategorizarionData,
      //   "application-percentage-chart"
      // );

      // const requestOptionsForCustomerScore = {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: "Bearer " + localStorage.getItem("loginToken"),
      //   },
      //   body: JSON.stringify({
      //     // application_id : ["169592827540659"],
      //     from_date: props?.fromDate1,
      //     to_date: props?.toDate1,
      //     folder_id: folderID,
      //     silent_time: endSlTime,
      //     start_silent_time: startSlTime,
      //     duration: props?.endDuration,
      //     start_duration: props?.startDuration,
      //     campaign: defaultValueC1,
      //     gender: genderOF,
      //     gender_type: genderType,
      //     emotion: emotionOF ? emotionOF : "",
      //     emotion_type: emotionType ? emotionType : [],
      //   }),
      // };

      // var customer_score_effect = `${base_url}smartspeechtags/customer-score-effect-tags`;

      // callApi(
      //   customer_score_effect,
      //   requestOptionsForCustomerScore,
      //   "customer-score-effect"
      // );

      // const requestOptionsForExcalationsTypesOnLoad = {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: "Bearer " + localStorage.getItem("loginToken"),
      //   },
      //   body: JSON.stringify({
      //     from_date: props?.fromDate1,
      //     to_date: props?.toDate1,
      //     folder_id: folderID,
      //     silent_time: endSlTime,
      //     start_silent_time: startSlTime,
      //     duration: props?.endDuration,
      //     start_duration: props?.startDuration,
      //     campaign: defaultValueC1,
      //     gender: genderOF,
      //     gender_type: genderType,
      //     emotion: emotionOF ? emotionOF : "",
      //     emotion_type: emotionType ? emotionType : [],
      //   }),
      // };
      // var types_of_escalations_chart = `${base_url}smartspeechtags/escalations-type-tag-data`;
      // callApi(
      //   types_of_escalations_chart,
      //   requestOptionsForExcalationsTypesOnLoad,
      //   "types-of-escalations-chart"
      // );

      // var cust_score_url = `${base_url}smartspeechtags/customer-score-effect-attribute-tags`;

      // const custEffortRequestOptions = {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: "Bearer " + localStorage.getItem("loginToken"),
      //   },
      //   body: JSON.stringify({
      //     from_date: props?.fromDate1,
      //     to_date: props?.toDate1,
      //     folder_id: folderID,
      //     silent_time: endSlTime,
      //     start_silent_time: startSlTime,
      //     duration: props?.endDuration,
      //     start_duration: props?.startDuration,
      //     campaign: defaultValueC1,
      //     gender: genderOF,
      //     gender_type: genderType,
      //     emotion: emotionOF ? emotionOF : "",
      //     emotion_type: emotionType ? emotionType : [],
      //     // application_id: [appID],
      //   }),
      // };
      // callApi(
      //   cust_score_url,
      //   custEffortRequestOptions,
      //   "category-effect-score"
      // );
      apiFlag = true;
    }
  }, [folderListData,apiFlag]);

  //   console.log("filterData",filterData)

  // useEffect(()=>{
  //     termDataArray = []
  //     termsArr && termsArr?.map(item=>{
  //         let value = item
  //         termDataArray.push(value?.split(':')[1])
  //     })
  // },[termsArr])

  const handleChartDataChange = (e) => {
    setTimeFormat(e);
  };

  const onChangeSlienceTime = (min, max) => {
    console.log("onChangeSlienceTime", min, max);
    setEndSlTime(max.toString());
    setStartSlTime(min.toString());
  };

  const onChangeDuration = (min, max) => {
    console.log("onChangeDuration", min, max);

    props?.setEndDuration && props?.setEndDuration(max.toString());
    props?.setStartDuration && props?.setStartDuration(min.toString());
  };

  useEffect(() => {
    getCustomHierarchyData();
  }, []);

  const getCustomHierarchyData = async () => {
    var url = `${base_url}hierarchy/get-custom-hierarchy`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("hierarchy data======", data);
        if (isNaN(data.status)) {
          // Logout();
          return;
        }
        var arr = [];
        data.data.map((i, j) => {
          arr[i.custom1_id] = i.custom1_name;
          arr[i.custom2_id] = i.custom2_name;
          arr[i.custom3_id] = i.custom3_name;
          arr[i.custom4_id] = i.custom4_name;
        });
        // arr['c2'] = 'Vendor';
        // arr['c3'] = 'LOB';
        // arr['c1'] = 'Campaign';
        // arr['c4'] = 'Location';
        console.log("arr -===========", arr);
        setHierarchyData(arr);
      })
      .catch((err) => {
        console.log(err);
      });

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    var url = `${base_url}callspeech/get-meta-campaign`;
    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("data campaign", data);
        if (data?.data?.length > 0 && data?.data[0]?.campaign?.length > 0) {
          setC1Data(data?.data[0]?.campaign);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ValueContainer = ({ children, ...props }) => {
    const { getValue } = props;
    let selectedOption = getValue().length;

    if (selectedOption > 0 && selectedLobData.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${selectedLobData.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const ValueContainer2 = ({ children, ...props }) => {
    const { getValue } = props;
    let selectedOption = getValue().length;

    if (folderValue && folderValue?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${folderValue?.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const emotionTypeValContainer = ({ children, ...props }) => {
    const { getValue } = props;
    let selectedOption = getValue().length;

    if (emotionType && emotionType?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${
            emotionType?.some((item) => item == "all")
              ? emotionTypeData?.length - 1
              : emotionType?.length
          } items`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const ValueContainer3 = ({ children, ...props }) => {
    const { getValue } = props;
    let selectedOption = getValue().length;

    if (selectedOption > 0 && selectedVendorData.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${selectedVendorData.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const ValueContainer4 = ({ children, ...props }) => {
    const { getValue } = props;
    let selectedOption = getValue().length;

    if (selectedOption > 0 && selectedLocData.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${selectedLocData.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props} className="multiselect-comp">
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label style={{ fontSize: 12, marginLeft: 3 }}>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const optionsC1 = Object.keys(lobData)
    .filter((key) => lobData[key] !== "NA" && lobData[key] !== null)
    .map((item) => ({
      value: lobData[item],
      label: lobData[item],
    }));
  {
    console.log("Options11111", optionsC1);
  }

  const optionsC2 = Object.keys(compaignData)
    .filter((key) => compaignData[key] !== "NA" && compaignData[key] !== null)
    .map((item) => ({
      value: compaignData[item],
      label: compaignData[item],
    }));

  const optionsC3 = Object.keys(vendorData)
    .filter((key) => vendorData[key] !== "NA" && vendorData[key] !== null)
    .map((item) => ({
      value: vendorData[item],
      label: vendorData[item],
    }));
  const optionsC4 = Object.keys(locData)
    .filter((key) => locData[key] !== "NA" && locData[key] !== null)
    .map((item) => ({
      value: locData[item],
      label: locData[item],
    }));

  const handleSearch = () => {
    if (folderID?.length <= 0) {
      ErrorNotify("Please Select Folder");
      return;
    }

    if (timeFormat == "") {
      ErrorNotify("Please Select Chart Data");
      return;
    }
    // console.log('durationValue are========', durationValue);
    // return;
    props?.setOverallData([])
    props?.setEmotionsData([])
    props?.setDurationData([])
    props?.setCategorizationData([])
    props?.setDetractorsHotWordsData([])
    props?.setExcalationsHotWordsData([])
    props?.setDetractorsData([])
    props?.setEscalationsData([]);
    props?.setTypesOfExcalations([]);
    props?.setCustomerScoreData([]);
    props?.setChart1status(true);
    props?.setChart5status(true);
    props?.setChart6status(true);
    props?.setChart12status(true);
    props?.setDynamicEmotionHeading &&
      props?.setDynamicEmotionHeading(emotionOF ? emotionOF : "All");
    let values = {
      // lob: defaultValueC2,
      campaign: defaultValueC1,
      // vendor: defaultValueC3,
      // location: defaultValueC4,
      gender: genderOF,
      gender_type: genderType,
      emotion: emotionOF ? emotionOF : "",
      emotion_type: emotionType ? emotionType : [],
      overall_from_date: props?.fromDate1,
      overall_to_date: props?.toDate1,
      silent_time: endSlTime,
      start_silent_time: props?.startSlTime,
      duration: props?.endDuration,
      start_duration: props?.startDuration,
      folder_id: folderID,
    };
    props?.setFilterPayload(values);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify(values),
    };

    props?.setFilterPayload &&
      props?.setFilterPayload({
        // lob: defaultValueC2,
        campaign: defaultValueC1,
        // vendor: defaultValueC3,
        // location: defaultValueC4,
        gender: genderOF,
        gender_type: genderType,
        emotion: emotionOF ? emotionOF : "",
        emotion_type: emotionType ? emotionType : [],
        overall_from_date: props?.fromDate1,
        overall_to_date: props?.toDate1,
        silent_time: endSlTime,
        start_silent_time: startSlTime,
        duration: props?.endDuration,
        start_duration: props?.startDuration,
        folder_id: folderID,
      });
    console.log("props?.fromDate1", props?.fromDate1);
    // const requestOptionsForCategorizarionData = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + localStorage.getItem("loginToken"),
    //   },
    //   body: JSON.stringify({
    //     // application_id: [appID],
    //     from_date: props?.fromDate1,
    //     to_date: props?.toDate1,
    //     folder_id: folderID,
    //     silent_time: endSlTime,
    //     start_silent_time: startSlTime,
    //     duration: props?.endDuration,
    //     start_duration: props?.startDuration,
    //     campaign: defaultValueC1,
    //     gender: genderOF,
    //     gender_type: genderType,
    //     emotion: emotionOF ? emotionOF : "",
    //     emotion_type: emotionType ? emotionType : [],
    //   }),
    // };
    // const requestOptionsForDetractorsEscalationsData = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + localStorage.getItem("loginToken"),
    //   },
    //   body: JSON.stringify({
    //     from_date: props?.fromDate1,
    //     to_date: props?.toDate1,
    //     folder_id: folderID,
    //     silent_time: endSlTime,
    //     start_silent_time: startSlTime,
    //     duration: props?.endDuration,
    //     start_duration: props?.startDuration,
    //     campaign: defaultValueC1,
    //     gender: genderOF,
    //     gender_type: genderType,
    //     emotion: emotionOF ? emotionOF : "",
    //     emotion_type: emotionType ? emotionType : [],
    //   }),
    // };

    // const requestOptionsForExcalationsTypes = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + localStorage.getItem("loginToken"),
    //   },
    //   body: JSON.stringify({
    //     from_date: props?.fromDate1,
    //     to_date: props?.toDate1,
    //     folder_id: folderID,
    //     silent_time: endSlTime,
    //     start_silent_time: startSlTime,
    //     duration: props?.endDuration,
    //     start_duration: props?.startDuration,
    //     campaign: defaultValueC1,
    //     gender: genderOF,
    //     gender_type: genderType,
    //     emotion: emotionOF ? emotionOF : "",
    //     emotion_type: emotionType ? emotionType : [],
    //   }),
    // };

    // const requestOptionsForCustomerScore = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + localStorage.getItem("loginToken"),
    //   },
    //   body: JSON.stringify({
    //     // application_id : ["169592827540659"],
    //     from_date: props?.fromDate1,
    //     to_date: props?.toDate1,
    //     folder_id: folderID,
    //     silent_time: endSlTime,
    //     start_silent_time: startSlTime,
    //     duration: props?.endDuration,
    //     start_duration: props?.startDuration,
    //     campaign: defaultValueC1,
    //     gender: genderOF,
    //     gender_type: genderType,
    //     emotion: emotionOF ? emotionOF : "",
    //     emotion_type: emotionType ? emotionType : [],
    //   }),
    // };

    // const requestOptionsForDetractorHotWordsChart = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + localStorage.getItem("loginToken"),
    //   },
    //   body: JSON.stringify({
    //     application: "",
    //     folder_id: folderID,
    //     overall_from_date: props?.fromDate1,
    //     overall_to_date: props?.toDate1,
    //     chart: "Detractors Hot Words",
    //     silent_time: endSlTime,
    //     start_silent_time: startSlTime,
    //     duration: props?.endDuration,
    //     start_duration: props?.startDuration,
    //     campaign: defaultValueC1,
    //     gender: genderOF,
    //     gender_type: genderType,
    //     emotion: emotionOF ? emotionOF : "",
    //     emotion_type: emotionType ? emotionType : [],
    //   }),
    // };

    // const requestOptionsForEscalationsHotWordsChart = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + localStorage.getItem("loginToken"),
    //   },
    //   body: JSON.stringify({
    //     application: "",
    //     folder_id: folderID,
    //     overall_from_date: props?.fromDate1,
    //     overall_to_date: props?.toDate1,
    //     chart: "Escalations Hot Words",
    //     silent_time: endSlTime,
    //     start_silent_time: startSlTime,
    //     duration: props?.endDuration,
    //     start_duration: props?.startDuration,
    //     campaign: defaultValueC1,
    //     gender: genderOF,
    //     gender_type: genderType,
    //     emotion: emotionOF ? emotionOF : "",
    //     emotion_type: emotionType ? emotionType : [],
    //   }),
    // };

    var dashboard_filter_url = `${base_url}smartspeechtags/${timeFormat?.value}`;
    // var agent_emotions_url = `${base_url}smartspeechtags/emotion-tag-avg`;
    // var average_call_duration_url = `${base_url}smartspeechtags/emotion-tag-duration`;
    var overall_data_url = `${base_url}smartspeechtags/overall-tag-data`;
    // var app_cat_url = `${base_url}smartspeechtags/tag-data-chart`;
    // var application_percentage_chart = `${base_url}smartspeechtags/tag-percentage-chart`;
    // var customer_score_effect = `${base_url}smartspeechtags/customer-score-effect-tags`;
    // var detractors_app_data = `${base_url}smartspeechtags/detractors-app-tag-data`;
    // var escalations_app_data = `${base_url}smartspeechtags/escalations-app-tag-data`;
    // var types_of_escalations_chart = `${base_url}smartspeechtags/escalations-type-tag-data`;
    // var detractors_hot_words_data = `${base_url}smartspeechtags/detractors-tag-bubble-chart-phrase-count`;
    // var escalations_hot_words_data = `${base_url}smartspeechtags/escalation-tag-bubble-chart-phrase-count`;
    // var line_chart_url = `${base_url}smartspeechtags/tag-trend-chart-${
    //   timeFormat?.value == "daily-tag-data"
    //     ? "daily-data"
    //     : timeFormat?.value == "monthly-tag-data"
    //     ? "monthly-data"
    //     : "weekly-data"
    // }`;

    callApi(dashboard_filter_url, requestOptions, "dashboard-filter");
    // callApi(agent_emotions_url, requestOptions, "agent-emotions-data");
    // callApi(average_call_duration_url, requestOptions, "average-call-duration");
    // callApi(
    //   application_percentage_chart,
    //   requestOptionsForCategorizarionData,
    //   "application-percentage-chart"
    // );
    callApi(overall_data_url, requestOptions, "overall-tag-data");
    // callApi(line_chart_url, requestOptions, "line-chart-data");
    // callApi(
    //   customer_score_effect,
    //   requestOptionsForCustomerScore,
    //   "customer-score-effect"
    // );
    // callApi(
    //   detractors_app_data,
    //   requestOptionsForDetractorsEscalationsData,
    //   "detractors-app-data"
    // );
    // callApi(
    //   escalations_app_data,
    //   requestOptionsForDetractorsEscalationsData,
    //   "escalations-app-data"
    // );
    // callApi(
    //   types_of_escalations_chart,
    //   requestOptionsForExcalationsTypes,
    //   "types-of-escalations-chart"
    // );
    // callApi(
    //   detractors_hot_words_data,
    //   requestOptionsForDetractorHotWordsChart,
    //   "detractors-hot-words"
    // );
    // callApi(
    //   escalations_hot_words_data,
    //   requestOptionsForEscalationsHotWordsChart,
    //   "escalations-hot-words"
    // );

    // callApi(
    //   customer_score_effect,
    //   requestOptionsForCategorizarionData,
    //   "customer-score-effect"
    // );
    // callApi(
    //   detractors_app_data,
    //   requestOptionsForDetractorsEscalationsData,
    //   "detractors-app-data"
    // );
    // callApi(
    //   escalations_app_data,
    //   requestOptionsForDetractorsEscalationsData,
    //   "escalations-app-data"
    // );

    // const catRequestOptions = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + localStorage.getItem("loginToken"),
    //   },
    //   body: JSON.stringify({
    //     from_date: props?.fromDate1,
    //     to_date: props?.toDate1,
    //     folder_id: folderID,
    //     silent_time: endSlTime,
    //     start_silent_time: startSlTime,
    //     duration: props?.endDuration,
    //     start_duration: props?.startDuration,
    //     campaign: defaultValueC1,
    //     gender: genderOF,
    //     gender_type: genderType,
    //     emotion: emotionOF ? emotionOF : "",
    //     emotion_type: emotionType ? emotionType : [],
    //   }),
    // };
    // callApi(app_cat_url, catRequestOptions, "category-chart");

    // var cust_score_effect_url = `${base_url}smartspeechtags/customer-score-effect-attribute-tags`;

    // const custScoreEffortRequestOptions = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + localStorage.getItem("loginToken"),
    //   },
    //   body: JSON.stringify({
    //     from_date: props?.fromDate1,
    //     to_date: props?.toDate1,
    //     folder_id: folderID,
    //     silent_time: endSlTime,
    //     start_silent_time: startSlTime,
    //     duration: props?.endDuration,
    //     start_duration: props?.startDuration,
    //     campaign: defaultValueC1,
    //     gender: genderOF,
    //     gender_type: genderType,
    //     emotion: emotionOF ? emotionOF : "",
    //     emotion_type: emotionType ? emotionType : [],
    //     // application_id: [appID],
    //   }),
    // };
    // callApi(
    //   cust_score_effect_url,
    //   custScoreEffortRequestOptions,
    //   "category-effect-score"
    // );
    setSearchLoader(true);
  };

  const selectedLobDataFunc = (val) => {
    console.log("lobbVALUE", val);
    setSelectedLobData(val);
    console.log("lobbVALUE2", selectedLobData);
  };

  const selectedCampDataFunc = (val) => {
    console.log("lobbCamppain", val);
    setSelectedCampData(val);
    console.log("lobbcampai2", selectedCampData);
  };

  const selectedVendorDataFunc = (val) => {
    console.log("lobbVender", val);
    setSelectedVendorData(val);
    console.log("lobbVendor2", selectedVendorData);
  };

  const selectedLocDataFunc = (val) => {
    console.log("lobbLOC", val);
    setSelectedLocData(val);
    console.log("lobbLOC2", selectedLocData);
  };

  // const handleC2Change = (e) => {
  //   console.log("handleC2Change c2");
  //   console.log("props are here-----------", props);
  //   props?.setFilterData((prevArr) => ({ ...prevArr, custom1: e }));
  //   var url = `${base_url}filter/custom2`;
  //   const requestOptions = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + localStorage.getItem("loginToken"),
  //     },
  //     body: JSON.stringify({
  //       from: props?.fromDate1,
  //       to: props?.toDate1,
  //       datetype: dateType?.value,
  //       custom1: e,
  //     }),
  //   };

  //   fetch(url, requestOptions)
  //     .then((res) => res.json())
  //     .then((data) => {
  //       console.log("c2 data======", data);
  //       if (data.status != 200) {
  //         return;
  //       }
  //       setdefaultValueC1(e);
  //       // props.getC1Value(e);
  //       setC2Data(data.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleC3Change = (e) => {
    console.log("handleC3Change c3");
    props?.setFilterData((prevArr) => ({ ...prevArr, custom2: e }));
    setdefaultValueC1(e);
  };

  // const handleC4Change = (e) => {
  //   console.log("handleC4Change c4");
  //   props?.setFilterData((prevArr) => ({ ...prevArr, custom3: e }));

  //   var url = `${base_url}filter/custom4`;
  //   const requestOptions = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + localStorage.getItem("loginToken"),
  //     },
  //     body: JSON.stringify({
  //       from: props?.fromDate1,
  //       to: props?.toDate1,
  //       datetype: dateType?.value,
  //       custom1: defaultValueC1,
  //       custom2: defaultValueC2,
  //       custom3: e,
  //     }),
  //   };

  //   fetch(url, requestOptions)
  //     .then((res) => res.json())
  //     .then((data) => {
  //       // console.log('c3 data======', data);
  //       if (data.status != 200) {
  //         return;
  //       }
  //       setdefaultValueC3(e);
  //       // props.getC3Value(e);
  //       setC4Data(data.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const handleC5Change = (e) => {
  //   setdefaultValueC4(e);
  //   console.log("handleC5Change c5");
  //   props?.setFilterData((prevArr) => ({ ...prevArr, custom4: e }));

  //   var url = `${base_url}filter/forms`;
  //   fetch(
  //     url,
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + localStorage.getItem("loginToken"),
  //       },
  //       body: JSON.stringify({
  //         from: props?.fromDate1,
  //         to: props?.toDate1,
  //         datetype: dateType?.value,
  //         custom1: defaultValueC1,
  //         custom2: defaultValueC2,
  //         custom3: defaultValueC3,
  //         custom4: e,
  //       }),
  //     },
  //     "formData"
  //   )
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setFormData(data.data);
  //     });

  //   var url = `${base_url}filter/manager2`;
  //   fetch(
  //     url,
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + localStorage.getItem("loginToken"),
  //       },
  //       body: JSON.stringify({
  //         from: props?.fromDate1,
  //         to: props?.toDate1,
  //         datetype: dateType?.value,
  //         custom1: defaultValueC1,
  //         custom2: defaultValueC2,
  //         custom3: defaultValueC3,
  //         custom4: e,
  //       }),
  //     },
  //     "manager2"
  //   )
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setManager2(data.data);
  //     });

  //   // var url = `${base_url}filter/manager1`;
  //   // callApi(url, {
  //   //     method: 'POST',
  //   //     headers: {
  //   //         'Content-Type': 'application/json',
  //   //         'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
  //   //     },
  //   //     body: JSON.stringify({
  //   //         from: props.fromDate,
  //   //         to: props.toDate,
  //   //         custom1: JSON.stringify([defaultValueC1]),
  //   //         custom2: JSON.stringify([defaultValueC2]),
  //   //         custom3: JSON.stringify([defaultValueC3]),
  //   //         custom4: JSON.stringify([defaultValueC4]),
  //   //         manager2: JSON.stringify(props.manager2)
  //   //     })
  //   // }, 'manager1');

  //   // var url = `${base_url}filter/supervisor`;
  //   // callApi(url, {
  //   //     method: 'POST',
  //   //     headers: {
  //   //         'Content-Type': 'application/json',
  //   //         'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
  //   //     },
  //   //     body: JSON.stringify({
  //   //         from: props.fromDate,
  //   //         to: props.toDate,
  //   //         custom1: JSON.stringify([defaultValueC1]),
  //   //         custom2: JSON.stringify([defaultValueC2]),
  //   //         custom3: JSON.stringify([defaultValueC3]),
  //   //         custom4: JSON.stringify([e]),
  //   //         manager1: JSON.stringify([props.manager1])
  //   //     })
  //   // }, 'supervisor');

  //   // var url = `${base_url}filter/agent`;
  //   // callApi(url, {
  //   //     method: 'POST',
  //   //     headers: {
  //   //         'Content-Type': 'application/json',
  //   //         'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
  //   //     },
  //   //     body: JSON.stringify({
  //   //         from: props.fromDate,
  //   //         to: props.toDate,
  //   //         supervisor: JSON.stringify([props.supervisor])
  //   //     })
  //   // }, 'agent');
  // };

  const getBookmarkTags = (val, accordian_id, id) => {
    let terms = allBookMarksData
      .filter((id) => id.bookmark_id === val)
      .map((bm) => bm.terms);
    setAllTagssData(terms[0]);
    setTagsArrFunc(terms[0]);
    $(`#${accordian_id}`).toggle("fast", "linear");
    $(`#${id}`).css("backgroundColor", "#0686D0");
    $(`#${id}`).css("color", "#fff");

    if (lastid !== "") {
      $(`#${lastid}`).css("backgroundColor", "");
      $(`#${lastid}`).css("color", "#000");
    }

    if (lastid !== "" && lastid !== id) {
      $(`#${accordian_id}`).toggle("fast", "linear");
    }

    if (lastid === id) {
      $(`#${id}`).css("backgroundColor", "");
      $(`#${id}`).css("color", "#000");
      lastid = "";
    } else {
      lastid = id;
    }
  };

  const removeTermsValue = (tt) => {
    const termKey = tt?.split(`:`);
    // termDataArray?.map((item,ind)=>{
    //     if(item === termKey[1]){
    //         termDataArray.splice(ind, 1)
    //     }
    // })

    setTermDataArray(
      termDataArray?.length > 0
        ? termDataArray.filter((item) => item !== termKey[1])
        : []
    );
    // var index;
    // allBookTagsData.map((i,j) => {
    //     console.log(i)
    // })
    // console.log('index of======', index);
    // var abcde = allBookTagsData.filter(tt1 => tt1.terms_text !== tt);
    // allBookTagsData.push(abcd)
    // setAllTagssData(prevState => [
    //     ...prevState,
    //     abcd
    // ]);
    // console.log('tagsarr1=============', abcde);
  };

  const setTagsArrFunc = (terms, id) => {
    tagsArr = [];
    if (!typeof terms === String)
      terms.map((i, j) => {
        tagsArr.push(i.terms_name);
      });
    // tagsArr.map((i, j) => {
    //     i.terms.push({
    //         terms_name : terms_name
    //     })
    // })
  };

  const setSilenceTimeValue = (val) => {
    setSilenceTime(val);
  };

  const setDurationValue = (val) => {
    setDuration(val);
  };

  const setSilenceTimeValueFunc = (min, max) => {
    setSilenceTime(min);
  };
  console.log("termsArr", termsArr);

  const setSearchTerm = () => {
    // var term_name = termVal + ':' + termText;
    setSearchTermValue((prevState) => [...prevState, termVal + ":" + termText]);
  };

  const setTermSearchCriteria = () => {
    $("#ats_accordion_05").toggle("slow", "linear");
    setAllTagssData((prevState) => [
      ...prevState,
      {
        terms_id: "",
        terms_name: termCreator + ":" + termWord,
        terms_createdBy: localStorage.getItem("loginUserUniqueid"),
      },
    ]);

    setTermsArr((prevState) => [...prevState, termCreator + ":" + termWord]);
    setTermDataArray((terms) => [...terms, termWord]);
    setTermWord("");
  };

  const handleAddBookmark = () => {
    if (bookMarkValue === "") {
      ErrorNotify("Please enter bookmark name");
      return;
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        bookmark_name: bookMarkValue,
        created_by: localStorage.getItem("loginUserUniqueid"),
        operator: bookmarkBooleanValue ? "AND" : "OR",
        terms: searchTermValue,
      }),
    };
    var url = `${base_url}callspeech/addbookmark`;
    callApi(url, requestOptions, "add-bookmark-value");
  };

  const addTermValue = () => {
    setSearchTermValue([]);
    allBookTagsData.map((i, j) => {
      // var pair = i.terms_text + ':' + i.terms_phrase;
      setSearchTermValue((prevState) => [...prevState, i.terms_name]);
    });
  };

  const onMaxClick = (e) => {
    console.log("onMaxClick", e);
  };

  const handleAppChange = (e) => {
    // setClearApp(true);
    // setAppID(e.value);
    // setAppName(e.label);
    // getFolders(e.value);
    // setFolderListData([]);
    // setFolderID('');
    // setFolderName('');
  };

  // getting all folders on call search
  const getAllFolders = () => {
    var url = `${base_url}folder/folder-list`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    callApi(url, requestOptions, "all-folders-list");
  };

  // getting applications based on folder id
  const getFoldersApplications = (folder_id) => {
    console.log("folder_id", folder_id);
    // setAppName("");
    // setAppID([]);
    var url = `${base_url}folder/all-application-folder-wise/${
      folder_id?.length > 0 && folder_id
    }`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    callApi(url, requestOptions, "folder-wise-app-list");
  };

  // getting applications based on folder id
  const getFoldersTags = (folder_id) => {
    setTagName("");
    setTagID([]);
    var url = `${base_url}tags/tag-folder/${
      folder_id?.length > 0 && folder_id
    }`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    callApi(url, requestOptions, "folder-wise-tag-list");
  };

  const handleEmotionTypChange = (e, event) => {
    console.log("handleFolderChange", e);

    if (event?.option?.value == "all" && event?.action == "select-option") {
      setEmotionTypValue(emotionTypeData);
      // props?.setFilteredFolders&&props?.setFilteredFolders(folderListData)
      setEmotionType(["all"]);
    } else if (
      event?.option?.value == "all" &&
      event?.action == "deselect-option"
    ) {
      // props?.setFilteredFolders&&props?.setFilteredFolders([])
      setEmotionTypValue([]);
      setEmotionType([]);
    } else {
      if (e.some((i) => i.value == "all")) {
        let filterData = e.filter((item) => item?.value !== "all");

        setEmotionTypValue(filterData);
        let filterDataValue = [];
        filterData?.map((item) => {
          filterDataValue.push(item.value);
        });
        setEmotionType(filterDataValue);
      } else {
        if (folderListData?.length - 1 == e?.length) {
          setEmotionTypValue(folderListData);

          setEmotionType(["all"]);
        } else {
          let emotionTypData = [];
          e?.map((item) => {
            emotionTypData.push(item);
          });
          setEmotionTypValue(emotionTypData);
          // props?.setFilteredFolders && props?.setFilteredFolders(folderData);
          let emotionTypeValue = [];
          e?.map((item) => {
            emotionTypeValue.push(item.value);
          });
          setEmotionType(emotionTypeValue);
        }
      }
    }
  };

  const handleFolderChange = (e, event) => {
    if (event?.option?.value == "All" && event?.action == "select-option") {
      setFolderValue(folderListData);
      console.log("11111111111", folderListData);
      props?.setFilteredFolders && props?.setFilteredFolders(folderListData);
      let allFolderValue = [];
      folderListData &&
        folderListData?.map((item) => {
          if (item?.value !== "All") {
            allFolderValue.push(item?.value);
          }
        });
      setFolderID(allFolderValue);
    } else if (
      event?.option?.value == "All" &&
      event?.action == "deselect-option"
    ) {
      console.log("2222222222");
      props?.setFilteredFolders && props?.setFilteredFolders([]);
      setFolderValue([]);
      setFolderID([]);
    } else {
      if (e.some((i) => i.value == "All")) {
        console.log("3333333333");
        let filterData = e.filter((item) => item?.value !== "All");

        setFolderValue(filterData);
        let filterDataValue = [];
        filterData?.map((item) => {
          filterDataValue.push(item.value);
        });
        setFolderID(filterDataValue);
      } else {
        if (folderListData?.length - 1 == e?.length) {
          console.log("444444444");
          setFolderValue(folderListData);

          setFolderID(
            folderListData
              ?.filter((item) => item?.value !== "All")
              ?.map((i) => i?.value)
          );
        } else {
          console.log("55555555555");

          let folderData = [];
          e?.map((item) => {
            folderData.push(item);
          });
          setFolderValue(folderData);
          props?.setFilteredFolders && props?.setFilteredFolders(folderData);
          let folderValue = [];
          e?.map((item) => {
            folderValue.push(item.value);
          });
          setFolderID(folderValue);
        }
      }
    }
  };

  // getting folder based on application id
  const getFolders = (app_id) => {
    var url = `${base_url}folder/all-application-folder-list/${app_id}`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    callApi(url, requestOptions, "folder-list");
  };

  // remove bookmarks here
  const removeBookmarks = (id) => {
    var url = `${base_url}callspeech/delbookmark`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        bookmark_id: id,
      }),
    };
    callApi(url, requestOptions, "remove-bookmarks");
  };
  console.log("termDataArray", termDataArray);

  const callApi = async (url, requestOptions, flag = "") => {
    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        switch (flag) {
          case "all-folders-list":
            console.log("all-folders-list------", data);
            setSearchLoader(false);
            if (data?.status == 200 || data?.status == 201) {
              if (data?.data?.length > 0) {
                let folderListArr = [];
                folderListArr.push({ label: "All", value: "All" });

                data?.data?.map((item) => {
                  const values = {
                    label: item.folder_name,
                    value: item.folder_id,
                  };
                  folderListArr.push(values);
                });
                setFolderListData(folderListArr);
                setFolderID([folderListArr[1]?.value]);
                setFolderValue([folderListArr[1]]);
                props?.setFilteredFolders &&
                  props?.setFilteredFolders([folderListArr[1]]);
              }
            } else if (data?.status >= 400 || data?.status <= 422) {
              setSearchLoader(false);
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.error, {
                  icon: "error",
                });
                return;
              }
            } else {
              setSearchLoader(false);
              ErrorNotify(data.error, {
                icon: "error",
              });
              return;
            }
            break;

          case "folder-wise-app-list":
            console.log("folder-wise-app-list---------", data);
            if (data.status === 200) {
              props?.setAppListData && props?.setAppListData(data.data);
            }
            setSearchLoader(false);
            break;

          case "folder-wise-tag-list":
            console.log("folder-wise-tag-list---------", data);
            if (data.status === 200) {
              setTagListData(data.data);
            }
            setSearchLoader(false);
            break;

          case "remove-bookmarks":
            console.log("remove-bookmarks-------", data);
            if (data.status === 200) {
              SuccessNotify(data.message);
              setAllTagssData([]);
              return;
            }
            setSearchLoader(false);
            break;

          case "folder-list":
            console.log("folder-list--------", data);
            if (data.status) {
              setFolderListData(data.data);
            }
            setSearchLoader(false);
            break;

          case "app-list":
            if (isNaN(data.status)) {
              Logout();
              return;
            }
            if (data.status === 200) {
              props?.setAppListData &&
                props?.setAppListData(
                  data.data.application
                    .filter((i, j) => i.application_type === "general")
                    .map((app) => app)
                );
              return;
            }
            setSearchLoader(false);
            break;

          case "meta-data":
            // console.log('meta data============', JSON.parse(data.data));
            if (isNaN(data.status)) {
              // swal(data.status);
              Logout();
              return;
            }
            if (data.status === 200) {
              setLobData(JSON.parse(data.data).lob);
              {
                console.log("metaLobDAta", lobData);
              }
              setCompaignData(JSON.parse(data.data).campaign);
              {
                console.log("metaLobDAtacomp", compaignData);
              }
              setVendorData(JSON.parse(data.data).vendor);
              {
                console.log("metaLobDAtavendor", vendorData);
              }
              setLocData(JSON.parse(data.data).loc);
              {
                console.log("metaLobDAtaloc", locData);
              }
            }
            setSearchLoader(false);
            // swal(data)
            break;
          case "category-chart":
            if (data?.status == 200 || data?.status == 201) {
              props?.setChart5status && props?.setChart5status(false);
              setSearchLoader(false);
              if (data?.data) {
                console.log("category-chart", data?.data);
                props?.setApplicationCategory(data?.data);
              }
              const line_chart_requestOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("loginToken"),
                },
                body: JSON.stringify({
                  // application_id: ["169592827540659"],
                  overall_from_date: props?.fromDate1,
                  overall_to_date: props?.toDate1,
                  folder_id: folderID,
                  // lob: defaultValueC2,
                  campaign: defaultValueC1,
                  // vendor: defaultValueC3,
                  // location: defaultValueC4,
                  gender: genderOF,
                  gender_type: genderType,
                  emotion: emotionOF ? emotionOF : "",
                  emotion_type: emotionType ? emotionType : [],
                  silent_time: endSlTime,
                  start_silent_time: startSlTime,
                  duration: props?.endDuration,
                  start_duration: props?.startDuration,
                }),
              };
              var line_chart_url = `${base_url}smartspeechtags/tag-trend-chart-${
                timeFormat?.value == "daily-tag-data"
                  ? "daily-data"
                  : timeFormat?.value == "monthly-tag-data"
                  ? "monthly-data"
                  : "weekly-data"
              }`;
              callApi(
                line_chart_url,
                line_chart_requestOptions,
                "line-chart-data"
              );
            } else if (data?.status >= 400 || data?.status <= 422) {
              props?.setChart5status && props?.setChart5status(false);
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setSearchLoader(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                setSearchLoader(false);
                return;
              }
            } else {
              props?.setChart5status && props?.setChart5status(false);
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              setSearchLoader(false);
              return;
            }
            break;

          case "category-effect-score":
            if (data?.status == 200 || data?.status == 201) {
              console.log("setCustEffectScoreAttr", data);
              if (data?.dataset?.length > 0) {
                let values = {
                  categories: data?.categories,
                  dataset: data?.dataset,
                };
                props?.setCustEffectScoreAttr(values);
              }
              var escalations_hot_words_data = `${base_url}smartspeechtags/escalation-tag-bubble-chart-phrase-count`;

              const requestOptionsForEscalationsHotWordsChart = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("loginToken"),
                },
                body: JSON.stringify({
                  application: "",
                  folder_id: folderID,
                  overall_from_date: props?.fromDate1,
                  overall_to_date: props?.toDate1,
                  chart: "Escalations Hot Words",
                  silent_time: endSlTime,
                  start_silent_time: startSlTime,
                  duration: props?.endDuration,
                  start_duration: props?.startDuration,
                  campaign: defaultValueC1,
                  gender: genderOF,
                  gender_type: genderType,
                  emotion: emotionOF ? emotionOF : "",
                  emotion_type: emotionType ? emotionType : [],
                }),
              };
              callApi(
                escalations_hot_words_data,
                requestOptionsForEscalationsHotWordsChart,
                "escalations-hot-words"
              );
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setSearchLoader(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                setSearchLoader(false);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              setSearchLoader(false);
              return;
            }
            break;
          case "dashboard-filter":
            if (data?.status == 200 || data?.status == 201) {
              props?.setChart1status && props?.setChart1status(false);
              console.log("setFilterData", data);
              if (data?.overall_weekly_data) {
                props?.setFilterData(data.overall_weekly_data);
              }
              if (data?.overall_monthly_data) {
                props?.setFilterData(data.overall_monthly_data);
              }
              if (data?.overall_daily_data) {
                props?.setFilterData(data.overall_daily_data);
              }
              var agent_emotions_url = `${base_url}smartspeechtags/emotion-tag-avg`;
              callApi(
                agent_emotions_url,
                requestOptions,
                "agent-emotions-data"
              );

              setSearchLoader(false);
              props?.toggleDrawer(false);
            } else if (data?.status >= 400 || data?.status <= 422) {
              props?.setChart1status && props?.setChart1status(false);
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setSearchLoader(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                setSearchLoader(false);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              setSearchLoader(false);
              return;
            }
            break;
          case "line-chart-data":
            if (data?.status == 200 || data?.status == 201) {
              props?.setChart6status && props?.setChart6status(false);
              console.log("line-chart-data", data);
              if (data?.overall_weekly_data) {
                props?.setCallDriverTrendData(data.overall_weekly_data);
              }
              if (data?.overall_monthly_data) {
                props?.setCallDriverTrendData(data.overall_monthly_data);
              }
              if (data?.overall_daily_data) {
                props?.setCallDriverTrendData(data.overall_daily_data);
              }
              const requestOptionsForDetractorsEscalationsData = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("loginToken"),
                },
                body: JSON.stringify({
                  from_date: props?.fromDate1,
                  to_date: props?.toDate1,
                  folder_id: folderID,
                  silent_time: endSlTime,
                  start_silent_time: startSlTime,
                  duration: props?.endDuration,
                  start_duration: props?.startDuration,
                  campaign: defaultValueC1,
                  gender: genderOF,
                  gender_type: genderType,
                  emotion: emotionOF ? emotionOF : "",
                  emotion_type: emotionType ? emotionType : [],
                }),
              };

              var detractors_app_data = `${base_url}smartspeechtags/detractors-app-tag-data`;

              callApi(
                detractors_app_data,
                requestOptionsForDetractorsEscalationsData,
                "detractors-app-data"
              );
              setSearchLoader(false);
              props?.toggleDrawer(false);
            } else if (data?.status >= 400 || data?.status <= 422) {
              props?.setChart6status && props?.setChart6status(false);
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setSearchLoader(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                setSearchLoader(false);
                return;
              }
            } else {
              props?.setChart6status && props?.setChart6status(false);
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              setSearchLoader(false);
              return;
            }
            break;
          case "overall-tag-data":
            if (data?.status == 200 || data?.status == 201) {
              console.log("overall Data", data);
              if (data?.overall_data) {
                props?.setOverallData(data?.overall_data);
              }
              setSearchLoader(false);
              props?.toggleDrawer(false);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setSearchLoader(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                setSearchLoader(false);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              setSearchLoader(false);
              return;
            }
            break;
          case "all-bookmarks":
            console.log("all-bookmarks-----------", data);
            if (data.status === 200) {
              setAllBookMarksData(data.data.bookmarks);
            }
            break;
          case "add-bookmark-value":
            console.log("added bookmark data ------", data);
            if (data.status === 200) {
              SuccessNotify(data.message);
              setTimeout(() => {
                window.location.reload();
              }, 5600);
              return;
            }
            break;
          case "agent-emotions-data":
            if (data?.status == 200 || data?.status == 201) {
              props?.setEmotionsData && props?.setEmotionsData(data?.data);
              var average_call_duration_url = `${base_url}smartspeechtags/emotion-tag-duration`;

              callApi(
                average_call_duration_url,
                requestOptions,
                "average-call-duration"
              );
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setSearchLoader(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                setSearchLoader(false);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              setSearchLoader(false);
              return;
            }
            break;
          case "average-call-duration":
            if (data?.status == 200 || data?.status == 201) {
              props?.setDurationData && props?.setDurationData(data?.data);
              const requestOptionsForCategorizarionData = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("loginToken"),
                },
                body: JSON.stringify({
                  // application_id: ["169592827540659"],
                  from_date: props?.fromDate1,
                  to_date: props?.toDate1,
                  folder_id: folderID,
                  silent_time: endSlTime,
                  start_silent_time: startSlTime,
                  duration: props?.endDuration,
                  start_duration: props?.startDuration,
                  campaign: defaultValueC1,
                  gender: genderOF,
                  gender_type: genderType,
                  emotion: emotionOF ? emotionOF : "",
                  emotion_type: emotionType ? emotionType : [],
                }),
              };

              var application_percentage_chart = `${base_url}smartspeechtags/tag-percentage-chart`;

              callApi(
                application_percentage_chart,
                requestOptionsForCategorizarionData,
                "application-percentage-chart"
              );
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setSearchLoader(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                setSearchLoader(false);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              setSearchLoader(false);
              return;
            }
            break;
          case "application-percentage-chart":
            if (data.status === 200) {
              props?.setCategorizationData &&
                props?.setCategorizationData(data?.data);
              var app_cat_url = `${base_url}smartspeechtags/tag-data-chart`;

              const catRequestOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("loginToken"),
                },
                body: JSON.stringify({
                  from_date: props?.fromDate1,
                  to_date: props?.toDate1,
                  folder_id: folderID,
                  silent_time: endSlTime,
                  start_silent_time: startSlTime,
                  duration: props?.endDuration,
                  start_duration: props?.startDuration,
                  campaign: defaultValueC1,
                  gender: genderOF,
                  gender_type: genderType,
                  emotion: emotionOF ? emotionOF : "",
                  emotion_type: emotionType ? emotionType : [],
                }),
              };
              callApi(app_cat_url, catRequestOptions, "category-chart");
            }
            break;
          case "detractors-hot-words":
            if (data.status === 200) {
              props?.setDetractorsHotWordsData &&
                props?.setDetractorsHotWordsData(data?.data);
            }
            break;
          case "escalations-hot-words":
            if (data.status === 200) {
              props?.setExcalationsHotWordsData &&
                props?.setExcalationsHotWordsData(data?.data);
              const requestOptionsForDetractorHotWordsChart = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("loginToken"),
                },
                body: JSON.stringify({
                  application: "",
                  folder_id: folderID,
                  overall_from_date: props?.fromDate1,
                  overall_to_date: props?.toDate1,
                  chart: "Detractors Hot Words",
                  silent_time: endSlTime,
                  start_silent_time: startSlTime,
                  duration: props?.endDuration,
                  start_duration: props?.startDuration,
                  campaign: defaultValueC1,
                  gender: genderOF,
                  gender_type: genderType,
                  emotion: emotionOF ? emotionOF : "",
                  emotion_type: emotionType ? emotionType : [],
                }),
              };

              var detractors_hot_words_data = `${base_url}smartspeechtags/detractors-tag-bubble-chart-phrase-count`;

              callApi(
                detractors_hot_words_data,
                requestOptionsForDetractorHotWordsChart,
                "detractors-hot-words"
              );
            }
            break;
          case "detractors-app-data":
            if (data.status === 200) {
              const requestOptionsForDetractorsEscalationsData = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("loginToken"),
                },
                body: JSON.stringify({
                  from_date: props?.fromDate1,
                  to_date: props?.toDate1,
                  folder_id: folderID,
                  silent_time: endSlTime,
                  start_silent_time: startSlTime,
                  duration: props?.endDuration,
                  start_duration: props?.startDuration,
                  campaign: defaultValueC1,
                  gender: genderOF,
                  gender_type: genderType,
                  emotion: emotionOF ? emotionOF : "",
                  emotion_type: emotionType ? emotionType : [],
                }),
              };
              props?.setDetractorsData && props?.setDetractorsData(data?.data);
              var escalations_app_data = `${base_url}smartspeechtags/escalations-app-tag-data`;
              callApi(
                escalations_app_data,
                requestOptionsForDetractorsEscalationsData,
                "escalations-app-data"
              );
            }
            break;
          case "escalations-app-data":
            if (data.status === 200) {
              props?.setEscalationsData &&
                props?.setEscalationsData(data?.data);
              const requestOptionsForCustomerScore = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("loginToken"),
                },
                body: JSON.stringify({
                  // application_id : ["169592827540659"],
                  from_date: props?.fromDate1,
                  to_date: props?.toDate1,
                  folder_id: folderID,
                  silent_time: endSlTime,
                  start_silent_time: startSlTime,
                  duration: props?.endDuration,
                  start_duration: props?.startDuration,
                  campaign: defaultValueC1,
                  gender: genderOF,
                  gender_type: genderType,
                  emotion: emotionOF ? emotionOF : "",
                  emotion_type: emotionType ? emotionType : [],
                }),
              };

              var customer_score_effect = `${base_url}smartspeechtags/customer-score-effect-tags`;

              callApi(
                customer_score_effect,
                requestOptionsForCustomerScore,
                "customer-score-effect"
              );
            }
            break;
          case "types-of-escalations-chart":
            if (data?.status == 200 || data?.status == 201) {
              props?.setTypesOfExcalations &&
                props?.setTypesOfExcalations(data?.data);
              var cust_score_url = `${base_url}smartspeechtags/customer-score-effect-attribute-tags`;

              const custEffortRequestOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("loginToken"),
                },
                body: JSON.stringify({
                  from_date: props?.fromDate1,
                  to_date: props?.toDate1,
                  folder_id: folderID,
                  silent_time: endSlTime,
                  start_silent_time: startSlTime,
                  duration: props?.endDuration,
                  start_duration: props?.startDuration,
                  campaign: defaultValueC1,
                  gender: genderOF,
                  gender_type: genderType,
                  emotion: emotionOF ? emotionOF : "",
                  emotion_type: emotionType ? emotionType : [],
                  // application_id: [appID],
                }),
              };
              callApi(
                cust_score_url,
                custEffortRequestOptions,
                "category-effect-score"
              );
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setSearchLoader(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                setSearchLoader(false);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              setSearchLoader(false);
              return;
            }
            break;

          case "customer-score-effect":
            if (data?.status == 200 || data?.status == 201) {
              props?.setChart12status && props?.setChart12status(false);
              console.log("overall Data", data);
              props?.setCustomerScoreData &&
                props?.setCustomerScoreData(data?.data);
              const requestOptionsForExcalationsTypesOnLoad = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("loginToken"),
                },
                body: JSON.stringify({
                  from_date: props?.fromDate1,
                  to_date: props?.toDate1,
                  folder_id: folderID,
                  silent_time: endSlTime,
                  start_silent_time: startSlTime,
                  duration: props?.endDuration,
                  start_duration: props?.startDuration,
                  campaign: defaultValueC1,
                  gender: genderOF,
                  gender_type: genderType,
                  emotion: emotionOF ? emotionOF : "",
                  emotion_type: emotionType ? emotionType : [],
                }),
              };
              var types_of_escalations_chart = `${base_url}smartspeechtags/escalations-type-tag-data`;
              callApi(
                types_of_escalations_chart,
                requestOptionsForExcalationsTypesOnLoad,
                "types-of-escalations-chart"
              );
              setSearchLoader(false);
              props?.toggleDrawer(false);
            } else if (data?.status >= 400 || data?.status <= 422) {
              props?.setChart12status && props?.setChart12status(false);
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setSearchLoader(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                setSearchLoader(false);
                return;
              }
            } else {
              props?.setChart12status && props?.setChart12status(false);
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              setSearchLoader(false);
              return;
            }

          default:
            break;
        }
      })
      .catch((err) => {
        DismissToast();
        setIsLoading(false);
        props?.setChart1status && props?.setChart1status(false);
        props?.setChart5status && props?.setChart5status(false);
        props?.setChart6status && props?.setChart6status(false);
        props?.setChart12status && props?.setChart12status(false);
        setSearchLoader(false);
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        return;
        // console.log('========================', data.data);
      });
  };

  // const allAppl = [{ value: "", label: "Select" }, ...allApplications];
  // var dynamicHeadersArr = [];
  // var dataArr = {};

  // var arr = [
  //     { "cat_name": "Test Category 1", "phrase_count_percentage": "31%" },
  //     { "cat_name": "Test Category 2", "phrase_count_percentage": "69%" },
  //     { "cat_name": "Test Category 3", "phrase_count_percentage": "0%" }
  // ];

  // arr.map((i, j) => {
  //     dynamicHeadersArr.push(
  //         { Header: i.cat_name, accessor: i.cat_name.replace(/ /g, "_") }
  //     )
  //     dataArr[i.cat_name.replace(/ /g, "_")] = <p>{i.phrase_count_percentage}</p>;
  // });

  const columns = React.useMemo(
    () =>
      // dynamicHeadersArr,
      [
        {
          Header: "Call ID",
          accessor: "file_name", // accessor is the "key" in the data
        },
        {
          Header: "Agent ID",
          accessor: "agent_id",
        },
        {
          Header: "Form Name",
          accessor: "form_name",
        },

        {
          Header: "Time",
          accessor: "time", // accessor is the "key" in the data
        },
        {
          Header: "Duration",
          accessor: "duration",
        },
        {
          Header: "Silence Time",
          accessor: "silence_time",
        },
        {
          Header: "Overtalk",
          accessor: "overtalk",
        },

        {
          Header: "Diarization",
          accessor: "diarization", // accessor is the "key" in the data
        },
        {
          Header: "Agent Clarity",
          accessor: "agent_clarity",
        },
        {
          Header: "Client Clarity",
          accessor: "client_clarity",
        },
        {
          Header: "Agent Gender",
          accessor: "agent_gender",
        },
        {
          Header: "Client Gender",
          accessor: "client_gender",
        },
        {
          Header: "Overall Emotion",
          accessor: "overall_emotion",
        },
        {
          Header: "Agent Emotion",
          accessor: "agent_emotion",
        },

        {
          Header: "Client Emotion",
          accessor: "client_emotion",
        },

        {
          Header: "Transcript ID",
          accessor: "transcript_id",
        },

        {
          Header: "Request ID",
          accessor: "request_id",
        },

        {
          Header: "Score",
          accessor: "score",
        },
      ],
    []
  );

  const data = React.useMemo(() =>
    callSearchData
      ?.filter((item) => {
        return searchValue === ""
          ? item && item
          : (item?.agent_id &&
              item?.agent_id?.toLowerCase().includes(searchValue)) ||
              (item?.call_id &&
                item?.call_id?.toLowerCase().includes(searchValue)) ||
              (item?.agent_emotion &&
                item?.agent_emotion?.toLowerCase().includes(searchValue)) ||
              (item?.agent_gender &&
                item?.agent_gender?.toLowerCase().includes(searchValue)) ||
              (item?.call_view_status &&
                item?.call_view_status?.toLowerCase().includes(searchValue)) ||
              (item?.campaign &&
                item?.campaign?.toLowerCase().includes(searchValue)) ||
              (item?.client_emotion &&
                item?.client_emotion?.toLowerCase().includes(searchValue)) ||
              (item?.client_gender &&
                item?.client_gender?.toLowerCase().includes(searchValue)) ||
              (item?.datetime &&
                item?.datetime?.toLowerCase().includes(searchValue)) ||
              (item?.duration &&
                item?.duration?.toLowerCase().includes(searchValue)) ||
              (item?.folder_id &&
                item?.folder_id?.toLowerCase().includes(searchValue)) ||
              (item?.lob && item?.lob?.toLowerCase().includes(searchValue)) ||
              (item?.location &&
                item?.location?.toLowerCase().includes(searchValue)) ||
              (item?.overall_emotion &&
                item?.overall_emotion?.toLowerCase().includes(searchValue)) ||
              (item?.overtalk &&
                item?.overtalk?.toLowerCase().includes(searchValue)) ||
              (item?.silence_time &&
                item?.silence_time?.toLowerCase().includes(searchValue)) ||
              (item?.tran_id &&
                item?.tran_id?.toLowerCase().includes(searchValue)) ||
              (item?.transcription_status &&
                item?.transcription_status
                  ?.toLowerCase()
                  .includes(searchValue)) ||
              (item?.vendor &&
                item?.vendor?.toLowerCase().includes(searchValue)) ||
              (item?.wav && item?.wav?.toLowerCase().includes(searchValue));
        // console.log("ITEN",item);
      })
      .map((i, j) => ({
        file_name: [
          <p className="  td-link">
            <Link
              to={`/smartspeech-call-details?callId=${i.call_id}&wavId=${i.call_id}&appName=${appName}&data=${termDataArray}`}
              target="_blank"
            >
              {i.call_id}
            </Link>
          </p>,
        ],
        agent_id: [
          <p className="  td-link">{i?.agent_id ? i?.agent_id : "NA"}</p>,
        ],
        form_name: [
          <p className="  td-link">{i.form_name ? i.form_name : "NA"}</p>,
        ],

        time: [<p className="  td-link">{i.datetime}</p>],
        duration: [<p className="  td-link">{i.duration}</p>],
        silence_time: [<p className="  td-link">{i.silence_time}</p>],

        overtalk: [
          <p className="  td-link">{i.overtalk ? i.overtalk : "NA"}</p>,
        ],
        diarization: [
          <p className="  td-link">{i.diarization ? i.diarization : "NA"}</p>,
        ],
        agent_clarity: [
          <p className="  td-link">
            {i.agent_clarity ? i.agent_clarity : "NA"}
          </p>,
        ],

        client_clarity: [
          <p className="  td-link">
            {i.client_clarity ? i.client_clarity : "NA"}
          </p>,
        ],
        agent_gender: [<p className="  td-link">{i.agent_gender}</p>],
        client_gender: [<p className="  td-link">{i.client_gender}</p>],

        overall_emotion: [<p className="  td-link">{i.overall_emotion}</p>],
        agent_emotion: [<p className="  td-link">{i.agent_emotion}</p>],
        client_emotion: [<p className="  td-link">{i.client_emotion}</p>],

        transcript_id: [<p className="  td-link">{i.tran_id}</p>],
        request_id: [<p className="  td-link">{i.req_id ? i.req_id : "NA"}</p>],
        score: [<p className="  td-link">{i.score ? i.score : "NA"}</p>],

        // Action: [(i.transcribe_status === 'p' || i.transcribe_status === 'P') && <div className="table-checkbox">
        //     <input className="ckbCheckAll filled-in assign_checkbox" type="checkbox" onChange={(e) => setCallIdArr(prevState => [
        //         ...prevState,
        //         i.file_name
        //     ])} />
        // </div>],
      }))
  );

  // const data = [];

  return (
    <div className="filter-area">
      <div
        id="filter_drawer"
        className={`san-drawer right${props?.isDrawerOpen ? " open" : ""}`}
        data-reference="filter_drawer"
      >
        <form
          action=""
          className="filter_page_form"
          method="post"
          acceptCharset="utf-8"
        >
          <div className="drawer-card">
            <div className="drawer-header d-flex align-items-center justify-content-between">
              <h4>Filter</h4>
              <button type="button" className="drawer-close"></button>
            </div>
            <div className="drawer-body">
              {/* new search criteria starts here */}
              <div className="form-group">
                <label className="form-label">
                  <span>From</span>
                  <span className="mandatory">*</span>
                </label>
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) => [
                    props?.setFromDate1 && props?.setFromDate1(e.target.value),
                    props?.setFromDateForApp &&
                      props?.setFromDateForApp(e.target.value),
                  ]}
                  max={props?.toDate1}
                  placeholder="From Date"
                  required
                  value={props?.fromDate1}
                />
              </div>
              <div className="form-group">
                <label className="form-label">
                  <span>To</span>
                  <span className="mandatory">*</span>
                </label>
                <input
                  type="date"
                  onChange={(e) => [
                    props?.setToDate1 && props?.setToDate1(e.target.value),
                    props?.setToDateForApp &&
                      props?.setToDateForApp(e.target.value),
                  ]}
                  min={props?.fromDate1}
                  max={today}
                  className="form-control"
                  placeholder="To Date"
                  required
                  value={props?.toDate1}
                />
              </div>
              <div className="form-group">
                <label>
                  <span>Chart Data</span>
                  <span className="mandatory">*</span>
                </label>
                <Select
                  options={[
                    { value: "monthly-tag-data", label: "Month Wise" },
                    { value: "weekly-tag-data", label: "Week Wise" },
                    { value: "daily-tag-data", label: "Day Wise" },
                  ]}
                  placeholder="Select Chart Data"
                  isSearchable={true}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: "100px",
                      boxShadow: "none", // Remove the blue box shadow
                      borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                      "&:hover": {
                        borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                      },
                      "&:focus": {
                        outline: "none", // Remove the blue outline on focus
                        borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                        boxShadow: "none", // Remove the blue box shadow on focus
                      },
                      "&:active": {
                        borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                        boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                      },
                    }),
                    indicatorSeparator: (provided) => ({
                      ...provided,
                      display: "none",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      background: state.isSelected ? "#fff" : "transparent", // Remove background highlight from non-selected options
                      color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                    }),
                  }}
                  value={timeFormat}
                  onChange={handleChartDataChange}
                />
              </div>

              <div className="form-group">
                {/* {folderListData.map((i, j) => {
                  optionsFolderList.push({
                    value: i.folder_id,
                    label: i.folder_name,
                  });
                })} */}
                <label className="form-label">Folders</label>
                <span className="mandatory">*</span>
                {/* <select
                  className="form-control"
                  style={{ borderRadius: "100px" }}
                  onChange={(e) => [
                    setFolderID(e.target.value),
                    setFolderValue(),
                    getFoldersApplications(e),
                    setFolderName(
                      e.nativeEvent.target[e.nativeEvent.target.selectedIndex]
                        .text
                    ),
                  ]}
                  value={folderValue}
                >
                  {folderListData.map((i, j) => {
                    return <option value={i.folder_id}>{i.folder_name}</option>;
                  })}
                </select> */}
                <Select
                  options={folderListData}
                  placeholder="Select Folder"
                  isSearchable={true}
                  isMulti
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: "100px",
                      boxShadow: "none", // Remove the blue box shadow
                      borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                      "&:hover": {
                        borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                      },
                      "&:focus": {
                        outline: "none", // Remove the blue outline on focus
                        borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                        boxShadow: "none", // Remove the blue box shadow on focus
                      },
                      "&:active": {
                        borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                        boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                      },
                    }),
                    indicatorSeparator: (provided) => ({
                      ...provided,
                      display: "none",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      background: state.isSelected ? "#fff" : "transparent", // Remove background highlight from non-selected options
                      color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                    }),
                  }}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  isClearable={true}
                  allowSelectAll={true}
                  components={{
                    Option,
                    ValueContainer: ValueContainer2,
                  }}
                  value={folderValue}
                  onChange={(e, event) => [handleFolderChange(e, event)]}
                />
                {/* </div> */}
                {/* <Select
                                                options={optionsFolderList}
                                                placeholder='Folders'
                                                isSearchable={true}
                                                // value={'appName'}
                                                onChange={handleFolderChange}
                                            /> */}
              </div>
              {/* <div className="form-group"> */}
              {/* <label className="form-label">Applications</label> */}
              {/* <select className="form-control" style={{ borderRadius: '100px' }} onChange={(e) => [setAppID(e.target.value), setAppName(e.target.value)]}>
                                                <option value=''>Select</option>
                                                {appListData.map((i, j) => {
                                                    return (
                                                        <option value={i.application_id}>{i.application_name}</option>
                                                    )
                                                })}
                                            </select> */}
              {/* <Select
                  options={allAppl}
                  placeholder="Select Application"
                  isSearchable={true}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: "100px",
                      boxShadow: "none", // Remove the blue box shadow
                      borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                      "&:hover": {
                        borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                      },
                      "&:focus": {
                        outline: "none", // Remove the blue outline on focus
                        borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                        boxShadow: "none", // Remove the blue box shadow on focus
                      },
                      "&:active": {
                        borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                        boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                      },
                    }),
                    indicatorSeparator: (provided) => ({
                      ...provided,
                      display: "none",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      background: state.isSelected ? "#fff" : "transparent", // Remove background highlight from non-selected options
                      color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                    }),
                  }}
                  // value={timeFormat}
                  onChange={(e) => setAppID(e?.value)}
                />
              </div> */}
              {/* <div className="form-group">
                <label>
                  <span>{hierarchyData.c1}</span>
                </label>
                {console.log("c1Datac1Data", c1Data)}
                <MultiSelection
                  options={c1Data}
                  customSelectionName="lob"
                  isFilter={true}
                  isFormFilter={false}
                  handleC2Change={handleC2Change}
                  filterData={props?.filterData}
                  selectedCampDataFunc={selectedCampDataFunc}
                />
              
              </div> */}

              <div className="form-group">
                <label>
                  <span>Campaign</span>
                </label>
                <MultiSelection
                  options={c1Data}
                  customSelectionName="Campaign"
                  isFilter={true}
                  isFormFilter={false}
                  filterData={props?.filterData}
                  handleC3Change={handleC3Change}
                />
                {/* <select className="form-control custom-multiselectfilter search_type">
                            </select> */}
              </div>

              {/* <div className="form-group">
                <label>
                  <span>{hierarchyData.c3}</span>
                </label>
                <MultiSelection
                  options={c3Data}
                  customSelectionName="vendor"
                  isFilter={true}
                  isFormFilter={false}
                  filterData={props?.filterData}
                  handleC4Change={handleC4Change}
                />
           
              </div> */}

              {/* <div className="form-group">
                <label>
                  <span>{hierarchyData.c4}</span>
                </label>
                <MultiSelection
                  options={c4Data}
                  customSelectionName="Location"
                  isFilter={true}
                  isFormFilter={false}
                  filterData={props?.filterData}
                  handleC5Change={handleC5Change}
                />
               
              </div> */}
              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-6">
                    <label className="form-label">
                      <span>Gender</span>
                    </label>
                    <select
                      className="form-control "
                      onChange={(e) => setGenderOF(e.target.value)}
                    >
                      <option selected={true} value="all_gender">
                        All
                      </option>
                      <option value="client_gender">Client</option>
                      <option value="agent_gender">Agent</option>
                      {/* <option value="speaker_gender">Agent</option> */}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">
                      <span></span>
                    </label>
                    <select
                      className="form-control "
                      onChange={(e) => setGenderType(e.target.value)}
                    >
                      <option value="all" selected={true}>
                        All
                      </option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-6">
                    <label className="form-label">
                      <span>Emotions</span>
                    </label>
                    <select
                      className="form-control "
                      onChange={(e) => setEmotionOF(e.target.value)}
                    >
                      <option selected={true} value="all">
                        Overall
                      </option>
                      <option value="client">Client</option>
                      <option value="agent">Agent</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    {/* <label className="form-label">
                      <span></span>
                    </label>
                    <select
                      className="form-control "
                      onChange={(e) => setEmotionVal(e.target.value)}
                    >
                      <option selected={true} value="All">
                        All
                      </option>
                      <option value="Positive">Positive</option>
                      <option value="Negative">Negative</option>
                      <option value="Improving">Improving</option>
                      <option value="Worsening">Worsening</option>
                    </select> */}
                    <div className="form-group">
                      {/* {folderListData.map((i, j) => {
                  optionsFolderList.push({
                    value: i.folder_id,
                    label: i.folder_name,
                  });
                })} */}
                      <label className="form-label">Emotion Type</label>
                      <span className="mandatory">*</span>
                      {/* <select
                  className="form-control"
                  style={{ borderRadius: "100px" }}
                  onChange={(e) => [
                    setFolderID(e.target.value),
                    setFolderValue(),
                    getFoldersApplications(e),
                    setFolderName(
                      e.nativeEvent.target[e.nativeEvent.target.selectedIndex]
                        .text
                    ),
                  ]}
                  value={folderValue}
                >
                  {folderListData.map((i, j) => {
                    return <option value={i.folder_id}>{i.folder_name}</option>;
                  })}
                </select> */}
                      <Select
                        options={emotionTypeData}
                        placeholder="Select"
                        isSearchable={true}
                        isMulti
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderRadius: "100px",
                            height: "32px!important",
                            fontSize: "11px",
                            minHeight: 0,
                            boxShadow: "none", // Remove the blue box shadow
                            borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                            "&:hover": {
                              borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                            },
                            "&:focus": {
                              outline: "none", // Remove the blue outline on focus
                              borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                              boxShadow: "none", // Remove the blue box shadow on focus
                            },
                            "&:active": {
                              borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                              boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                            },
                          }),
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 1000,
                            marginTop: "6px",
                            width: "100%",
                            position: "absolute",
                            background: "#fff",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                          }),
                          indicatorSeparator: (provided) => ({
                            ...provided,
                            display: "none",
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            marginTop: "-4px",
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            background: state.isSelected
                              ? "#fff"
                              : "transparent", // Remove background highlight from non-selected options
                            color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                          }),
                        }}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        isClearable={true}
                        allowSelectAll={true}
                        components={{
                          Option,
                          ValueContainer: emotionTypeValContainer,
                        }}
                        value={emotionTypValue}
                        onChange={(e, event) => [
                          handleEmotionTypChange(e, event),
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group" style={{ margin: "30px 0" }}>
                <label className="form-label">
                  <span>Silence Time</span>
                </label>
                <MultiRangeSlider
                  rangeLabel={"%"}
                  min={0}
                  max={100}
                  onChange={({ min, max }) => onChangeSlienceTime(min, max)}
                />
                {/* <HorizontalCustomLabels setSilenceTimeValue={setSilenceTimeValue} typeOfSlider="silence" /> */}
                {/* <input type="text" className="form-control" placeholder="Call Id" required /> */}
              </div>
              <div className="form-group" style={{ margin: "30px 0" }}>
                <label className="form-label">
                  <span>Duration</span>
                </label>
                <MultiRangeSlider
                  style={{ height: "15vh" }}
                  min={0}
                  type="time"
                  maxButton={onMaxClick}
                  max={1800000}
                  onChange={({ min, max }) => onChangeDuration(min, max)}
                />
                {/* <HorizontalCustomLabels setDurationValue={setDurationValue} typeOfSlider="duration" /> */}
                {/* <input type="text" className="form-control custom-startdatepicker" placeholder="From Date" required /> */}
              </div>
              {/* new search criteria ends here */}

              {/* <div className="form-group">
                                            <label className="form-label"><span>To</span><span className="mandatory">*</span></label>
                                            <input type="text" className="form-control custom-enddatepicker" placeholder="To Date" required />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label"><span>Agent Id</span><span className="mandatory">*</span></label>
                                            <input type="text" className="form-control" placeholder="Agent Id" required />
                                        </div> */}
            </div>
            <hr />
            <div className="ss-drawer-footer d-flex align-items-center justify-content-between">
              {/* <button type="button" id="filter_sbmtBtn" className="btn btn-secondary alpha">
                                            <span>Reset</span>
                                        </button> */}

              <button
                onClick={handleSearch}
                type="button"
                style={{ marginLeft: "20px", width: "30%", marginTop: "3px" }}
                className={`btn btn-primary beta add_attributes ${
                  searchLoader ? "loading" : ""
                }`}
              >
                <span>Submit</span>
                <span className="loader">
                  <span
                    className="spinner-border"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewDashboardFilter;
