import React, { useEffect, useState, useRef, useCallback } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import swal from "sweetalert";
import { Link } from "react-router-dom";

import { default as ReactSelect } from "react-select";
import { ErrorNotify, SuccessNotify } from "../../../shared/Toast";
import { components } from "react-select";
import Popover from "react-bootstrap/Popover";
import OverlayPopover from "../../../controls/OverlayPopover";
import ButtonComp from "../../../controls/Button";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import fileDownload from "js-file-download";
import $ from "jquery";
import DateRangePicker from "../../../controls/DateRangePicker";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import XLSX from "xlsx";
import Tippy from "@tippyjs/react";
import DataTable from "react-data-table-component";
import DataTableView from "../../../shared/DataTable";
import Login from "../../../auth/loginComponent";
// import 'react-h5-audio-player/lib/styles.css';
const base_url = process.env.REACT_APP_AUTH_BASE_URL;
const base_name = process.env.PUBLIC_URL;
var last_id = "";
var htmlText = "";

let newSSHeaders = [
  {
    label: "call_id",
    key: "call_id",
  },
  {
    label: "datetime",
    key: "datetime",
  },
  {
    label: "agent_id",
    key: "agent_id",
  },
  {
    label: "duration",
    key: "duration",
  },
  {
    label: "folder_id",
    key: "folder_id",
  },
  {
    label: "tag",
    key: "tag",
  },
  {
    label: "applications",
    key: "applications",
  },
  {
    label: "created_at",
    key: "created_at",
  },
  // {
  //     "label": "AgentName",
  //     "key": "AgentName"
  // },
  // {
  //     "label": "Campaign",
  //     "key": "Campaign"
  // },
  // {
  //     "label": "Client",
  //     "key": "Client"
  // },
  // {
  //     "label": "DialledNumber",
  //     "key": "DialledNumber"
  // },
  // {
  //     "label": "Duration",
  //     "key": "Duration"
  // },
  // {
  //     "label": "EmployeeID",
  //     "key": "EmployeeID"
  // },
  // {
  //     "label": "Filename",
  //     "key": "Filename"
  // },
  // {
  //     "label": "Outcome",
  //     "key": "Outcome"
  // },
  // {
  //     "label": "QueueNumber",
  //     "key": "QueueNumber"
  // },
  // {
  //     "label": "Timestamp",
  //     "key": "Timestamp"
  // },
  // {
  //     "label": "URN",
  //     "key": "URN"
  // }
];

var csvReport = {
  data: [],
};
var pdfReport = {
  data: [],
};
var xlsReport = {
  data: [],
};

let allIds = [];

let allBreakData = [];

const SSCallListNew = (props) => {
  const navigate = useNavigate();

  const audioRef = useRef(null);
  const [allCalls, setAllCalls] = useState([]);
  const [succCallsCount, setSuccCallsCount] = useState(0);
  const [filterCallsCount, setFilterCallsCount] = useState(0);

  const [retainCallsData, setRetainCallsData] = useState([]);
  const [conversationId, setConversationId] = useState("");
  const [callTags, setCallTags] = useState("");
  const [allRecordsLoader, setAllRecordsLoader] = useState(true);
  const [popupLoader, setPopupLoader] = useState(true);
  const [callInfo, setCallInfo] = useState([]);
  const [addCallData, setAddCallData] = useState([]);

  const [wavFileInfo, setWavFileInfo] = useState([]);
  const [tagsInfo, setTagsInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  const [agents, setAgents] = useState([]);
  const [tags, setTags] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [fromDuration, setFromDuration] = useState("");
  const [toDuration, setToDuration] = useState("");
  const [allCallTags, setAllCallTags] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  const [from_date, setFromDate] = useState(
    moment().clone().startOf("month").format("YYYY-MM-DD")
  );
  const [to_date, setToDate] = useState(moment().format("YYYY-MM-DD"));

  const [assignValue, setAssignValue] = useState("");
  const [hierarchyData, setHierarchyData] = useState([]);
  const [c1Data, setC1Data] = useState([]);
  const [c2Data, setC2Data] = useState([]);
  const [c3Data, setC3Data] = useState([]);
  const [c4Data, setC4Data] = useState([]);

  const [defaultValueC1, setdefaultValueC1] = useState("");
  const [defaultValueC2, setdefaultValueC2] = useState("");
  const [defaultValueC3, setdefaultValueC3] = useState("");
  const [defaultValueC4, setdefaultValueC4] = useState("");

  const [showEvaluateBtn, setShowEvaluateBtn] = useState(true);
  const [allClasses, setAllClasses] = useState(
    "col-sm-6 col-md-4 col-lg-6 form-group d-none"
  );
  const [otherEvaluatorsData, setOtherEvaluatorsData] = useState([]);
  const [evaluationStatus, setEvaluationStatus] = useState("Pending");
  const [evaluatorName, setEvaluatorName] = useState("");
  const [isLoadingEvaluate, setIsLoadingEvaluate] = useState(false);
  const [isDataFound, setIsDataFound] = useState(false);
  const [isMetaCollapse, setIsMetaCollapse] = useState(false);

  const [mediaValue, setMediaValue] = useState("");
  const [file, setFile] = useState({});
  const [fileName, setFileName] = useState(false);
  const [formData, setFormData] = useState([]);
  const [formName, setFormName] = useState("");
  const fileInputRef = useRef(null);

  const [callDateTime, setCallDateTime] = useState("");
  const [callId, setcallId] = useState("");
  const [agentName, setAgentname] = useState("");
  const [callDuration, setCallDuration] = useState("");
  const [isCustom, setIsCustom] = useState(false);

  const [customFromDate, setCustomFromDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [customToDate, setCustomToDate] = useState(new Date());
  const [agentSupervisors, setAgentSupervisors] = useState("");
  const [storeAllAgents, setStoreAllAgents] = useState([]);
  const [storeAllTags, setStoreAllTags] = useState([]);
  const [appScoreData, setAppScoreData] = useState([]);
  const [clickOnCallLoader, setClickOnCallLoader] = useState(false);
  const [overview, setOverview] = useState("Overview");
  const [timeFilter, setTimeFilter] = useState("Filter By Time...");
  const [filteredBy, setFilteredBy] = useState([]);

  const [filteredByTime, setFilteredByTime] = useState(false);
  const [filteredByAgent, setFilteredByAgent] = useState(false);
  const [filteredByTags, setFilteredByTags] = useState(false);
  const [filteredByDuration, setFilteredByDuration] = useState(false);

  const [whichFilter, setWhichFilter] = useState([]);
  const [renderFilter, setRenderFilter] = useState([]);

  const [handleStartTime, setHandleStartTime] = useState(
    moment().subtract(1, "days").format("YYYY-MM-DD")
  );
  const [handleEndTime, setHandleEndTime] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [showTimeFilter, setShowTimeFilter] = useState("");
  const [showAgentFilter, setShowAgentFilter] = useState([]);
  const [showTagsFilter, setShowTagsFilter] = useState([]);
  const [showFromDurationFilter, setShowFromDurationFilter] = useState("");
  const [showToDurationFilter, setShowToDurationFilter] = useState("");
  const [filteredApplied, setFilteredApplied] = useState(false);
  const [transCounting, setTransCounting] = useState(0);
  const [transcriptTabVisible, setTranscriptTabVisible] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const [addNamePhrase, setAddNamePhrase] = useState("");
  const [filterShowPhrase, setFilterShowPhrase] = useState([]);
  const [activeDiv, setActiveDiv] = useState(2);
  const [operator, setOperator] = useState("AND");
  const [isDataLength, setIsDataLength] = useState(true);
  const [allExportData, setAllExportData] = useState([]);
  const [oneMonthLoading, setOneMonthLoading] = useState(false);

  const [transcriptClickStatus, setTranscriptClickStatus] = useState("");

  const [tagNameForTrans, setTagNameForTrans] = useState("");

  const [folderListData, setFolderListData] = useState([]);

  const [callIdFilterForDisplay, setCallIdFilterForDisplay] = useState("");
  const [callIdFilter, setCallIdFilter] = useState("");
  const [folderValue, setFolderValue] = useState([]);
  const [locationVal, setLocationVal] = useState([]);

  const [filteredFolders, setFilteredFolders] = useState([]);
  const [folderID, setFolderID] = useState("");
  const [folderWiseTags, setFolderWiseTags] = useState([]);

  const [calleeFilter, setCalleeFilter] = useState("");
  const [calleeNoFilterForDisplay, setCalleeNoFilterForDisplay] = useState("");
  const [folderIdFilterForDisplay, setFolderIdFilterForDisplay] = useState("");
  const [morethanOneMonthData, setMorethanOneMonthData] = useState([]);
  const [exportData, setExportData] = useState(false);
  const [exportDataCheck, setExportDataCheck] = useState(false);

  const [excelData, setExcelData] = useState([]);

  const handleDivClick = (divIndex) => {
    setActiveDiv(divIndex);
  };

  console.log(
    "HUUHUHUHUHUH",
    window.location.host?.split(".")[0] === "recruitment"
  );

  useEffect(() => {
    var url = `${base_url}folder/folder-list`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    callApi(url, requestOptions, "all-folders-list");
  }, []);

  const callApi = async (url, requestOptions, flag = "") => {
    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        switch (flag) {
          case "all-folders-list":
            console.log("all-folders-list------", data);
            if (data?.status == 200 || data?.status == 201) {
              if (data?.data?.length > 0) {
                let folderListArr = [];
                // folderListArr.push({ label: "All", value: "All" });
                data?.data?.map((item) => {
                  const values = {
                    label: item.folder_name,
                    value: item.folder_id,
                  };
                  folderListArr.push(values);
                });
                setFolderListData(folderListArr);
                // setFolderID([folderListArr[1]?.value]);
                setFolderValue([folderListArr[0]]);
                // setFilteredFolders([folderListArr[1]]);

                var url2 = `${base_url}tags/tag-folder/${folderListArr[0]?.value}`;

                var requestOptions2 = {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization:
                      "Bearer " + localStorage.getItem("loginToken"),
                  },
                };
                callApi(url2, requestOptions2, "folder-wise-tag");
              }
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.error, {
                  icon: "error",
                });
                return;
              }
            } else {
              ErrorNotify(data.error, {
                icon: "error",
              });
              return;
            }
            break;
          case "folder-wise-tag":
            console.log("folder-wise-tag------", data);
            if (data?.status == 200 || data?.status == 201) {
              if (data?.data?.length > 0) {
                setFolderWiseTags(data?.data);

                console.log(
                  "allTagsDataallTagsData",
                  allTagsData,
                  "datadatadata",
                  data
                );
              }
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.error, {
                  icon: "error",
                });
                return;
              }
            } else {
              ErrorNotify(data.error, {
                icon: "error",
              });
              return;
            }
        }
      });
  };

  const filterHeaders = React.useMemo(() => [
    {
      Header: "Filter By Time",
      accessor: "time", // accessor is the "key" in the data
    },
    {
      Header: "Filter By Agents",
      accessor: "agents", // accessor is the "key" in the data
    },
    {
      Header: "Filter By Tags",
      accessor: "tags", // accessor is the "key" in the data
    },
    {
      Header: "Filter By Duration",
      accessor: "duration", // accessor is the "key" in the data
    },
  ]);

  //
  const jumpForward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime += 10;
    }
  };

  const jumpBackward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime -= 10;
    }
  };
  const ValueContainer = ({ children, ...props }) => {
    const { getValue } = props;
    let selectedOption = getValue().length;
    if (selectedOption > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${selectedOption} selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  // var url = `${base_url}callspeech/all-success-call-records`;
  async function getAllCalls(all_time = "") {
    console.log("12345436565656557", folderValue);
    setIsLoading(true);
    setPopupLoader(true);
    setAllRecordsLoader(true);
    await fetch(
      `${base_url}callspeech/${
        window.location.host?.split(".")[0] === "recruitment"
          ? "recruit-newfilter"
          : "newfilter"
      }`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date: moment().subtract(1, "days").format("YYYY-MM-DD"),
          to_date: moment().format("YYYY-MM-DD"),
          duration: toDuration !== "" ? toDuration : "",
          agent_id:
            storeAllAgents?.length > 0
              ? storeAllAgents?.map((i) => i?.value)
              : [],
          start_duration: fromDuration !== "" ? fromDuration : "",
          call_id: callIdFilter !== "" ? callIdFilter : "",
          operator: operator,
          application:
            storeAllTags?.length > 0 ? storeAllTags?.map((i) => i?.value) : [],
          tag: [],
          call_status: "5",
          // folder_id: [],
          folder_id:
            folderID === ""
              ? [folderValue[0]?.value.toString()]
              : [folderID?.toString()],
          location:
            window.location.host?.split(".")[0] === "recruitment" &&
            locationVal?.value,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.status == 200) {
          if (data?.data?.length > 0) {
            // let getHeader = Object.keys(data?.data[0]?.meta_data).concat(Object.keys(data?.data[0]))?.filter(i => i !== "meta_data" && i !== "_id")
            // let getHeader = data?.data[0]?.folder_meta?.filter(i=>i!=="_id")
            let getHeader = Object.keys(data?.data[0])
              .concat(data?.folder_meta[0]?.metadata)
              ?.filter((i) => i !== "meta_data");

            newSSHeaders = getHeader?.map((item) => ({
              label: item,
              key: item,
            }));
          }

          let newData = [];
          data?.data?.map((item) => {
            newData.push({
              ...item,

              ...item.meta_data,
              folder_id: item.folder_id,
              applications: item?.applications?.map((i) =>
                i?.application_name ? i?.application_name : i
              ),
              tag: item?.tag?.map((i) => (i?.tag_name ? i?.tag_name : i)),
            });
          });

          console.log("newDatanewData", newData, newSSHeaders);

          csvReport = {
            data: newData,

            headers: newSSHeaders,
            filename: "ss_call_list.csv",
          };
          setAllCalls(data?.data);
          setSuccCallsCount(data?.total_success_call);
          setFilterCallsCount(data?.filter_call_without_limit);
          setIsLoading(false);
          setAllRecordsLoader(false);
          setRetainCallsData(data?.data);
          data?.data?.length === 0 && setIsDataLength(false);
        } else if (data?.status >= 400 && data?.status <= 422) {
          if (data?.status === 401) {
            localStorage.clear();
          }

          setIsLoading(false);
          setAllRecordsLoader(false);
          return ErrorNotify(data.message, {
            icon: "error",
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setAllRecordsLoader(false);
        return ErrorNotify(
          "Something went wrong.Please contact to Administrator !",
          {
            icon: "error",
          }
        );
      });
    setHandleStartTime(moment().subtract(1, "days").format("YYYY-MM-DD"));
    setHandleEndTime(moment().format("YYYY-MM-DD"));

    await fetch(`${base_url}filter/agents-list`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.status == 200) {
          setIsLoading(false);
          setAllRecordsLoader(false);
          setAgents(data?.data);
        } else if (data?.status >= 400 && data?.status <= 422) {
          if (data?.status === 401) {
            localStorage.clear();
          }
          setIsLoading(false);
          setAllRecordsLoader(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        return ErrorNotify(
          "Something went wrong.Please contact to Administrator !",
          {
            icon: "error",
          }
        );
      });

    await fetch(`${base_url}tags/tag-folder`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.status == 200) {
          console.log("PPOPOPPOOPOO", data?.data);
          setIsLoading(false);
          setAllRecordsLoader(false);
          setTags(data?.data);
        } else if (data?.status >= 400 && data?.status <= 422) {
          if (data?.status === 401) {
            localStorage.clear();
          }
          setIsLoading(false);
          setAllRecordsLoader(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        return ErrorNotify(
          "Something went wrong.Please contact to Administrator !",
          {
            icon: "error",
          }
        );
      });

    const keysToSelect = [
      "call_id",
      "datetime",
      "agent_id",
      "duration",
      "folder_id",
      "tag",
      "applications",
      "created_at",
    ];
    newSSHeaders = keysToSelect?.map((item) => ({
      label: item,
      key: item,
    }));
    all_time === "all_time" && setFilteredByTime(true);
    setRenderFilter(whichFilter);
  }

  useEffect(() => {
    getCustomHierarchyData();
  }, []);
  useEffect(() => {
    if (
      localStorage.getItem("loginToken") === "" ||
      localStorage.getItem("loginToken") === null
    ) {
      localStorage.clear();
      navigate("/");
      // window.location.reload();
    }
  }, []);

  const getAgentSupervisors = (agentName) => {
    var url = `${base_url}calls/get-agents-super-visor`;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        agent_id: agentName,
        proxy_user_id:
          localStorage.getItem("settedProxy") !== null &&
          localStorage.getItem("settedProxy") !== undefined
            ? JSON.parse(localStorage.getItem("settedProxy"))?.value
            : "",
        proxy_by:
          localStorage.getItem("settedProxy") !== null &&
          localStorage.getItem("settedProxy") !== undefined
            ? localStorage.getItem("loginUserUniqueid")
            : "",
      }),
    };

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        // setAgentSupervisors(data.data.super_visior_name + ' (' + data.data.super_visior_id + ')');
        if (typeof data.data.supervisiorname === undefined) {
          setAgentSupervisors("NA");
        } else {
          setAgentSupervisors(data.data.supervisiorname);
        }
        setPopupLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });

    // console.log('-------------------------------',agentSupervisorName);
  };

  const getCustomHierarchyData = async () => {
    var url = `${base_url}hierarchy/get-custom-hierarchy`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("hierarchy data======", data);
        if (isNaN(data.status)) {
          // Logout();
          return;
        }
        var arr = [];
        if (data?.status == 200) {
          data?.data?.map((i, j) => {
            arr[i.custom1_id] = i.custom1_name;
            arr[i.custom2_id] = i.custom2_name;
            arr[i.custom3_id] = i.custom3_name;
            arr[i.custom4_id] = i.custom4_name;
          });
          setHierarchyData(arr);
        } else if (data?.status >= 400 && data?.status <= 422) {
          if (data?.status === 401) {
            localStorage.clear();
          }
          return ErrorNotify(data.message, { icon: "error" });
        }
      })
      .catch((err) => {
        return ErrorNotify(
          "Something went wrong.Please contact to Administrator !",
          {
            icon: "error",
          }
        );
      });

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    var url = `${base_url}custom/1`;
    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setC1Data(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleC2Change = (e) => {
    setdefaultValueC1("");
    setdefaultValueC2("");
    setdefaultValueC3("");
    setdefaultValueC4("");
    setFormName("");
    setMediaValue("");
    setFile({});
    setAssignValue("");

    var url = `${base_url}custom/2`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        custom1: e.value,
      }),
    };

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("c2 data======", data);
        if (data.status != 200) {
          alert(data.message);
          return;
        }
        setdefaultValueC1(e.value);
        {
          console.log("c1Datac1Data", defaultValueC1);
        }
        // props.getC1Value(e);
        setC2Data(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleC3Change = (e) => {
    setdefaultValueC2("");
    setdefaultValueC3("");
    setdefaultValueC4("");
    setFormName("");
    setMediaValue("");
    setFile({});
    setAssignValue("");

    var url = `${base_url}custom/3`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        custom1: defaultValueC1,
        custom2: e.value,
      }),
    };

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        // console.log('c3 data======', data);
        if (data.status != 200) {
          alert(data.message);
          return;
        }
        setdefaultValueC2(e.value);
        // props.getC2Value(e);
        setC3Data(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleC4Change = (e) => {
    setdefaultValueC3("");
    setdefaultValueC4("");
    setFormName("");
    setMediaValue("");
    setFile({});
    setAssignValue("");

    var url = `${base_url}custom/4`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        custom1: defaultValueC1,
        custom2: defaultValueC2,
        custom3: e.value,
      }),
    };

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        // console.log('c3 data======', data);
        if (data.status != 200) {
          alert(data.message);
          return;
        }
        setdefaultValueC3(e.value);
        // props.getC3Value(e);
        setC4Data(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleC5Change = (e) => {
    if (e !== "") {
      var url = `${base_url}calls/get-form-name`;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          c1: defaultValueC1,
          c2: defaultValueC2,
          c3: defaultValueC3,
          c4: e.value,
          proxy_user_id:
            localStorage.getItem("settedProxy") !== null &&
            localStorage.getItem("settedProxy") !== undefined
              ? JSON.parse(localStorage.getItem("settedProxy"))?.value
              : "",
          proxy_by:
            localStorage.getItem("settedProxy") !== null &&
            localStorage.getItem("settedProxy") !== undefined
              ? localStorage.getItem("loginUserUniqueid")
              : "",
        }),
      };

      fetch(url, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          console.log("form data======", data);
          // if (data.status != 200) {
          //     ErrorNotify(data.message);
          //     return;
          // }
          // setdefaultValueC3(e);
          // setFormData(prevState => [
          //     ...prevState,
          //     data.data
          // ]);
          setFormData(data.data);
          setdefaultValueC4(e.value);
          // console.log('-------------', data.data)
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    getAllCalls();
  }, [folderListData]);
  const allAgents =
    agents &&
    agents?.map((i) => ({
      value: `${i.agent_id}`,
      label: `${i.agent_name}`,
    }));

  const showAgentsForUsers =
    storeAllAgents &&
    storeAllAgents?.map((i) => ({
      value: `${i?.value}`,
      label: `${i?.label}`,
    }));
  const showTagsForUsers =
    storeAllTags &&
    storeAllTags?.map((i) => ({
      value: `${i?.value}`,
      label: `${i?.label}`,
    }));
  console.log("showAgentsForUsers", storeAllAgents);
  console.log("tagsssss", tags);

  // const allTagsData = tags && tags?.map((i) => ({
  //     value: `${i.tag_id}`,
  //     label: `${i.tag_name}`
  // }));

  const allTagsData =
    folderWiseTags &&
    folderWiseTags?.map((i) => ({
      value: `${i.tag_id}`,
      label: `${i.tag_name}`,
    }));

  const calldurationFunc = (e) => {
    console.log("CallInfooooooo1", e);
    setCallDuration(e);
  };
  const agentNameFunc = (e) => {
    console.log("CallInfooooooo2", e);
    setAgentname(e);
  };
  const callDateFunc = (e) => {
    console.log("CallInfooooooo3", e);
    setCallDateTime(e);
  };
  const callIdFunc = (e) => {
    console.log("CallInfooooooo4", e);
    setcallId(e);
  };

  async function getDetailsForCalls(id, type) {
    setAddNamePhrase("");
    setIsLoading(true);
    setIsLoading2(true);

    if (type === "clickOnCall") {
      setClickOnCallLoader(true);
    }
    if (type === "clickOnAddCall") {
      setPopupLoader(true);
    }

    // const callDetails = await fetch(`${base_url}callspeech/calldetailss/${id}`, {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
    //     },
    // });

    await fetch(
      `${base_url}callspeech/${
        window.location.host?.split(".")[0] === "recruitment"
          ? "calldetails-rec"
          : "calldetails"
      }/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.status == 200) {
          if (type === "clickOnCall") {
            setIsActive(false);
            const overviewTabLink = document.getElementById("overview-tab");
            overviewTabLink.click();
            const fileViewLink = document.getElementById("file-tab");
            fileViewLink.click();
            setCallInfo(data?.data);
            setClickOnCallLoader(false);
            setAppScoreData(data?.data[0]?.tag);
          }

          if (type === "clickOnAddCall") {
            console.log("222222222222222", data?.data);
            setIsActive(false);
            const overviewTabLink = document.getElementById("overview-tab");
            overviewTabLink.click();
            const fileViewLink = document.getElementById("file-tab");
            fileViewLink.click();
            setAddCallData(data?.data);
            setPopupLoader(false);
            // setCallInfo(callDetailsData?.data)
          }
          setAgentSupervisors(data?.data[0]?.supervisorname);
          setCallDuration(data?.data[0]?.duration);
          setAgentname(data?.data[0]?.agent_name);
          setCallDateTime(
            moment(data?.data[0]?.datetime).format("YYYY-MM-DD HH:mm:ss")
          );
          setcallId(data?.data[0]?.call_id);
        } else if (data?.status >= 400 && data?.status <= 422) {
          if (data?.status === 401) {
            localStorage.clear();
          }
          setPopupLoader(false);
        }
      })
      .catch((err) => {
        setPopupLoader(false);
        return ErrorNotify(
          "Something went wrong.Please contact to Administrator !",
          {
            icon: "error",
          }
        );
      });

    // const callDetailsData = await callDetails.json();
    // console.log("callDetails",callDetails);

    // if (type === "clickOnCall") {
    //     setIsActive(false)
    //     const overviewTabLink = document.getElementById("overview-tab");
    //     overviewTabLink.click();
    //     const fileViewLink = document.getElementById("file-tab");
    //     fileViewLink.click();
    //     setCallInfo(callDetailsData?.data)
    //     setClickOnCallLoader(false)
    // }

    // if (type === "clickOnAddCall") {
    //     console.log("222222222222222");
    //     setIsActive(false)
    //     const overviewTabLink = document.getElementById("overview-tab");
    //     overviewTabLink.click();
    //     const fileViewLink = document.getElementById("file-tab");
    //     fileViewLink.click();
    //     setAddCallData(callDetailsData?.data)
    //     setPopupLoader(false)
    //     // setCallInfo(callDetailsData?.data)
    // }

    // { getAgentSupervisors(callDetailsData?.data[0]?.agentid) }
    // setTimeout(() => {
    //     {callIdFunc(callInfo[0]?.call_id)}

    //     {callDateFunc(moment(callInfo[0]?.datetime).format("YYYY-MM-DD HH:mm:ss"))}
    //     {agentNameFunc(callInfo[0]?.agentid)}
    //     {calldurationFunc(callInfo[0]?.duration)}
    // }, 1000);

    // setPopupLoader(false)
    // setWavFileInfo(wavFileDetailsData?.data)
    // setTagsInfo(tagsDetailsData?.data)
    setIsLoading(false);
    setIsLoading2(false);
  }
  const getOtherEvaluators = () => {
    var url = `${base_url}calls/get-other-evaluators`;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        userId: localStorage.getItem("loginUserId"),
        proxy_user_id:
          localStorage.getItem("settedProxy") !== null &&
          localStorage.getItem("settedProxy") !== undefined
            ? JSON.parse(localStorage.getItem("settedProxy"))?.value
            : "",
        proxy_by:
          localStorage.getItem("settedProxy") !== null &&
          localStorage.getItem("settedProxy") !== undefined
            ? localStorage.getItem("loginUserUniqueid")
            : "",
      }),
    };

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("setOtherEvaluatorsData data======", data);
        if (data.status != 200) {
          SuccessNotify(data.message);
          return;
        }
        setOtherEvaluatorsData(data.data);
        // setTimeout(() => {
        //     console.log('========', otherEvaluatorsData)
        // }, 1200);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const hideEvaluateBtn = (e) => {
    console.log("erererere", e);
    setAssignValue(e);

    if (e === "none" || e === "other") {
      setShowEvaluateBtn(false);
      setAllClasses("col-sm-6 col-md-4 col-lg-6 form-group d-none");
    } else {
      setShowEvaluateBtn(true);
      setAllClasses("col-sm-6 col-md-4 col-lg-6 form-group d-none");
    }
    if (e === "other") {
      setAllClasses("col-sm-6 col-md-4 col-lg-6 form-group");
      getOtherEvaluators();
    }

    if (e === "none") {
      setEvaluationStatus("Unassigned");
      setEvaluatorName("");
    } else if (e === "myself" || e === "other") {
      setEvaluationStatus("Pending");
    }
    if (e === "myself") {
      setEvaluatorName(localStorage.getItem("loginUserUniqueid"));
    }
  };
  const today = moment().startOf("day").format("YYYY-MM-DD");
  const handleFilter = async (e, filterType = "") => {
    setIsLoading(true);
    setAllRecordsLoader(true);
    let startDate, endDate;
    console.log("e.value", e.value);
    const today = moment().startOf("day").format("YYYY-MM-DD");
    if (filterType === "time_filter") {
      if (e.value === "lastMonth") {
        startDate = moment(today)
          .subtract(30, "days")
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = today;
      }
      if (e.value === "Yesterday") {
        startDate = moment(today).subtract(2, "days").format("YYYY-MM-DD");
        endDate = today;
      }
      if (e.value === "weekToDate") {
        startDate = moment(today).subtract(7, "days").format("YYYY-MM-DD");
        endDate = today;
      }
      if (e.value === "quarterToDate") {
        startDate = moment(today).subtract(90, "days").format("YYYY-MM-DD");
        endDate = today;
        //setAllCalls(retainCallsData?.filter(i => moment(i?.datetime).format("YYYY-MM-DD") > startDate && moment(i?.datetime).format("YYYY-MM-DD") < endDate))
      }
      if (e.value === "custom") {
        startDate = moment(customFromDate).format("YYYY-MM-DD");
        endDate = moment(customToDate).format("YYYY-MM-DD");
      }
      if (e.value === "all_time") {
        startDate = moment().clone().startOf("month").format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
        //getAllCalls("all_time")
        //return;
        //startDate=moment().clone().startOf('month').format('YYYY-MM-DD')
        //endDate=moment().format('YYYY-MM-DD')
      }
      setFromDate(startDate);
      setToDate(endDate);

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          lob: "",
          campaign: "",
          vendor: "",
          location: "",
          gender: "",
          gender_type: "",
          emotion: "",
          emotion_type: "",
          from_date: startDate,
          to_date: endDate,
          terms: [],
          silent_time: "",
          duration: toDuration !== "" ? toDuration : "",
          agent_id:
            storeAllAgents?.length > 0
              ? storeAllAgents?.map((i) => i?.value)
              : [],
          start_duration: fromDuration !== "" ? fromDuration : "",
          call_id: "",
          operator: operator,
          application:
            storeAllTags?.length > 0 ? storeAllTags?.map((i) => i?.value) : [],
          folder_id:
            folderID === ""
              ? [folderValue[0]?.value.toString()]
              : [folderID?.toString()],
          tag: [],
        }),
      };
      await fetch(
        `${base_url}callspeech/${
          window.location.host?.split(".")[0] === "recruitment"
            ? "recruit-newfilter"
            : "newfilter"
        } `,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          console.log("data?.status===200", data?.status);
          if (data?.status === 200) {
            console.log("DDDDDDDD", data?.data);
            setIsLoading(false);
            setAllRecordsLoader(false);
            setAllCalls(data?.data);
            //SuccessNotify("Tags Added Successfully");
            setFilteredByTime(true);
            return;
          } else {
            ErrorNotify(data?.message);
            return;
          }
        });
    }
    if (filterType === "agent_filter") {
      console.log("IIIIIIIIIIIII", e);
      if (e?.length === 0) {
        // setWhichFilter((prevValues) => prevValues.filter((value) => value !== "filter_by_agent"));
        setAllCalls(retainCallsData);
        setIsLoading(false);
        setAllRecordsLoader(false);
      } else {
        // setAllCalls(
        //     retainCallsData?.filter((item) => {
        //         return e.some((agent) => agent.value === item.agentid);
        //     })
        // );
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("loginToken"),
          },
          body: JSON.stringify({
            lob: "",
            campaign: "",
            vendor: "",
            location: "",
            gender: "",
            gender_type: "",
            emotion: "",
            emotion_type: "",
            //from_date: "01-01-2020",
            from_date:
              from_date !== ""
                ? from_date
                : moment().clone().startOf("month").format("YYYY-MM-DD"),
            to_date: to_date !== "" ? to_date : moment().format("YYYY-MM-DD"),
            terms: [],
            silent_time: "",
            duration: toDuration !== "" ? toDuration : "",
            start_duration: fromDuration !== "" ? fromDuration : "",
            agent_id: e?.map((i) => i?.value),
            call_id: "",
            operator: operator,
            application:
              storeAllTags?.length > 0
                ? storeAllTags?.map((i) => i?.value)
                : [],
            folder_id:
              folderID === ""
                ? [folderValue[0]?.value.toString()]
                : [folderID?.toString()],
            tag: [],
          }),
        };
        await fetch(
          `${base_url}callspeech/${
            window.location.host?.split(".")[0] === "recruitment"
              ? "recruit-newfilter"
              : "newfilter"
          } `,
          requestOptions
        )
          .then((res) => res.json())
          .then((data) => {
            console.log("data?.status===200", data?.status);
            if (data?.status === 200) {
              console.log("DDDDDDDD", data?.data);
              setAllCalls(data?.data);
              setIsLoading(false);
              setAllRecordsLoader(false);
              // if (!whichFilter.includes("filter_by_agent")) {
              //     setWhichFilter((prevValues) => [...prevValues, "filter_by_agent"]);
              // }

              //SuccessNotify("Tags Added Successfully");
              return;
            } else {
              ErrorNotify(data?.message);
              return;
            }
            console.log("DATATAT435", data);
          });
      }
    }
    if (filterType === "tags_filter") {
      if (e?.length === 0) {
        setWhichFilter((prevValues) =>
          prevValues.filter((value) => value !== "filter_by_tags")
        );
        setFilteredByTags(false);
        setAllCalls(retainCallsData);
        setIsLoading(false);
        setAllRecordsLoader(false);
      } else {
        // setAllCalls(
        //     retainCallsData?.filter((item) => {
        //         return e.some((tag) => tag.value === item.tag);
        //     })
        // );
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("loginToken"),
          },
          body: JSON.stringify({
            lob: "",
            campaign: "",
            vendor: "",
            location: "",
            gender: "",
            gender_type: "",
            emotion: "",
            emotion_type: "",
            // from_date: "01-01-2020",
            from_date: from_date,
            to_date: to_date,
            terms: [],
            silent_time: "",
            duration: toDuration !== "" ? toDuration : "",
            agent_id:
              storeAllAgents?.length > 0
                ? storeAllAgents?.map((i) => i?.value)
                : [],
            start_duration: fromDuration !== "" ? fromDuration : "",
            call_id: "",
            operator: operator,
            application: e?.map((i) => i?.value),
            folder_id:
              folderID === ""
                ? [folderValue[0]?.value.toString()]
                : [folderID?.toString()],
            tag: [],
          }),
        };
        await fetch(
          `${base_url}callspeech/${
            window.location.host?.split(".")[0] === "recruitment"
              ? "recruit-newfilter"
              : "newfilter"
          } `,
          requestOptions
        )
          .then((res) => res.json())
          .then((data) => {
            console.log("data?.status===200", data?.status);
            if (data?.status === 200) {
              console.log("DDDDDDDD", data?.data);
              setAllCalls(data?.data);
              setIsLoading(false);
              setAllRecordsLoader(false);
              if (!whichFilter.includes("filter_by_tags")) {
                setWhichFilter((prevValues) => [
                  ...prevValues,
                  "filter_by_tags",
                ]);
              }
              return;
            } else {
              ErrorNotify(data?.message);
              return;
            }
            console.log("DATATAT435", data);
          });
      }
    }
    // setStartDate(startDate);
    // setEndDate(endDate)
  };

  const onStartDateChange = (date) => {
    // setStartDate(date)
    // setCustomFromDate(date)
    setHandleStartTime(date);
  };

  const onEndDateChange = (date) => {
    // setEndDate(date)
    // setCustomToDate(date)
    setHandleEndTime(date);
  };

  // const handleCallDurationFilter = () => {
  //     retainCallsData?.map(i=>abc.push(i?.duration))
  //     console.log("fromDuration",fromDuration);
  //     const timeStringsInSeconds = abc.map(timeString => {
  //         const [hours, minutes, seconds] = timeString.split(":");
  //         return parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
  //       });
  //       const filteredData = timeStringsInSeconds.filter(duration => duration >= fromDuration && duration <= toDuration);

  //       const convertedTimes = filteredData.map(seconds => {
  //         const hours = Math.floor(seconds / 3600);
  //         const remainingSeconds = seconds % 3600;
  //         const minutes = Math.floor(remainingSeconds / 60);
  //         const secondsPart = remainingSeconds % 60;
  //         const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secondsPart).padStart(2, '0')}`;
  //         return formattedTime;

  //       });
  //      setAllCalls(
  //         retainCallsData?.filter((item) => {
  //             return convertedTimes.some((i) => i === item.duration);
  //         })
  //     );

  //     console.log("convertedTimes",convertedTimes);
  // }
  const handleCallDurationFilter = async () => {
    setIsLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        lob: "",
        campaign: "",
        vendor: "",
        location: "",
        gender: "",
        gender_type: "",
        emotion: "",
        emotion_type: "",
        // from_date: "01-01-2020",
        from_date: from_date,
        to_date: to_date,
        terms: [],
        silent_time: "",
        duration: toDuration !== "" ? toDuration : "",
        start_duration: fromDuration !== "" ? fromDuration : "",
        agent_id:
          storeAllAgents?.length > 0
            ? storeAllAgents?.map((i) => i?.value)
            : [],
        call_id: "",
        operator: operator,
        application:
          storeAllTags?.length > 0 ? storeAllTags?.map((i) => i?.value) : [],
        folder_id:
          folderID === ""
            ? [folderValue[0]?.value.toString()]
            : [folderID?.toString()],
        tag: [],
      }),
    };
    await fetch(
      `${base_url}callspeech/${
        window.location.host?.split(".")[0] === "recruitment"
          ? "recruit-newfilter"
          : "newfilter"
      } `,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        console.log("data?.status===200", data?.status);
        if (data?.status === 200) {
          console.log("DDDDDDDD", data?.data);
          setAllCalls(data?.data);
          setIsLoading(false);
          setFilteredByDuration(true);
          //SuccessNotify("Tags Added Successfully");
          return;
        } else {
          ErrorNotify(data?.message);
          return;
        }
        console.log("DATATAT435", data);
      });
  };

  const addCallTags = async () => {
    if (conversationId === "") {
      ErrorNotify("Please select a call");
      return;
    }
    const Tags = callTags.split(",").map(function (item) {
      return item.trim();
    });
    let alltags = [...Tags, ...allCallTags];

    var url = `${base_url}folder/add-tag`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        call_id: `${conversationId}`,
        tag: alltags,
      }),
    };
    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("data?.status===200", data?.status);
        if (data?.status === 200) {
          SuccessNotify("Tags Added Successfully");
          return;
        } else {
          ErrorNotify(data?.message);
          return;
        }
        console.log("DATATAT435", data);
      });

    //callApi(url, requestOptions, 'add-calltag');
    setCallTags("");
  };

  const highLightChatBox = (startTime, id) => {
    if (last_id !== 0 || last_id == "") {
      $("#chat_box_" + last_id).css("border", "");
    }

    setCurrentTime(startTime);
    last_id = id;
    // changeHighLight();
  };
  const onTimeUpdate = (event) => {
    setCurrentTime(event.target.currentTime);
  };
  const timeUpdate = (start_time, end_time, len, index) => {
    console.log("start_time", start_time);
    $("#sf2")[0].currentTime = start_time;
    $("#sf2")[0].play();
    // setIsClicked(true);

    // changeHighLight(end_time, len, index);
    // $('#sf2')[0].buffered.start(start_time)
    // $('#sf2')[0].buffered.end(end_time)
    // const minutes = Math.floor(event.toString().split(/[.\-_]/)[0] / 60);
    // const seconds = Math.floor(event.toString().split(/[.\-_]/)[1] - minutes * 60);
    // const currentTime = str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);
    // setCurrentTime(currentTime);
  };

  const showEvaluateForm = () => {
    setIsLoadingEvaluate(true);
    // Simulate a loading process
    setTimeout(() => {
      // Your loading logic here
      setIsLoadingEvaluate(false); // Once loading is done
    }, 3000); // Simulate a 2-second loading time

    handleAddEvaluation(1);
  };
  const handleAddEvaluation = (isEva = 0) => {
    const data = new FormData();

    if (
      defaultValueC1 === "" ||
      defaultValueC1.length === 0 ||
      defaultValueC1 === "Select"
    ) {
      ErrorNotify(`Please select ${hierarchyData.c1} value`);
      return;
    }
    if (
      defaultValueC2 === "" ||
      defaultValueC2.length === 0 ||
      defaultValueC2 === "Select"
    ) {
      ErrorNotify(`Please select ${hierarchyData.c2} value`);
      return;
    }
    if (
      defaultValueC3 === "" ||
      defaultValueC3.length === 0 ||
      defaultValueC3 === "Select"
    ) {
      ErrorNotify(`Please select ${hierarchyData.c3} value`);
      return;
    }
    if (
      defaultValueC4 === "" ||
      defaultValueC4.length === 0 ||
      defaultValueC4 === "Select"
    ) {
      ErrorNotify(`Please select ${hierarchyData.c4} value`);
      return;
    }
    if (formName === "") {
      ErrorNotify("Please select Form Name");
      return;
    }
    if (assignValue === "") {
      ErrorNotify("Please select Assign to ");
      return;
    }
    if (evaluationStatus === "") {
      ErrorNotify("Please select Evaluation status");
      return;
    }

    // if (mediaValue === '') {
    //     ErrorNotify('Please select mediaValue ');
    //     return;
    // }

    // data.append('file', file);
    if (!addCallData[0]?.agent_id) {
      return ErrorNotify("Agent id is required");
    }
    data.append(
      "agent",
      addCallData[0]?.agent_id ? addCallData[0]?.agent_id : ""
    );
    console.log("formnameformnameAgeb=nt", agentName);
    data.append("c1", defaultValueC1);
    data.append("c2", defaultValueC2);
    data.append("c3", defaultValueC3);
    data.append("c4", defaultValueC4);
    data.append("userId", localStorage.getItem("loginUserId"));
    data.append("call_id", callId ? callId : "");

    data.append("call_date", callDateTime ? callDateTime : "");
    console.log("formnameformname", callDateTime);
    data.append("call_duration", callDuration ? callDuration : "");
    console.log("formnameformname", formName);
    data.append("form_name", String(formName).split("||")[0]);
    data.append("form_version", String(formName).split("||")[1]);
    data.append("channel", "channel");
    data.append("affiliation", "mattsenkumar");
    data.append("evaluation_status", evaluationStatus);
    data.append("evaluator", evaluatorName);
    data.append(
      "proxy_user_id",
      localStorage.getItem("settedProxy") !== null &&
        localStorage.getItem("settedProxy") !== undefined
        ? JSON.parse(localStorage.getItem("settedProxy"))?.value
        : ""
    );
    data.append(
      "proxy_by",
      localStorage.getItem("settedProxy") !== null &&
        localStorage.getItem("settedProxy") !== undefined
        ? localStorage.getItem("loginUserUniqueid")
        : ""
    );

    // data.append('media', mediaValue);
    var url = `${base_url}calls/add`;

    const requestOptions = {
      method: "POST",
      headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded',
        // 'Content-Type': 'application/json',
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },

      body: data,
    };
    // console.log('evaluation data is here=========', requestOptions)
    // return
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("evaluation data is======", data);

        if (data.status === 401) {
          ErrorNotify(data.message);
          setIsDataFound(false);
          // handleClose();
          return;
        }
        if (data.status === 422) {
          ErrorNotify(data.message);
          setIsDataFound(false);
          // handleClose();
          return;
        }
        if (data.status === 200) {
          console.log("evalutehandling");
          if (isEva) {
            setIsDataFound(false);
            handleClose();
            console.log("evalutehandling2222");
            window.location.href = `${base_name}/new-form-preview?formName=${data.data.form_name}&formVersion=${data.data.form_version}&moduleName=evaluation&authSecondId&resolvedSecId&call_id=${data.data.call.call_id}`;
          } else {
            setIsDataFound(true);
            console.log("evalutehandling333");
            SuccessNotify(data.message);

            setTimeout(() => {
              setIsDataFound(false);
              handleClose();

              window.location.reload(false);

              return;
            }, 3000);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleMediaValue = (selectedOption) => {
    setMediaValue(selectedOption.value);

    if (selectedOption.value === "no") {
      setFile("");
      setFileName(false);
      console.log("mediaValuev1no", file);
    }
    if (selectedOption.value === "yes") {
      setFileName(true);
      if (fileInputRef.current) {
        // Reset the file input by creating a new one
        const newFileInput = document.createElement("input");
        newFileInput.type = "file";
        newFileInput.accept = "audio/wav";
        newFileInput.name = "shadow";
        newFileInput.required = true;
        newFileInput.addEventListener("change", (e) => {
          setFile(e.target.files[0]);
          setFileName(true);
        });

        // Replace the old file input with the new one
        fileInputRef.current.parentNode.replaceChild(
          newFileInput,
          fileInputRef.current
        );

        // Update the file input reference to the new input
        fileInputRef.current = newFileInput;
        console.log("mediaValuev1yes", file);
      }
    }
    console.log("mediaValuev2over", file);
  };
  const handleClose = () => {
    document.querySelector("#addCallModal").style.display = "none";
    console.log("deletion start");
    console.log("deletion callDuration", callDuration);
    console.log("deletion agentName", agentName);
    console.log("deletion callDateTime", callDateTime);
    console.log("deletion callId", callId);
    setdefaultValueC1("");
    setdefaultValueC2("");
    setdefaultValueC3("");
    setdefaultValueC4("");
    setFormName("");
    setMediaValue("");
    setFile({});
    setAssignValue("");
    setAgentname("");
    setcallId("");
    setCallDateTime("");
    setCallDuration("");
    setEvaluatorName("");
    setEvaluationStatus("");
    setAgentSupervisors("");
    console.log("deletion callDuration", callDuration);
    console.log("deletion agentName", agentName);
    console.log("deletion callDateTime", callDateTime);
    console.log("deletion callId", callId);

    console.log("deletion end");
    // setTimeout(() => {
    //     window.location.reload();
    // }, 3000);
  };
  // const getAllCallsReset = () => {
  //     setTimeout(() => {
  //         getAllCalls();
  //     }, 10000);
  // }

  const optionsC1 = c1Data?.map((item) => ({
    value: item,
    label: item,
  }));
  console.log("optionsC1Z", optionsC1);
  const optionsC2 = c2Data?.map((item) => ({
    value: item,
    label: item,
  }));
  const optionsC3 = c3Data?.map((item) => ({
    value: item,
    label: item,
  }));

  const optionsC4 = c4Data?.map((item) => ({
    value: item,
    label: item,
  }));
  const optionAssign = [
    { value: "", label: "Select" },
    { value: "none", label: "None" },
    { value: "myself", label: "Self" },
    { value: "other", label: "Other" },
  ];
  const optionsotherEvaluatorsData = otherEvaluatorsData.map((i) => ({
    value: `${i.agent_id}`,
    label: `${i.agent_name_id}`,
  }));
  const optionMedia = [
    { value: "", label: "Select" },
    { value: "no", label: "No" },
    { value: "yes", label: "Yes" },
  ];
  const optionsFormData = formData?.map((i) => ({
    value: `${i.form_name}||${i.form_version}`,
    label: `${i.display_name}(V${i.form_version}.0)`,
  }));
  const downloadTxtFile = (type = "", data) => {
    if (type === "text") {
      const textContent = data[0]
        .map((line) => line.type + ":" + line.text + "\n" + "\n")
        .join("");
      fileDownload(textContent, `${callId}.txt`);
    }
    if (type === "json") {
      const element = document.createElement("a");
      const file = new Blob([data], { type: "application/json" });
      element.href = URL.createObjectURL(file);
      element.download = `${callId}.json`;
      document.body.appendChild(element);
      element.click();
    }
  };
  const getJSONRes = async (id) => {
    const res = await fetch(`${base_url}callspeech/jsonresponse/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    });
    const data = await res.json();
    if (data?.status === 200) {
      downloadTxtFile("json", JSON.stringify(data.data, null, 2));
    } else {
      ErrorNotify(data?.message);
      return;
    }
  };

  const getTextRes = async (id) => {
    const res = await fetch(`${base_url}callspeech/textresponse/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    });
    const data = await res.json();
    if (data) {
      downloadTxtFile("text", data?.data);
    } else {
      ErrorNotify(data?.message);
      return;
    }
  };

  const getAppScoreData = async (cid = "") => {
    setAppScoreData([]);
    console.log("conversationId", conversationId);
    console.log("cid", cid);
    // if(conversationId===""){
    //     ErrorNotify("Please select a call");
    //         return;
    // }
    const res = await fetch(`${base_url}callspeech/application-score`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        id: `${cid}`,
      }),
    });

    const data = await res.json();
    if (data.status == 200) {
      if (data?.data?.length > 0) {
        const sortedData = [...data?.data].sort((a, b) =>
          a.app_name.localeCompare(b.app_name)
        );
        console.log("sortedData", sortedData);
        // setAppScoreData(sortedData)
        return;
      }
      // else {
      //     ErrorNotify("Applications Not Exists");
      //     return;
      // }
    } else {
      ErrorNotify(data.message);
      return;
    }
  };

  const exportPDF = async () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: [1000, 600],
    });

    const table = document.getElementById("ss-table");

    const headerStyles = {
      fontSize: 8, // Adjust the font size as needed

      // You can also make it bold
    };
    const bodyStyles = {
      fontSize: 8, // Adjust the font size for the body rows
    };

    doc.autoTable({
      html: "#ss-table",

      bodyStyles: bodyStyles,
      headStyles: headerStyles, // Apply the header styles
    });

    doc.save("ss_call_list.pdf");
  };

  const exportXLS = async () => {
    console.log("xlsReport", excelData);
    try {
      // Collect all unique keys from the excelData, excluding "meta_data"
      const uniqueKeys = new Set();
      excelData.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (key !== "meta_data") {
            uniqueKeys.add(key);
          }
        });
      });

      // Convert the set of unique keys to an array
      const keysArray = Array.from(uniqueKeys);

      // Create a new workbook and worksheet
      var wb = XLSX.utils.book_new();

      // Map the excelData to the format required for the worksheet
      var wsData = excelData.map((item) => {
        const rowData = {};
        keysArray.forEach((key) => {
          if (key === "tag" && Array.isArray(item[key])) {
            rowData[key] = item[key].map((i) => i);
          } else if (key === "applications" && Array.isArray(item[key])) {
            rowData[key] = item[key].map((i) => i);
          } else {
            rowData[key] = item[key];
          }
        });
        return rowData;
      });

      // Add headers as the first row
      wsData.unshift(
        keysArray.reduce((acc, key) => ({ ...acc, [key]: key }), {})
      );

      // Create the worksheet from the data
      var ws = XLSX.utils.json_to_sheet(wsData, { skipHeader: true });

      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "MySheet1");

      // Write the workbook to a file
      XLSX.writeFile(wb, "ss_call_list.xlsx");
    } catch (error) {
      console.error("Error exporting to XLSX:", error);
    }
  };

  useEffect(() => {
    if (
      (timeFilter === "Custom" &&
        moment(handleEndTime).diff(handleStartTime, "months", true) > 1) ||
      timeFilter === "Last 90 Days"
    ) {
      getCallsForExport("No Download");
    } else {
      getCallsForExport();
    }
  }, [handleStartTime, handleEndTime, folderListData]);

  async function getCallsForExport(one_month = "") {
    setExportData(false);

    one_month === "Download" && setOneMonthLoading(true);
    await fetch(
      `${base_url}callspeech/${
        window.location.host?.split(".")[0] === "recruitment"
          ? "recruit-newlistexport"
          : "newListExport"
      }`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
        body: JSON.stringify({
          from_date:
            timeFilter === "Last 90 Days" || one_month === "Download"
              ? moment().clone().startOf("month").format("YYYY-MM-DD")
              : one_month === "No Download"
              ? moment().clone().startOf("month").format("YYYY-MM-DD")
              : moment(handleStartTime).format("YYYY-MM-DD"),
          to_date:
            timeFilter === "Last 90 Days" || one_month === "Download"
              ? moment().format("YYYY-MM-DD")
              : one_month === "No Download"
              ? moment(handleEndTime).format("YYYY-MM-DD")
              : moment(handleEndTime).format("YYYY-MM-DD"),
          duration: toDuration !== "" ? toDuration : "",
          agent_id:
            storeAllAgents?.length > 0
              ? storeAllAgents?.map((i) => i?.value)
              : [],
          start_duration: fromDuration !== "" ? fromDuration : "",
          call_id: callIdFilter !== "" ? callIdFilter : "",
          operator: operator,
          application:
            storeAllTags?.length > 0 ? storeAllTags?.map((i) => i?.value) : [],
          tag: [],
          call_status: "5",
          // folder_id: [],
          folder_id:
            folderID === ""
              ? [folderValue[0]?.value.toString()]
              : [folderID?.toString()],
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.status == 200) {
          console.log("PPOPOPPOOPOO", data?.data);
          setAllExportData(data?.data);
          setMorethanOneMonthData(data?.data);
          setExportData(true);
          setExportDataCheck(true);

          if (data?.data?.length > 0) {
            // let getHeader = Object.keys(data?.data[0]?.meta_data).concat(Object.keys(data?.data[0]))?.filter(i => i !== "meta_data" && i !== "_id")
            // let getHeader = data?.data[0]?.folder_meta?.filter(i=>i!=="_id")
            let getHeader = Object.keys(data?.data[0])
              .concat(data?.folder_meta[0]?.metadata)
              ?.filter((i) => i !== "meta_data");

            newSSHeaders = getHeader?.map((item) => ({
              label: item,
              key: item,
            }));
          }

          let newData = [];
          data?.data?.map((item) => {
            newData.push({
              ...item,

              ...item.meta_data,
              folder_id: item.folder_id,
              applications: item?.applications?.map((i) =>
                i?.application_name ? i?.application_name : i
              ),
              tag: item?.tag?.map((i) => (i?.tag_name ? i?.tag_name : i)),
            });
          });

          setExcelData(newData);

          console.log("newDatanewData", newData, newSSHeaders);

          csvReport = {
            data: newData,

            headers: newSSHeaders,
            filename: "ss_call_list.csv",
          };

          setTimeout(() => {
            setOneMonthLoading(false);
            console.log("UHUHHUUHUHUUH", csvReport);
          }, 2000);
        } else if (data?.status >= 400 && data?.status <= 422) {
          if (data?.status === 401) {
            localStorage.clear();
          }
        }
      })
      .catch((err) => {
        setOneMonthLoading(false);
        return ErrorNotify(
          "Something went wrong.Please contact to Administrator !",
          {
            icon: "error",
          }
        );
      });
  }

  // csvReport = {
  //     data:
  //         allCalls?.map((item) => ({
  //             call_id: item?.call_id,
  //             datetime: item?.datetime,
  //             agent_id: item?.agent_id,
  //             duration: item?.duration,
  //             folder_id: item?.folder_id,
  //             // tag: Object.values(item?.tag || {})?.map(tag => tag?.tag_name),
  //             tag: item?.tag?.map(i => i?.tag_name),
  //             applications: item?.applications?.map(i => i?.application_name),
  //             created_at: item?.created_at

  //         })),
  //     headers: newSSHeaders,
  //     filename: 'ss_call_list.csv'
  // };

  const handleChange = (e) => {
    const { name, checked } = e.target;
    console.log("name123", name, checked);
    if (name === "allSelect") {
      let tempCalls = allCalls
        ?.filter((i) => i?.transcription_status === "success")
        ?.map((call) => {
          return { ...call, isChecked: checked };
        });
      setAllCalls(tempCalls);
    } else {
      let tempCalls = allCalls
        ?.filter((i) => i?.transcription_status === "success")
        ?.map((call) =>
          call.call_id === name ? { ...call, isChecked: checked } : call
        );
      console.log("tempCalls", tempCalls);
      setAllCalls(tempCalls);
    }
  };

  const handleAllFilter = async (load_data = false, isFilter = false) => {
    exportData ? setExportDataCheck(true) : setExportDataCheck(false);
    console.log("whichFilterwhichFilter", whichFilter);
    setAddNamePhrase("");
    storeAllAgents?.length === 0 &&
      setRenderFilter((prevValues) =>
        prevValues.filter((value) => value !== "filter_by_agent")
      );
    storeAllTags?.length === 0 &&
      setRenderFilter((prevValues) =>
        prevValues.filter((value) => value !== "filter_by_tags")
      );
    storeAllTags?.length === 0 && setFilterShowPhrase([]);
    setShowTimeFilter(timeFilter);
    setShowAgentFilter(storeAllAgents);
    setShowTagsFilter(storeAllTags);
    setShowFromDurationFilter(fromDuration === "" ? "00" : fromDuration);
    setShowToDurationFilter(toDuration === "" ? "00" : toDuration);
    setCallInfo([]);
    setAppScoreData([]);
    if (whichFilter?.length === 0 && load_data === false && isFilter === true) {
      console.log("LOLOLLLOLLOLOLO");
      setCallIdFilter("");
      setRenderFilter([]);
      setAllRecordsLoader(true);
      getAllCalls("all_time");
      setFilteredApplied(false);
      return;
      // return ErrorNotify("Please Select Atleast One Filter");
    }
    setIsLoading(true);
    setAllRecordsLoader(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        //lob: "",
        //campaign: "",
        //vendor: "",
        //location: "",
        //gender: "",
        //gender_type: "",
        //emotion: "",
        //emotion_type: "",
        from_date: moment(handleStartTime).format("YYYY-MM-DD"),
        to_date: moment(handleEndTime).format("YYYY-MM-DD"),
        //terms: [],
        //silent_time: "",
        duration: toDuration !== "" ? toDuration : "",
        agent_id:
          storeAllAgents?.length > 0
            ? storeAllAgents?.map((i) => i?.value)
            : [],
        start_duration: fromDuration !== "" ? fromDuration : "",
        call_id: callIdFilter !== "" ? callIdFilter : "",
        operator: operator,
        application: [],
        folder_id:
          folderID === ""
            ? [folderValue[0]?.value.toString()]
            : [folderID?.toString()],
        tag: storeAllTags?.length > 0 ? storeAllTags?.map((i) => i?.value) : [],
        call_status: "5",
        last_created_date: !load_data
          ? ""
          : new Date(allCalls[allCalls?.length - 1]?.created_at),
        callee_number:
          window.location.host?.split(".")[0] === "recruitment" && calleeFilter,
        location:
          window.location.host?.split(".")[0] === "recruitment" &&
          locationVal?.value,
      }),
    };
    await fetch(
      `${base_url}callspeech/${
        window.location.host?.split(".")[0] === "recruitment"
          ? "recruit-newfilter"
          : "newfilter"
      } `,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.status === 200) {
          // if (data?.data?.length > 0) {

          //     let getHeader = Object.keys(data?.data[0]?.meta_data).concat(Object.keys(data?.data[0]))?.filter(i => i !== "meta_data" && i !== "_id")

          //     newSSHeaders = getHeader?.map((item) => ({
          //         label: item,
          //         key: item
          //     }));
          // }

          // let newData = [];
          // data?.data?.map(item => {
          //     newData.push({
          //         ...item,

          //         ...item.meta_data,
          //         folder_id: item.folder_id,
          //         applications:item?.applications?.map(i=>i?.application_name),
          //         tag:item?.tag?.map(i=>i?.tag_name)

          //     });
          // });

          // console.log("newDatanewData", newData,newSSHeaders);

          // csvReport = {
          //     data: newData,

          //     headers: newSSHeaders,
          //     filename: 'ss_call_list.csv'
          // };

          if (data?.data?.length === 0) {
            setIsDataLength(false);
          }
          !load_data && setFilteredApplied(true);
          setIsDataLength(true);
          setIsLoading(false);
          setAllRecordsLoader(false);
          setSuccCallsCount(data?.total_success_call);
          isFilter
            ? setAllCalls(data?.data)
            : setAllCalls((prevAllCalls) => [...prevAllCalls, ...data?.data]);
          setFilterCallsCount(data?.filter_call_without_limit);
          setFilteredByTime(true);
          setRenderFilter(whichFilter);
          load_data && filteredApplied && setFilteredApplied(true);
          setCallIdFilterForDisplay(callIdFilter);
          setCalleeNoFilterForDisplay(calleeFilter);

          setFolderIdFilterForDisplay(
            folderListData?.filter((i) => i?.value === folderID)[0]?.label
          );
          setFilterShowPhrase(storeAllTags);
        } else if (data?.status >= 400 && data?.status <= 422) {
          setAllRecordsLoader(false);
          ErrorNotify(data?.message);
          return;
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setAllRecordsLoader(false);
        return ErrorNotify(
          "Something went wrong.Please contact to Administrator !",
          {
            icon: "error",
          }
        );
      });
  };

  const handleClickOnIcon = (e) => {
    e.stopPropagation();
    setTranscriptTabVisible(!transcriptTabVisible); // Toggle the visibility
  };

  // useEffect(() => {
  //     const handleTranscriptLinkClick = (e) => {
  //         e.preventDefault();
  //         const transcriptTabLink = document.getElementById("transcript-tab");
  //         if (transcriptTabLink) {
  //             transcriptTabLink.click();
  //         }
  //     };

  //     const transcriptLink = document.getElementById("transcript-link");

  //     if (transcriptLink) {
  //         // Remove the event listener if it exists
  //         transcriptLink.removeEventListener('click', handleTranscriptLinkClick);
  //         // Add the event listener
  //         transcriptLink.addEventListener('click', handleTranscriptLinkClick);
  //     }

  //     // Clean up the event listener when transCounting changes or when the component unmounts
  //     return () => {
  //         if (transcriptLink) {
  //             transcriptLink.removeEventListener('click', handleTranscriptLinkClick);
  //         }
  //     };
  // }, [transCounting]);

  const addClick = (app_name) => {
    console.log("storeAllAgents", storeAllTags);
    storeAllTags?.length === 0 && console.log("EEEEEEEEEE", app_name);
    setAddNamePhrase(app_name);

    const transcriptLinks = document.getElementsByClassName("transcript-link");
    const transcriptTabLink = document.getElementById("transcript-tab");

    if (transcriptTabLink) {
      // Loop through the collection of transcript links and attach the event listener to each one
      for (const link of transcriptLinks) {
        link.addEventListener("click", (e) => {
          e.preventDefault();

          transcriptTabLink.click();
        });
      }
    }

    // Clean up the event listeners when the component unmounts
    return () => {
      if (transcriptTabLink) {
        // Loop through the collection of transcript links and remove the event listener from each one
        for (const link of transcriptLinks) {
          link.removeEventListener("click", () => {
            transcriptTabLink.click();
          });
        }
      }
    };
  };

  const ValueContainer2 = ({ children, ...props }) => {
    const { getValue } = props;
    let selectedOption = getValue().length;

    if (folderValue && folderValue?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          <span
            style={{ fontSize: "12px" }}
          >{`${folderValue?.length} selected`}</span>
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const handleFolderChange = (e, event) => {
    // if (event?.option?.value == "All" && event?.action == "select-option") {
    //     setFolderValue(folderListData);
    //     console.log("11111111111", folderListData);
    //     setFilteredFolders && setFilteredFolders(folderListData);
    //     let allFolderValue = [];
    //     folderListData &&
    //         folderListData?.map((item) => {
    //             if (item?.value !== "All") {
    //                 allFolderValue.push(item?.value);
    //             }
    //         });
    //     setFolderID(allFolderValue);
    // } else if (
    //     event?.option?.value == "All" &&
    //     event?.action == "deselect-option"
    // ) {
    //     console.log("2222222222");
    //     setFilteredFolders && setFilteredFolders([]);
    //     setFolderValue([]);
    //     setFolderID([]);
    // } else {
    //     if (e.some((i) => i.value == "All")) {
    //         console.log("3333333333");
    //         let filterData = e.filter((item) => item?.value !== "All");

    //         setFolderValue(filterData);
    //         let filterDataValue = [];
    //         filterData?.map((item) => {
    //             filterDataValue.push(item.value);
    //         });
    //         setFolderID(filterDataValue);
    //     } else {
    //         if (folderListData?.length - 1 == e?.length) {
    //             console.log("444444444");
    //             setFolderValue(folderListData);

    //             setFolderID(["All"]);
    //         } else {
    //             console.log("55555555555");

    //             let folderData = [];
    //             e?.map((item) => {
    //                 folderData.push(item);
    //             });
    //             setFolderValue(folderData);
    //             setFilteredFolders && setFilteredFolders(folderData);
    //             let folderValue = [];
    //             e?.map((item) => {
    //                 folderValue.push(item.value);
    //             });
    //             setFolderID(folderValue);
    //         }
    //     }
    // }

    // for singal folder selection
    setFolderValue(e);
    console.log("eeeeeeeeeeeeeeee", e?.value);
    if (e?.value !== undefined && e?.value !== "") {
      var url2 = `${base_url}tags/tag-folder/${e.value}`;

      var requestOptions2 = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
      };
      callApi(url2, requestOptions2, "folder-wise-tag");
    }

    //for multiple folder selection

    // var url2 = `${base_url}tags/tag-folder/`;

    //                         var requestOptions2 = {
    //                             method: "POST",
    //                             headers: {
    //                                 "Content-Type": "application/json",
    //                                 Authorization: "Bearer " + localStorage.getItem("loginToken"),
    //                             },
    //                             body: JSON.stringify({
    //                                 //folder_id:e?.some(i=>i?.value==="All")?["All"]:e?.map(i => i?.value),
    //                                 folder_id:folderID

    //                             })
    //                         };
    //                         callApi(url2, requestOptions2, "folder-wise-tag");
  };

  return (
    <main>
      <ToastContainer />
      <table className="d-none" id="ss-table">
        <thead>
          <tr>
            <th>Call Id</th>
            <th>Datetime</th>
            <th>Agent Id</th>
            <th>Duration</th>
            <th>Folder Id</th>
            <th>Tags</th>
            <th>Applications</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {allCalls?.map((item, index) => (
            <tr key={index}>
              <td>{item?.call_id}</td>
              <td>{item?.datetime}</td>
              <td>{item?.agent_id}</td>
              <td>{item?.duration}</td>
              <td>{item?.folder_id}</td>
              <td>{item?.tag?.map((i) => i?.tag_name + ",")}</td>
              {/* <td>{Object.values(item?.tag || {}).map(tag => tag.tag_name).join(', ')}</td> */}
              <td>
                {item?.applications?.map((i) => i?.application_name + ",")}
              </td>
              <td>{moment(item?.created_at).format("YYYY-MM-DD")}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div
        className="modal fade bd-example-modal-sm"
        id="addCallModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="mySmallModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addCallModal">
                Add Call
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={handleClose}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {popupLoader ? (
              <div className="loader-container ">
                <div className="position-absolute top-0 start-0 w-100 h-100 d-flex p-5 justify-content-center bg-white align-items-center opacity-75">
                  <div
                    className="spinner-border text-black "
                    role="status"
                  ></div>
                </div>
              </div>
            ) : (
              <div>
                <div className="modal-body">
                  {allCalls
                    ?.filter((i) => i?._id === conversationId)
                    ?.map((j) => {
                      return (
                        <div
                          className="row no-gutters"
                          style={{ textAlign: "justify", lineHeight: "1px" }}
                        >
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <h5>
                              <span style={{ fontWeight: 600 }}>
                                Call Id :{" "}
                              </span>
                              {j?.call_id}{" "}
                            </h5>

                            <h5>
                              <span style={{ fontWeight: 600 }}>
                                Agent Name : &nbsp;{" "}
                              </span>
                              {addCallData[0]?.agent_name
                                ? addCallData[0]?.agent_name
                                : "NA"}
                              &nbsp;
                            </h5>

                            <h5>
                              <span style={{ fontWeight: 600 }}>
                                Agent Id : &nbsp;{" "}
                              </span>
                              {addCallData[0]?.agent_id
                                ? addCallData[0]?.agent_id
                                : "NA"}
                              &nbsp;
                            </h5>

                            <h5>
                              <span style={{ fontWeight: 600 }}>
                                Supervisor Name :
                              </span>{" "}
                              &nbsp;
                              {addCallData[0]?.supervisorname
                                ? addCallData[0]?.supervisorname
                                : "NA"}{" "}
                              &nbsp;{" "}
                            </h5>
                            <h5>
                              <span style={{ fontWeight: 600 }}>
                                Supervisor Id :
                              </span>{" "}
                              &nbsp;
                              {addCallData[0]?.supervisorid
                                ? addCallData[0]?.supervisorid
                                : "NA"}{" "}
                              &nbsp;{" "}
                            </h5>

                            <h5>
                              <span style={{ fontWeight: 600 }}>
                                Call Date & Time :
                              </span>
                              &nbsp;
                              {moment(addCallData[0]?.datetime).format(
                                "YYYY-MM-DD HH:mm:ss a"
                              )}{" "}
                              &nbsp;{" "}
                            </h5>

                            <h5>
                              <span style={{ fontWeight: 600 }}>
                                Call Duration :
                              </span>
                              &nbsp;{addCallData[0]?.duration}{" "}
                            </h5>
                          </div>
                        </div>
                      );
                    })}
                  <div className="mt-4">
                    <div className="row">
                      <div className="col-sm-6 col-md-3 col-lg-6 rounded form-group">
                        <label>
                          <span>{hierarchyData.c1}</span>
                          <span className="mandatory">*</span>
                        </label>
                        <Select
                          options={optionsC1}
                          value={
                            defaultValueC1 !== ""
                              ? optionsC1.find(
                                  (option) => option.value === defaultValueC1
                                )
                              : ""
                          }
                          isSearchable={true}
                          required={true}
                          onChange={(e) => handleC2Change(e)}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px",
                              boxShadow: "none", // Remove the blue box shadow
                              borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                              "&:hover": {
                                borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                              },
                              "&:focus": {
                                outline: "none", // Remove the blue outline on focus
                                borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                boxShadow: "none", // Remove the blue box shadow on focus
                              },
                              "&:active": {
                                borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                              },
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              background: state.isSelected
                                ? "#fff"
                                : "transparent", // Remove background highlight from non-selected options
                              color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                            }),
                          }}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-6 rounded form-group">
                        <label>
                          <span>{hierarchyData.c2}</span>
                          <span className="mandatory">*</span>
                        </label>
                        <Select
                          options={optionsC2}
                          id="optionC2Id"
                          value={
                            defaultValueC2 !== ""
                              ? optionsC2.find(
                                  (option) => option.value === defaultValueC2
                                )
                              : ""
                          }
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px",
                              boxShadow: "none", // Remove the blue box shadow
                              borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                              "&:hover": {
                                borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                              },
                              "&:focus": {
                                outline: "none", // Remove the blue outline on focus
                                borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                boxShadow: "none", // Remove the blue box shadow on focus
                              },
                              "&:active": {
                                borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                              },
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              background: state.isSelected
                                ? "#fff"
                                : "transparent", // Remove background highlight from non-selected options
                              color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                            }),
                          }}
                          isSearchable={true}
                          required={true}
                          onChange={(e) => handleC3Change(e)}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-6 rounded form-group">
                        <label>
                          <span>{hierarchyData.c3}</span>
                          <span className="mandatory ">*</span>
                        </label>
                        <Select
                          options={optionsC3}
                          value={
                            defaultValueC3 !== ""
                              ? optionsC3.find(
                                  (option) => option.value === defaultValueC3
                                )
                              : ""
                          }
                          id="optionsC3Id"
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px",
                              boxShadow: "none", // Remove the blue box shadow
                              borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                              "&:hover": {
                                borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                              },
                              "&:focus": {
                                outline: "none", // Remove the blue outline on focus
                                borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                boxShadow: "none", // Remove the blue box shadow on focus
                              },
                              "&:active": {
                                borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                              },
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              background: state.isSelected
                                ? "#fff"
                                : "transparent", // Remove background highlight from non-selected options
                              color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                            }),
                          }}
                          isSearchable={true}
                          required={true}
                          onChange={(e) => handleC4Change(e)}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-6 rounded form-group">
                        <label>
                          <span>{hierarchyData.c4}</span>
                          <span className="mandatory">*</span>
                        </label>
                        <Select
                          options={optionsC4}
                          value={
                            defaultValueC4 !== ""
                              ? optionsC4.find(
                                  (option) => option.value === defaultValueC4
                                )
                              : ""
                          }
                          id="optionsC4Id"
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px",
                              boxShadow: "none", // Remove the blue box shadow
                              borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                              "&:hover": {
                                borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                              },
                              "&:focus": {
                                outline: "none", // Remove the blue outline on focus
                                borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                boxShadow: "none", // Remove the blue box shadow on focus
                              },
                              "&:active": {
                                borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                              },
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              background: state.isSelected
                                ? "#fff"
                                : "transparent", // Remove background highlight from non-selected options
                              color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                            }),
                          }}
                          isSearchable={true}
                          required={true}
                          onChange={(e) => handleC5Change(e)}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-6 rounded form-group">
                        <label>
                          <span>Form</span>
                          <span className="mandatory">*</span>
                        </label>
                        {/* <select className="form-control" required="" onChange={(e) => setFormName(e.target.value)}>
                                                    <option value="">Select</option>
                                                    {formData.map((i, j) => {
                                                        return (<option value={`${i.form_name}||${i.form_version}`}>{i.display_name}{' (V '}{i.form_version}{'.0)'}</option>)
                                                    })}
                                                </select> */}
                        <Select
                          options={optionsFormData}
                          value={
                            formName !== ""
                              ? optionsFormData.find(
                                  (option) => option.value === formName
                                )
                              : ""
                          }
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px",
                              boxShadow: "none", // Remove the blue box shadow
                              borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                              "&:hover": {
                                borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                              },
                              "&:focus": {
                                outline: "none", // Remove the blue outline on focus
                                borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                boxShadow: "none", // Remove the blue box shadow on focus
                              },
                              "&:active": {
                                borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                              },
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              background: state.isSelected
                                ? "#fff"
                                : "transparent", // Remove background highlight from non-selected options
                              color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                            }),
                          }}
                          isSearchable={true}
                          required={true}
                          onChange={(e) => setFormName(e.value)}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-6 form-group">
                        <label>
                          <span>Assign To</span>
                          <span className="mandatory">*</span>
                        </label>

                        <Select
                          options={optionAssign}
                          value={
                            assignValue !== ""
                              ? optionAssign.find(
                                  (option) => option.value === assignValue
                                )
                              : ""
                          }
                          id="reset"
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px",
                              boxShadow: "none", // Remove the blue box shadow
                              borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                              "&:hover": {
                                borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                              },
                              "&:focus": {
                                outline: "none", // Remove the blue outline on focus
                                borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                boxShadow: "none", // Remove the blue box shadow on focus
                              },
                              "&:active": {
                                borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                              },
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              background: state.isSelected
                                ? "#fff"
                                : "transparent", // Remove background highlight from non-selected options
                              color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                            }),
                          }}
                          onChange={(e) => hideEvaluateBtn(e.value)}
                        />
                      </div>
                      {
                        <div className={allClasses}>
                          <label>
                            <span>Evaluator Name(Id)</span>
                            <span className="mandatory">*</span>
                          </label>
                          {/* <select className="form-control" required="" onChange={(e) => setEvaluatorName(e.target.value)}>
                                                    <option value="">Select</option>
                                                    {otherEvaluatorsData.map((i, j) => {
                                                        return (<option value={i.agent_id}>{i.agent_name_id

                                                        }</option>)
                                                    })}
                                                </select> */}
                          <Select
                            options={optionsotherEvaluatorsData}
                            value={
                              evaluatorName !== ""
                                ? optionsotherEvaluatorsData.find(
                                    (option) => option.value === evaluatorName
                                  )
                                : ""
                            }
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                borderRadius: "100px",
                                boxShadow: "none", // Remove the blue box shadow
                                borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                                "&:hover": {
                                  borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                                },
                                "&:focus": {
                                  outline: "none", // Remove the blue outline on focus
                                  borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                  boxShadow: "none", // Remove the blue box shadow on focus
                                },
                                "&:active": {
                                  borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                  boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                                },
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                background: state.isSelected
                                  ? "#fff"
                                  : "transparent", // Remove background highlight from non-selected options
                                color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                              }),
                            }}
                            isSearchable={true}
                            required={true}
                            onChange={(e) => setEvaluatorName(e.value)}
                          />
                        </div>
                      }
                      {/* <div className="col-sm-6 col-md-4 col-lg-6 form-group">
                                        <label><span>Media</span><span className="mandatory">*</span></label>

                                        <Select
                                            className=""
                                            options={optionMedia}
                                            value={mediaValue !== '' ? optionMedia.find((option) => option.value === mediaValue) : ''}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderRadius: '100px',
                                                    boxShadow: 'none', // Remove the blue box shadow
                                                    borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                    '&:hover': {
                                                        borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                    },
                                                    '&:focus': {
                                                        outline: 'none', // Remove the blue outline on focus
                                                        borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                        boxShadow: 'none', // Remove the blue box shadow on focus
                                                    },
                                                    '&:active': {
                                                        borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                        boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                    },
                                                }),
                                                indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                    color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                }),

                                            }}
                                            required
                                            onChange={(selectedOption) => {
                                                handleMediaValue(selectedOption);
                                            }}
                                        />
                                    </div> */}

                      {/* <div className={`col-sm-6 col-md-6 col-lg-6 form-group ${(mediaValue === 'yes') ? ' ' : 'd-none'}`}>
                                        <div className="mb-1"><label ><span>Upload File</span><span className="mandatory">*</span></label></div>
                                        <div className=" form-control border border-black  p-2">
                                            <div className="ml-2">
                                                <input ref={fileInputRef} className="" type="file" accept="audio/wav" name="shadow" required="true" onChange={(e) => [setFile(e.target.files[0]), setFileName(true)]} />
                                            </div>
                                        </div>

                                    </div> */}
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  {/* <button type="button" className="btn btn-secondary" data-dismiss="modal">Register</button> */}
                  {showEvaluateBtn && (
                    // <button type="button" className="btn btn-outline-primary" onClick={showEvaluateForm}>Evaluate</button>
                    <div className="position-relative d-flex align-items-center">
                      <button
                        type="button"
                        className={`btn btn-outline-primary`}
                        onClick={showEvaluateForm}
                        disabled={isLoadingEvaluate}
                      >
                        Evaluate
                      </button>
                      {isLoadingEvaluate && (
                        <div className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center opacity-75">
                          <div
                            className="spinner-border text-black"
                            role="status"
                          ></div>
                        </div>
                      )}
                    </div>
                  )}
                  <button
                    type="button"
                    onClick={() => handleAddEvaluation(0)}
                    className={`btn btn-primary   ${
                      isDataFound ? "loading" : ""
                    } `}
                  >
                    Register
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="callModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="callModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable" role="document">
          <div className="modal-content" style={{ height: "550px" }}>
            <div className="modal-header">
              <h5 className="modal-title" id="callModal">
                Transcript
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {allCalls
                ?.filter((i) => i?._id === conversationId)
                ?.map((j) => {
                  return j?.diarize_transcript?.map((k) => {
                    return (
                      <div>
                        <h5>
                          <span style={{ fontWeight: 700 }}>{k?.type} : </span>
                          <span style={{ textAlign: "justify" }}>
                            {k?.text}{" "}
                          </span>
                        </h5>
                        {/* <h5><span style={{ fontWeight: 700 }}>Type : </span>{k?.type} </h5>
                                            <h5><span style={{ fontWeight: 700 }}>Emotion : </span>{k?.emotion} </h5> */}
                        <hr />
                      </div>
                    );
                  });
                })}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              {/* <button type="button" className="btn btn-primary">Save changes</button> */}
            </div>
          </div>
        </div>
      </div>

      <div className="main-contents">
        <div className="viewport-section">
          <div className="container-fluid">
            <div className="form-row">
              <div className="col-md-7 col-xl-8 vs-left">
                <div className="row">
                  <div className="col-md-4 col-lg-4 col-sm-4"></div>
                  <div className="col-md-4 col-lg-4 col-sm-4">
                    <div className="row" style={{ marginLeft: "1px" }}>
                      <div
                        className={` ${
                          activeDiv === 1 ? "active-opt" : "de-active-opt"
                        }`}
                        onClick={() => [handleDivClick(1), setOperator("OR")]}
                        style={{ width: "35px", textAlign: "center" }}
                      >
                        OR
                      </div>
                      <div
                        className={` ${
                          activeDiv === 2 ? "active-opt" : "de-active-opt"
                        }`}
                        onClick={() => [handleDivClick(2), setOperator("AND")]}
                        style={{ width: "35px", textAlign: "center" }}
                      >
                        AND
                      </div>
                      <div
                        className={` ${
                          activeDiv === 3 ? "active-opt" : "de-active-opt"
                        }`}
                        onClick={() => [handleDivClick(3), setOperator("NOT")]}
                        style={{ width: "35px", textAlign: "center" }}
                      >
                        NOT
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 col-sm-4"></div>
                </div>
                <div className="search-box">
                  <div className="form-row">
                    <div className="col-sm-7 col-lg-8">
                      {/* <input type="text" className="form-control" placeholder="Search for keywords like agent, tags, call" /> */}
                    </div>
                    {/* <div className="col-sm-5 col-lg-4">
                                            <button type="button" className="btn btn-primary mr-3">Search</button>
                                            <button type="button" className="btn btn-clear">Clear</button>
                                        </div> */}

                    <div className="col-sm-12">
                      <div className="d-inline-flex searchbox-check">
                        <input
                          type="checkbox"
                          id="search_check_01"
                          name=""
                          className="search-check-input d-none"
                        />
                        <OverlayPopover
                          trigger="click"
                          key="bottom"
                          placement="bottom"
                          rootClose={true}
                          overlay={
                            <Popover>
                              <Popover.Header as="h1">
                                Filter calls by time
                                <button
                                  className="btn btn-secondary mx-4"
                                  onClick={() => [
                                    setWhichFilter((prevValues) =>
                                      prevValues.filter(
                                        (value) => value !== "filter_by_time"
                                      )
                                    ),
                                    setTimeFilter("Filter By Time"),
                                    setHandleStartTime(
                                      moment()
                                        .subtract(1, "days")
                                        .format("YYYY-MM-DD")
                                    ),
                                    setHandleEndTime(
                                      moment().format("YYYY-MM-DD")
                                    ),
                                  ]}
                                  style={{ padding: "3px", width: "60px" }}
                                >
                                  clear
                                </button>
                              </Popover.Header>
                              <Popover.Body bsPrefix="pop-body" as="div">
                                <div className="col">
                                  <button
                                    style={{ fontSize: "13px" }}
                                    onClick={(e) => [
                                      setTimeFilter("Current Month"),
                                      setIsCustom(false),
                                      !whichFilter.includes("filter_by_time") &&
                                        setWhichFilter((prevValues) => [
                                          ...prevValues,
                                          "filter_by_time",
                                        ]),
                                      setHandleStartTime(
                                        moment()
                                          .clone()
                                          .startOf("month")
                                          .format("YYYY-MM-DD")
                                      ),
                                      setHandleEndTime(
                                        moment().format("YYYY-MM-DD")
                                      ),
                                    ]}
                                  >
                                    Current Month
                                  </button>
                                  {/* <button style={{ border: 'none', fontSize: "13px" }} onClick={(e) => [setTimeFilter("Yesterday"), setIsCustom(false), handleFilter({ label: "Yesterday", value: "Yesterday" }, "time_filter")]}>Yesterday</button> */}
                                  <button
                                    style={{ border: "none", fontSize: "13px" }}
                                    onClick={(e) => [
                                      setTimeFilter("Yesterday"),
                                      setIsCustom(false),
                                      !whichFilter.includes("filter_by_time") &&
                                        setWhichFilter((prevValues) => [
                                          ...prevValues,
                                          "filter_by_time",
                                        ]),
                                      setHandleStartTime(
                                        moment(today)
                                          .subtract(1, "days")
                                          .format("YYYY-MM-DD")
                                      ),
                                      setHandleEndTime(
                                        moment()
                                          .startOf("day")
                                          .format("YYYY-MM-DD")
                                      ),
                                    ]}
                                  >
                                    Yesterday
                                  </button>

                                  <button
                                    style={{ fontSize: "13px" }}
                                    onClick={(e) => [
                                      setTimeFilter("Last 7 Days"),
                                      setIsCustom(false),
                                      !whichFilter.includes("filter_by_time") &&
                                        setWhichFilter((prevValues) => [
                                          ...prevValues,
                                          "filter_by_time",
                                        ]),
                                      setHandleStartTime(
                                        moment(today)
                                          .subtract(7, "days")
                                          .format("YYYY-MM-DD")
                                      ),
                                      setHandleEndTime(
                                        moment()
                                          .startOf("day")
                                          .format("YYYY-MM-DD")
                                      ),
                                    ]}
                                  >
                                    Last 7 Days
                                  </button>

                                  <button
                                    style={{ fontSize: "13px" }}
                                    onClick={(e) => [
                                      setTimeFilter("Last 30 Days"),
                                      setIsCustom(false),
                                      !whichFilter.includes("filter_by_time") &&
                                        setWhichFilter((prevValues) => [
                                          ...prevValues,
                                          "filter_by_time",
                                        ]),
                                      setHandleStartTime(
                                        moment(today)
                                          .subtract(30, "days")
                                          .format("YYYY-MM-DD")
                                      ),
                                      setHandleEndTime(
                                        moment()
                                          .startOf("day")
                                          .format("YYYY-MM-DD")
                                      ),
                                    ]}
                                  >
                                    Last 30 Days
                                  </button>

                                  <button
                                    style={{ fontSize: "13px" }}
                                    onClick={(e) => [
                                      setTimeFilter("Last 90 Days"),
                                      setIsCustom(false),
                                      !whichFilter.includes("filter_by_time") &&
                                        setWhichFilter((prevValues) => [
                                          ...prevValues,
                                          "filter_by_time",
                                        ]),
                                      setHandleStartTime(
                                        moment(today)
                                          .subtract(90, "days")
                                          .format("YYYY-MM-DD")
                                      ),
                                      setHandleEndTime(
                                        moment()
                                          .startOf("day")
                                          .format("YYYY-MM-DD")
                                      ),
                                    ]}
                                  >
                                    Last 90 Days
                                  </button>

                                  {!isCustom && (
                                    <button
                                      style={{ fontSize: "13px" }}
                                      onClick={() => [
                                        setTimeFilter("Custom"),
                                        setIsCustom(true),
                                        !whichFilter.includes(
                                          "filter_by_time"
                                        ) &&
                                          setWhichFilter((prevValues) => [
                                            ...prevValues,
                                            "filter_by_time",
                                          ]),
                                      ]}
                                    >
                                      Custom
                                    </button>
                                  )}

                                  {isCustom && (
                                    <div>
                                      <DateRangePicker
                                        startDateChange={onStartDateChange}
                                        setIsCustom={setIsCustom}
                                        startDate={new Date(handleStartTime)}
                                        endDate={
                                          new Date(
                                            moment(handleEndTime).format(
                                              "YYYY-MM-DD"
                                            )
                                          )
                                        }
                                        endDateChange={onEndDateChange}
                                      />
                                      <button
                                        className="btn btn-primary"
                                        onClick={(e) =>
                                          handleAllFilter(false, true)
                                        }
                                        style={{
                                          marginRight: "-180px",
                                          marginTop: "-30px",
                                        }}
                                      >
                                        Apply
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          {/* <i style={{ fontSize: 20 }} className="las la-trash">call duration</i> */}
                          <span className="search-check-label ">
                            {timeFilter}
                          </span>
                        </OverlayPopover>
                      </div>
                      <div className="d-inline-flex searchbox-check">
                        <input
                          type="checkbox"
                          id="search_check_02"
                          name=""
                          className="search-check-input d-none"
                        />
                        {/* <label for="search_check_02" className="search-check-label">agent</label> */}
                        <Select
                          placeholder="Filter By Agent..."
                          // onChange={handleFilter}
                          onChange={(e) => [
                            setStoreAllAgents(e),
                            e?.length > 0
                              ? !whichFilter.includes("filter_by_agent") &&
                                setWhichFilter((prevValues) => [
                                  ...prevValues,
                                  "filter_by_agent",
                                ])
                              : setWhichFilter((prevValues) =>
                                  prevValues.filter(
                                    (value) => value !== "filter_by_agent"
                                  )
                                ),
                          ]}
                          options={[
                            ...new Set(allAgents?.map(JSON.stringify)),
                          ].map(JSON.parse)}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isClearable={true}
                          allowSelectAll={true}
                          className="basic-multi-select"
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px",
                              fontSize: "11px",
                              width: "200px",
                              textAlign: "justify",
                              boxShadow: "none", // Remove the blue box shadow
                              borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                              "&:hover": {
                                borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                              },
                              "&:focus": {
                                outline: "none", // Remove the blue outline on focus
                                borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                boxShadow: "none", // Remove the blue box shadow on focus
                              },
                              "&:active": {
                                borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                              },
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              background: state.isSelected
                                ? "#fff"
                                : "transparent", // Remove background highlight from non-selected options
                              color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                              fontSize: "9px",
                            }),
                          }}
                          components={{
                            Option,
                            ValueContainer,
                            // Menu: ({ children, ...props }) => (
                            //     <components.Menu {...props}>
                            //         {children}
                            //         <button className="btn btn-primary" style={{ height: "25px", margin: "10px", paddingTop: "5px", width: "85%" }} onClick={() => handleFilter(storeAllAgents, "agent_filter")} >Apply</button>
                            //     </components.Menu>
                            // ),
                          }}
                        ></Select>
                      </div>
                      <div className="d-inline-flex searchbox-check">
                        <Select
                          options={folderListData}
                          placeholder="Select Folder"
                          isSearchable={true}
                          // isMulti
                          className=""
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px",
                              width: "160px",
                              boxShadow: "none", // Remove the blue box shadow
                              borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                              "&:hover": {
                                borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                              },
                              "&:focus": {
                                outline: "none", // Remove the blue outline on focus
                                borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                boxShadow: "none", // Remove the blue box shadow on focus
                              },
                              "&:active": {
                                borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                              },
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              background: state.isSelected
                                ? "#fff"
                                : "transparent", // Remove background highlight from non-selected options
                              color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                            }),
                          }}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isClearable={true}
                          allowSelectAll={true}
                          // components={{
                          //     Option,
                          //     ValueContainer: ValueContainer2,
                          // }}
                          value={folderValue}
                          onChange={(e, event) => [
                            handleFolderChange(e, event),
                            setFolderID(e?.value),
                            e && Object.keys(e)?.length > 0
                              ? !whichFilter.includes("filter_by_folder_id") &&
                                setWhichFilter((prevValues) => [
                                  ...prevValues,
                                  "filter_by_folder_id",
                                ])
                              : setWhichFilter((prevValues) =>
                                  prevValues.filter(
                                    (value) => value !== "filter_by_folder_id"
                                  )
                                ),
                          ]}
                        />
                      </div>
                      <div className="d-inline-flex searchbox-check">
                        <input
                          type="checkbox"
                          id="search_check_03"
                          name=""
                          className="search-check-input d-none"
                        />
                        {/* <label for="search_check_03" className="search-check-label">tags</label> */}
                        {/* <div className="row" style={{marginLeft:"1px",}}>
                                                                <div className={` ${activeDiv === 1 ? 'active-opt' : 'de-active-opt'}`} onClick={() => [handleDivClick(1),setOperator("OR")]} style={{borderTopLeftRadius:"100px"}}>OR</div>
                                                                <div className={` ${activeDiv === 2 ? 'active-opt' : 'de-active-opt'}`} onClick={() => [handleDivClick(2),setOperator("AND")]}>AND</div>
                                                                <div className={` ${activeDiv === 3 ? 'active-opt' : 'de-active-opt'}`} onClick={() => [handleDivClick(3),setOperator("NOT")]} style={{borderBottomRightRadius:"100px"}}>NOT</div>
                                                               </div> */}
                        <Select
                          placeholder="Filter By Tags..."
                          onChange={(e) => [
                            setStoreAllTags(e),
                            e?.length > 0
                              ? !whichFilter.includes("filter_by_tags") &&
                                setWhichFilter((prevValues) => [
                                  ...prevValues,
                                  "filter_by_tags",
                                ])
                              : setWhichFilter((prevValues) =>
                                  prevValues.filter(
                                    (value) => value !== "filter_by_tags"
                                  )
                                ),
                          ]}
                          options={[
                            ...new Set(allTagsData?.map(JSON.stringify)),
                          ]?.map(JSON.parse)}
                          //options={allTagsData}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isClearable={true}
                          allowSelectAll={true}
                          className="basic-multi-select"
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px",
                              fontSize: "10px",
                              width: "200px",
                              boxShadow: "none", // Remove the blue box shadow
                              fontSize: "11px",
                              borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                              "&:hover": {
                                borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                              },
                              "&:focus": {
                                outline: "none", // Remove the blue outline on focus
                                borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                boxShadow: "none", // Remove the blue box shadow on focus
                              },
                              "&:active": {
                                borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                              },
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              background: state.isSelected
                                ? "#fff"
                                : "transparent", // Remove background highlight from non-selected options
                              color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                              fontSize: "11px",
                            }),
                          }}
                          components={{
                            Option,
                            ValueContainer,
                            // Menu: ({ children, ...props }) => (
                            //     <components.Menu {...props}>

                            //         {/* <div className="row" style={{marginTop:"-88px",marginLeft:"1px",width:"132%"}}>
                            //         <div className={`col-md-3 col-lg-3 col-sm-3 ${activeDiv === 1 ? 'active-opt' : 'de-active-opt'}`} onClick={() => [handleDivClick(1),setOperator("OR")]} style={{borderTopLeftRadius:"100px"}}>OR</div>
                            //         <div className={`col-md-3 col-lg-3 col-sm-3 ${activeDiv === 2 ? 'active-opt' : 'de-active-opt'}`} onClick={() => [handleDivClick(2),setOperator("AND")]}>AND</div>
                            //         <div className={`col-md-3 col-lg-3 col-sm-3 ${activeDiv === 3 ? 'active-opt' : 'de-active-opt'}`} onClick={() => [handleDivClick(3),setOperator("NOT")]} style={{borderBottomRightRadius:"100px"}}>NOT</div>
                            //        </div> */}

                            //         {children}
                            //     </components.Menu>
                            // ),
                          }}
                        />
                      </div>
                      {window.location.host?.split(".")[0] ===
                        "recruitment" && (
                        <div className="d-inline-flex searchbox-check">
                          <input
                            type="text"
                            id="call-id-input"
                            onChange={(e) => [
                              setCalleeFilter(e.target.value),
                              e.target.value !== ""
                                ? !whichFilter.includes(
                                    "filter_by_callee_number"
                                  ) &&
                                  setWhichFilter((prevValues) => [
                                    ...prevValues,
                                    "filter_by_callee_number",
                                  ])
                                : setWhichFilter((prevValues) =>
                                    prevValues.filter(
                                      (value) =>
                                        value !== "filter_by_callee_number"
                                    )
                                  ),
                            ]}
                            style={{
                              height: "35px",
                              width: "100%",
                              borderColor: "gray",
                              borderRadius: "100px",
                              border: "1px solid gray",
                              marginLeft: "3px",
                              marginRight: "-45px",
                              marginTop: "2px",
                              padding: "15px",
                            }}
                            placeholder="Filter By Callee No..."
                          />
                        </div>
                      )}
                      {window.location.host?.split(".")[0] ===
                        "recruitment" && (
                        <div className="d-inline-flex searchbox-check">
                          <Select
                            options={[
                              { label: "CA", value: "CA" },
                              { label: "CR", value: "CR" },
                              { label: "US", value: "US" },
                              { label: "TT", value: "TT" },
                              { label: "PH", value: "PH" },
                            ]}
                            placeholder="Select Location"
                            isSearchable={true}
                            // isMulti
                            className=""
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                borderRadius: "100px",
                                width: "160px",
                                boxShadow: "none", // Remove the blue box shadow
                                borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                                "&:hover": {
                                  borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                                },
                                "&:focus": {
                                  outline: "none", // Remove the blue outline on focus
                                  borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                  boxShadow: "none", // Remove the blue box shadow on focus
                                },
                                "&:active": {
                                  borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                  boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                                },
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                background: state.isSelected
                                  ? "#fff"
                                  : "transparent", // Remove background highlight from non-selected options
                                color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                              }),
                            }}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            isClearable={true}
                            allowSelectAll={true}
                            // components={{
                            //     Option,
                            //     ValueContainer: ValueContainer2,
                            // }}
                            value={locationVal}
                            onChange={(e, event) => [
                              setLocationVal(e),
                              e && Object.keys(e)?.length > 0
                                ? !whichFilter.includes("filter_by_location") &&
                                  setWhichFilter((prevValues) => [
                                    ...prevValues,
                                    "filter_by_location",
                                  ])
                                : setWhichFilter((prevValues) =>
                                    prevValues.filter(
                                      (value) => value !== "filter_by_location"
                                    )
                                  ),
                            ]}
                          />
                        </div>
                      )}
                      <div className="d-inline-flex searchbox-check">
                        <input
                          type="text"
                          id="call-id-input"
                          onChange={(e) => [
                            setCallIdFilter(e.target.value),
                            e.target.value !== ""
                              ? !whichFilter.includes("filter_by_call_id") &&
                                setWhichFilter((prevValues) => [
                                  ...prevValues,
                                  "filter_by_call_id",
                                ])
                              : setWhichFilter((prevValues) =>
                                  prevValues.filter(
                                    (value) => value !== "filter_by_call_id"
                                  )
                                ),
                          ]}
                          style={{
                            height: "35px",
                            width: "34%",
                            borderColor: "gray",
                            borderRadius: "100px",
                            border: "1px solid gray",
                            marginLeft: "3px",
                            marginRight: "4px",
                            marginTop: "2px",
                            padding: "15px",
                          }}
                          placeholder="Filter By Call Id..."
                        />

                        <input
                          type="checkbox"
                          id="search_check_04"
                          name=""
                          className="search-check-input d-none"
                        />
                        {/* <label for="search_check_04" className="search-check-label">call duration</label> */}
                        <OverlayPopover
                          trigger="click"
                          key="bottom"
                          placement="bottom"
                          rootClose={true}
                          overlay={
                            <Popover>
                              <Popover.Header as="h1">
                                Filter calls by minutes
                                <button
                                  className="btn btn-secondary mx-4"
                                  onClick={() => [
                                    setWhichFilter((prevValues) =>
                                      prevValues.filter(
                                        (value) =>
                                          value !== "filter_by_duration"
                                      )
                                    ),
                                    setFromDuration(""),
                                    setToDuration(""),
                                    setHandleStartTime(
                                      moment()
                                        .clone()
                                        .startOf("month")
                                        .format("YYYY-MM-DD")
                                    ),
                                    setHandleEndTime(
                                      moment().format("YYYY-MM-DD")
                                    ),
                                  ]}
                                  style={{ padding: "3px", width: "60px" }}
                                >
                                  clear
                                </button>
                              </Popover.Header>
                              <Popover.Body bsPrefix="pop-body" as="div">
                                <input
                                  type="number"
                                  placeholder="From"
                                  className="mx-4"
                                  value={fromDuration}
                                  onChange={(e) => [
                                    setFromDuration(e.target.value),
                                    !whichFilter.includes(
                                      "filter_by_duration"
                                    ) &&
                                      setWhichFilter((prevValues) => [
                                        ...prevValues,
                                        "filter_by_duration",
                                      ]),
                                  ]}
                                  style={{
                                    height: "30px",
                                    width: "70px",
                                    borderRadius: "100px",
                                    textAlign: "center",
                                  }}
                                  min={0}
                                />
                                <input
                                  type="number"
                                  placeholder="To"
                                  className="mx-4"
                                  value={toDuration}
                                  onChange={(e) => [
                                    setToDuration(e.target.value),
                                    !whichFilter.includes(
                                      "filter_by_duration"
                                    ) &&
                                      setWhichFilter((prevValues) => [
                                        ...prevValues,
                                        "filter_by_duration",
                                      ]),
                                  ]}
                                  style={{
                                    height: "30px",
                                    width: "70px",
                                    borderRadius: "100px",
                                    textAlign: "center",
                                  }}
                                  min={fromDuration}
                                />
                                <hr />

                                {/* <button type="button" className="btn btn-primary" style={{ marginLeft: "30px" }} onClick={handleCallDurationFilter}>Apply</button> */}
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          {/* <i style={{ fontSize: 20 }} className="las la-trash">call duration</i> */}
                          <span
                            className="search-check-label"
                            style={{
                              fontSize: "11px",
                              height: "35px",
                              marginTop: "2px",
                              paddingTop: "9px",
                            }}
                          >
                            call duration
                          </span>
                        </OverlayPopover>

                        <button
                          className="btn btn-primary"
                          style={{
                            height: "28px",
                            marginLeft: "7px",
                            marginTop: "3px",
                            paddingTop: "7px",
                          }}
                          onClick={() => handleAllFilter(false, true)}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="modal fade bd-example-modal-sm"
                  id="agent-modal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="mySmallModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div className="modal-header">All Selected Agents</div>
                    <div class="modal-content">
                      <Select
                        placeholder="All Selected Agents"
                        options={showAgentsForUsers}
                      />
                    </div>
                    <div className="modal-footer"></div>
                  </div>
                </div>
                <div
                  class="modal fade bd-example-modal-sm"
                  id="tags-modal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="mySmallModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog" style={{ width: "30%" }}>
                    <div className="modal-header">All Selected Tags</div>
                    <div class="modal-content">
                      <Select
                        placeholder="All Selected Tags"
                        options={showTagsForUsers}
                      />
                    </div>
                    <div className="modal-footer"></div>
                  </div>
                </div>
                {/* <DataTableView columns={filterHeaders} data={[]} quickSearch={false}/> */}
                {filteredApplied && (
                  <table
                    class="table"
                    style={{ marginTop: "15px", marginBottom: "-20px" }}
                  >
                    <thead
                      style={{ backgroundColor: "#0D9DD9", color: "white" }}
                    >
                      <tr>
                        <th scope="col">Filter By Time</th>
                        <th scope="col">Filter By Agent</th>
                        <th scope="col">Filter By Folder</th>
                        <th scope="col">Filter By Tags</th>
                        <th scope="col">Filter By Duration</th>
                        <th scope="col">Filter By Call Id</th>
                        {window.location.host?.split(".")[0] ===
                          "recruitment" && (
                          <th scope="col">Filter By Callee Number</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {renderFilter?.includes("filter_by_time") ? (
                            <span>
                              {showTimeFilter === "Custom" ? (
                                `${moment(handleStartTime).format(
                                  "YYYY-MM-DD"
                                )} : ${moment(handleEndTime).format(
                                  "YYYY-MM-DD"
                                )}`
                              ) : showTimeFilter === "Filter By Time" ? (
                                <p style={{ fontWeight: "600" }}>All</p>
                              ) : (
                                showTimeFilter
                              )}
                            </span>
                          ) : (
                            <p style={{ fontWeight: "600" }}>All</p>
                          )}
                        </td>
                        <td>
                          {renderFilter?.includes("filter_by_agent") ? (
                            <span>
                              {showAgentFilter?.length > 2 ? (
                                <>
                                  {showAgentFilter[0]?.label +
                                    ", " +
                                    showAgentFilter[1]?.label}

                                  {/* <Tippy allowHTML={true} zIndex='99999' trigger='mouseenter' offset={[0, 0]} interactiveBorder='30' maxWidth="500" arrow={true} animation='fade' placement='bottom' content={<div class="container" style={{ width: "300px", overflow: "auto" }}><div class="folder-tooltip row"><ul type="number">{showAgentFilter?.map(i => {
                                                                return <li id={i?.value}>{i?.label},</li>
                                                            })}</ul></div></div>}>
                                                                <span style={{ fontWeight: 600, marginLeft: "5px", textDecoration: "underline", cursor: "pointer", color: "rgb(0, 123, 255)" }}>Show More</span>
                                                            </Tippy> */}
                                  <OverlayPopover
                                    trigger="click"
                                    key="bottom"
                                    placement="bottom"
                                    rootClose={true}
                                    overlay={
                                      <Popover>
                                        <Popover.Header as="h1">
                                          All Selected Agents
                                        </Popover.Header>
                                        <Popover.Body
                                          bsPrefix="pop-body"
                                          as="div"
                                          style={{
                                            maxHeight: "200px",
                                            overflowY: "auto",
                                          }}
                                        >
                                          <div className="col">
                                            {showAgentFilter?.map(
                                              (i, index) => (
                                                <div
                                                  style={{ fontSize: "12px" }}
                                                  key={index}
                                                >
                                                  {i?.label}
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </Popover.Body>
                                      </Popover>
                                    }
                                  >
                                    <span
                                      style={{
                                        color: "#088BD2",
                                        cursor: "pointer",
                                      }}
                                      className="search-check-label mx-2"
                                    >
                                      Show More
                                    </span>
                                  </OverlayPopover>
                                </>
                              ) : (
                                // Render all items if the length is not greater than 2
                                showAgentFilter?.map(
                                  (item, index) => item?.label + ","
                                )
                              )}
                            </span>
                          ) : (
                            <p style={{ fontWeight: "600" }}>All</p>
                          )}
                        </td>
                        <td>
                          {renderFilter?.includes("filter_by_folder_id") ? (
                            <span>
                              {folderIdFilterForDisplay !== "" ? (
                                <p className="mx-2">
                                  {folderIdFilterForDisplay}
                                </p>
                              ) : (
                                <p
                                  className="my-2 mx-3"
                                  style={{ fontWeight: "600" }}
                                >
                                  All
                                </p>
                              )}
                            </span>
                          ) : (
                            <p
                              className="my-0 mx-1"
                              style={{ fontWeight: "600" }}
                            >
                              All
                            </p>
                          )}
                        </td>

                        <td>
                          {renderFilter?.includes("filter_by_tags") ? (
                            <span>
                              {showTagsFilter?.length > 2 ? (
                                <>
                                  {showTagsFilter[0]?.label +
                                    ", " +
                                    showTagsFilter[1]?.label}

                                  {/* <Tippy allowHTML={true} zIndex='99999' trigger='mouseenter' offset={[0, 0]} interactiveBorder='30' maxWidth="500" arrow={true} animation='fade' placement='bottom' content={<div class="container" style={{ width: "300px" }}><div class="folder-tooltip row"><ul type="number">{showTagsFilter?.map(i => {
                                                                        return <li id={i?.value}>{i?.label},</li>
                                                                    })}</ul></div></div>}>
                                                                        <span style={{ fontWeight: 600, marginLeft: "5px", textDecoration: "underline", cursor: "pointer", color: "rgb(0, 123, 255)" }}>Show More</span>
                                                                    </Tippy> */}
                                  <OverlayPopover
                                    trigger="click"
                                    key="bottom"
                                    placement="bottom"
                                    rootClose={true}
                                    overlay={
                                      <Popover>
                                        <Popover.Header as="h1">
                                          All Selected Tags
                                        </Popover.Header>
                                        <Popover.Body
                                          bsPrefix="pop-body"
                                          as="div"
                                          style={{
                                            maxHeight: "200px",
                                            overflowY: "auto",
                                          }}
                                        >
                                          <div className="col">
                                            {showTagsFilter?.map((i, index) => (
                                              <div
                                                style={{ fontSize: "12px" }}
                                                key={index}
                                              >
                                                {i?.label}
                                              </div>
                                            ))}
                                          </div>
                                        </Popover.Body>
                                      </Popover>
                                    }
                                  >
                                    <span
                                      style={{
                                        color: "#088BD2",
                                        cursor: "pointer",
                                      }}
                                      className="search-check-label mx-2"
                                    >
                                      Show More
                                    </span>
                                  </OverlayPopover>
                                </>
                              ) : (
                                showTagsFilter?.map(
                                  (item, index) => item?.label + ","
                                )
                              )}
                            </span>
                          ) : (
                            <p style={{ fontWeight: "600" }}>All</p>
                          )}
                        </td>
                        {renderFilter?.includes("filter_by_duration") ? (
                          <span>
                            {showFromDurationFilter !== "" &&
                            showToDurationFilter !== "" ? (
                              <p className="mx-2 my-2">
                                {showFromDurationFilter} :{" "}
                                {showToDurationFilter}
                              </p>
                            ) : (
                              <p
                                className="my-2 mx-3"
                                style={{ fontWeight: "600" }}
                              >
                                All
                              </p>
                            )}
                          </span>
                        ) : (
                          <p
                            className="my-2 mx-3"
                            style={{ fontWeight: "600" }}
                          >
                            All
                          </p>
                        )}
                        <td>
                          {renderFilter?.includes("filter_by_call_id") ? (
                            <span>
                              {callIdFilterForDisplay !== "" ? (
                                <p className="mx-2 my-2">
                                  {callIdFilterForDisplay}
                                </p>
                              ) : (
                                <p
                                  className="my-2 mx-3"
                                  style={{ fontWeight: "600" }}
                                >
                                  All
                                </p>
                              )}
                            </span>
                          ) : (
                            <p
                              className="my-0 mx-1"
                              style={{ fontWeight: "600" }}
                            >
                              All
                            </p>
                          )}
                        </td>
                        <td>
                          {window.location.host?.split(".")[0] ===
                            "recruitment" &&
                            (renderFilter?.includes(
                              "filter_by_callee_number"
                            ) ? (
                              <span>
                                {calleeNoFilterForDisplay !== "" ? (
                                  <p className="mx-2 my-2">
                                    {calleeNoFilterForDisplay}
                                  </p>
                                ) : (
                                  <p
                                    className="my-2 mx-3"
                                    style={{ fontWeight: "600" }}
                                  >
                                    All
                                  </p>
                                )}
                              </span>
                            ) : (
                              <p
                                className="my-0 mx-1"
                                style={{ fontWeight: "600" }}
                              >
                                All
                              </p>
                            ))}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}

                <div className="ip-box call-box d-flex flex-column p-0">
                  <div className="box-header px-0">
                    <div className="call-results">
                      <label>Search Results : </label>
                      <span>
                        {" "}
                        {allCalls?.length} Showing of {filterCallsCount}/
                        {succCallsCount}
                      </span>

                      {/* {
                                                (
                                                    renderFilter?.includes("filter_by_time") || renderFilter?.includes("filter_by_agent") || renderFilter?.includes("filter_by_tags") ||
                                                    renderFilter?.includes("filter_by_duration")
                                                )
                                                &&
                                                <label className="mx-2">| Data Filtered By :  </label>
                                            } */}

                      {/* {

                                                renderFilter?.includes("filter_by_time") &&
                                                <span style={{ height: "20px", marginLeft: "10px", marginTop: "-10px!important", borderRadius: '100px', border: '1px solid gray', fontSize: '11px', padding: '5px' }}>
                                                    {showTimeFilter === "Custom" ? `Time Range : ${moment(handleStartTime).format("YYYY-MM-DD")} : ${moment(handleEndTime).format("YYYY-MM-DD")}` : showTimeFilter}
                                                </span>

                                            } */}
                      {/* {
                                                renderFilter?.includes("filter_by_agent") &&

                                                <span style={{ height: "20px", marginLeft: "10px", borderRadius: '100px', border: '1px solid gray', fontSize: '11px', padding: '5px' }}>

                                                    {showAgentFilter?.length > 1 ? (
                                                        <>
                                                            {"" + showAgentFilter[0]?.label}

                                                            <Tippy allowHTML={true} zIndex='99999' trigger='mouseenter' offset={[0, 0]} interactiveBorder='30' maxWidth="500" arrow={true} animation='fade' placement='bottom' content={<div class="container" style={{ width: "300px", overflow: "auto" }}><div class="folder-tooltip row"><ul type="number">{showAgentFilter?.map(i => {
                                                                return <li id={i?.value}>{i?.label},</li>
                                                            })}</ul></div></div>}>
                                                                <span style={{ fontWeight: 600, marginLeft: "5px", textDecoration: "underline", cursor: "pointer", color: "rgb(0, 123, 255)" }}>Show More</span>
                                                            </Tippy>
                                                        </>
                                                    ) : (
                                                        showAgentFilter?.map((item, index) => (
                                                            "" + item?.label
                                                        ))
                                                    )}
                                                </span>
                                            } */}
                      {/* {
                                                renderFilter?.includes("filter_by_tags") &&

                                                <span style={{ height: "20px", marginLeft: "10px", borderRadius: '100px', border: '1px solid gray', fontSize: '11px', padding: '5px' }}>
                                                    {showTagsFilter?.length > 1 ? (
                                                        <>
                                                            {"" + showTagsFilter[0]?.label}


                                                            <Tippy allowHTML={true} zIndex='99999' trigger='mouseenter' offset={[0, 0]} interactiveBorder='30' maxWidth="500" arrow={true} animation='fade' placement='bottom' content={<div class="container" style={{ width: "300px" }}><div class="folder-tooltip row"><ul type="number">{showTagsFilter?.map(i => {
                                                                return <li id={i?.value}>{i?.label},</li>
                                                            })}</ul></div></div>}>
                                                                <span style={{ fontWeight: 600, marginLeft: "5px", textDecoration: "underline", cursor: "pointer", color: "rgb(0, 123, 255)" }}>Show More</span>
                                                            </Tippy>

                                                        </>
                                                    ) : (
                                                        showTagsFilter?.map((item, index) => (
                                                            "" + item?.label
                                                        ))
                                                    )}



                                                </span>
                                            } */}
                      {/* {
                                                renderFilter?.includes("filter_by_duration") &&

                                                <span style={{ height: "20px", marginLeft: "10px", borderRadius: '100px', border: '1px solid gray', fontSize: '11px', padding: '5px' }}>
                                                    {showFromDurationFilter !== "" && showToDurationFilter !== "" ? `Duration : ${showFromDurationFilter} : ${showToDurationFilter}` : `Duration : ${showFromDurationFilter} : ${showToDurationFilter}`}
                                                </span>
                                            } */}
                    </div>

                    <div
                      class="modal add-tags-modal"
                      tabindex="-1"
                      role="dialog"
                    >
                      <div
                        class="modal-dialog"
                        role="document"
                        style={{ width: "25%" }}
                      >
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title">Add Tags</h5>
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <Select
                              placeholder="Add Tags"
                              onChange={(e) => []}
                              options={[
                                ...new Set(allTagsData?.map(JSON.stringify)),
                              ]?.map(JSON.parse)}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              isClearable={true}
                              allowSelectAll={true}
                              className="basic-multi-select"
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  borderRadius: "100px",
                                  fontSize: "10px",

                                  boxShadow: "none", // Remove the blue box shadow
                                  fontSize: "11px",
                                  borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                                  "&:hover": {
                                    borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                                  },
                                  "&:focus": {
                                    outline: "none", // Remove the blue outline on focus
                                    borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                    boxShadow: "none", // Remove the blue box shadow on focus
                                  },
                                  "&:active": {
                                    borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                    boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                                  },
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  background: state.isSelected
                                    ? "#fff"
                                    : "transparent", // Remove background highlight from non-selected options
                                  color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                                  fontSize: "10px",
                                }),
                              }}
                              components={{
                                Option,
                                ValueContainer,

                                Menu: ({ children, ...props }) => (
                                  <components.Menu {...props}>
                                    {children}
                                    <button
                                      className="btn btn-primary"
                                      style={{
                                        height: "25px",
                                        margin: "10px",
                                        paddingTop: "5px",
                                        width: "85%",
                                      }}
                                      onClick={() =>
                                        handleFilter(
                                          storeAllTags,
                                          "tags_filter"
                                        )
                                      }
                                    >
                                      Add
                                    </button>
                                  </components.Menu>
                                ),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div class="modal matches-phrases-modal" tabindex="-1" role="dialog">
                                            <div class="modal-dialog" role="document" style={{ width: "25%",overflow:"auto" }}>
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title">Matches Phrases</h5>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div>
                                                            <div className="chatbot-summary">
                                                                <div className="chatbot-msg">
                                                                    {clickOnCallLoader && (
                                                                        <div className="loader-container">
                                                                            <div className="position-absolute top-0 start-0 w-100 h-100 d-flex p-5 justify-content-center bg-white align-items-center opacity-75">
                                                                                <div className="spinner-border text-black " role="status" style={{ marginTop: "-70px" }}>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    {
                                                                        callInfo?.length > 0 ?
                                                                            allCalls?.filter(i => i?._id === conversationId)?.map((j, index) => {
                                                                                return j?.diarize_transcript?.map((k, index) => {
                                                                                    return <div onClick={() => [timeUpdate(k.sentence_start, k.sentence_end, allCalls?.filter(i => i?._id === conversationId)[0].diarize_transcript.length, index)]}>
                                                                                        <h5
                                                                                            onClick={() => highLightChatBox(k.sentence_start, index)}
                                                                                            className={currentTime >= k.sentence_start && currentTime <= k.sentence_end ? "transcript-text active" : "transcript-text"}
                                                                                        >
                                                                                            <span style={{ fontWeight: 700 }}>{k?.type} : </span>
                                                                                            <span style={{ textAlign: "justify" }}>
                                                                                                {j?.tag?.map((m) => {
                                                                                                    return m?.tag_added_type === "S" && m?.match_tag?.map((n) => {
                                                                                                        if (k?.text?.includes(n)) {
                                                                                                            const parts = k.text.split(n); // Split the text into parts
                                                                                                            return (
                                                                                                                <span>
                                                                                                                    {parts[0]}
                                                                                                                    <span style={{ color: "green", fontWeight: "600" }}>{n}</span>
                                                                                                                    {parts[1]}
                                                                                                                </span>
                                                                                                            );
                                                                                                        }
                                                                                                        return k.text; // Return the original text for non-matching cases
                                                                                                    });
                                                                                                })}
                                                                                            </span>
                                                                                           
                                                                                        </h5>


                                                                                        <hr />
                                                                                    </div>

                                                                                })
                                                                            })
                                                                            : <p style={{ textAlign: "center", fontWeight: "600", fontSize: "12px" }}>No Transcript Found</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}

                    <div
                      class="modal fade bd-example-modal-sm"
                      tabindex="-1"
                      role="dialog"
                      aria-labelledby="mySmallModalLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-sm">
                        <div class="modal-content"></div>
                      </div>
                    </div>

                    <div className="call-rheader">
                      <div className="form-row">
                        <div className="col-auto">
                          {/* <input type="checkbox" name="allSelect" className="my-2 mx-4" checked={!allCalls.filter(i => i?.transcription_status === "success")?.some((call) => call?.isChecked !== true)} onChange={handleChange} /> */}
                        </div>
                        <div className="col-auto">
                          {/* <a href="javascript:void(0)" data-toggle="modal" data-target=".add-tags-modal" className="btn btn-sm btn-outline-primary">Add Tags</a> */}
                        </div>
                        {/* <div className="col-auto">
                                                    <a href="javascript:void(0)" className="btn btn-sm btn-outline-primary">Export</a>
                                                </div> */}

                        <div
                          class="modal fade csvmodal-modal-sm"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="mySmallModalLabel"
                          aria-hidden="true"
                        >
                          <div
                            class="modal-dialog modal-sm"
                            style={{ width: "20%" }}
                          >
                            <div className="modal-header">
                              Data download in CSV
                            </div>
                            <div
                              class="modal-content"
                              style={{ padding: "30px" }}
                            >
                              Data will be download till Current date month
                              range
                              <CSVLink {...csvReport}>
                                <button
                                  className="btn btn-primary btn-md"
                                  style={{ marginTop: "30px", width: "100%" }}
                                  onClick={() => getCallsForExport("Download")}
                                >
                                  Download
                                  {oneMonthLoading && (
                                    <div
                                      className="spinner-border text-black "
                                      role="status"
                                    ></div>
                                  )}
                                </button>
                              </CSVLink>
                            </div>
                          </div>
                        </div>
                        <div class="dropdown">
                          {exportDataCheck ? (
                            <button
                              className="btn btn-primary dropdown-toggle"
                              style={{
                                height: "25px",
                                paddingTop: "5px",
                                fontSize: "11px",
                              }}
                              type="button"
                              data-toggle="dropdown"
                            >
                              Export
                              <span class="caret"></span>
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary dropdown-toggle"
                              disabled
                              style={{
                                height: "25px",
                                paddingTop: "5px",
                                fontSize: "11px",
                              }}
                              type="button"
                              data-toggle="dropdown"
                            >
                              Export
                              <span class="caret"></span>
                              <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                            </button>
                          )}
                          <ul class="dropdown-menu export-menu">
                            <li>
                              <div className="table-header-group d-inline-flex align-items-center">
                                <div className="export_btns">
                                  <div className="dt-buttons ">
                                    {(timeFilter === "Custom" &&
                                      moment(handleEndTime).diff(
                                        handleStartTime,
                                        "months",
                                        true
                                      ) > 1) ||
                                    timeFilter === "Last 90 Days" ? (
                                      <button
                                        className="dt-button buttons-excel buttons-html5 csv-button"
                                        data-toggle="modal"
                                        data-target=".csvmodal-modal-sm"
                                        tabIndex="0"
                                        aria-controls="template_data"
                                        type="button"
                                      >
                                        <span>CSV</span>
                                      </button>
                                    ) : (
                                      <CSVLink {...csvReport}>
                                        <button
                                          className="dt-button buttons-excel buttons-html5 csv-button"
                                          tabIndex="0"
                                          aria-controls="template_data"
                                          type="button"
                                        >
                                          <span>CSV</span>
                                        </button>
                                      </CSVLink>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="table-header-group d-inline-flex align-items-center">
                                <div className="export_btns">
                                  <div className="dt-buttons">
                                    <button
                                      className="dt-button buttons-excel buttons-html5 pdf-button"
                                      tabIndex="0"
                                      aria-controls="template_data"
                                      type="button"
                                      onClick={exportPDF}
                                    >
                                      <span>PDF</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="table-header-group d-inline-flex align-items-center">
                                <div className="export_btns">
                                  <div className="dt-buttons">
                                    <button
                                      className="dt-button buttons-excel buttons-html5 xls-button"
                                      tabIndex="0"
                                      aria-controls="template_data"
                                      type="button"
                                      onClick={exportXLS}
                                    >
                                      <span>.XLS</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <div className="col-auto ml-auto"></div>
                      </div>
                    </div>
                  </div>
                  {allRecordsLoader ? (
                    <div className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center opacity-75">
                      <div
                        className="loader-dots d-flex align-items-center justify-content-center"
                        role="status"
                      >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  ) : (
                    <div className="box-contents flex-grow-1 px-0">
                      {
                        //allCalls?.filter(i => i?.transcription_status === "success" && moment(i?.datetime).isBetween(moment().subtract(1, 'days'),moment(),null,'[]'))?.map((i, j) => {
                        allCalls?.map((i, j) => {
                          const hasConversationId = i?._id === conversationId; // Check if the current item's _id matches the conversationId
                          const cardClasses = `call-card ${
                            hasConversationId ? "with-border" : ""
                          }`; // Conditionally add the 'with-border' class

                          return (
                            <div className={`call-card ${cardClasses}`}>
                              <div className="form-row justify-content-between flex-nowrap">
                                <div className="col-auto cc-left" key={j}>
                                  {/* <input type="checkbox" className="my-1" name={i.call_id} checked={i?.isChecked || false} onChange={handleChange} /> */}
                                </div>
                                <div className="col-auto flex-grow-1 cc-right">
                                  <div className="row">
                                    <div className="col-md-6 col-lg-6 col-sm-6">
                                      <h4 className="cc-title">
                                        <a
                                          href="javascript:void(0)"
                                          style={{ wordWrap: "break-word" }}
                                          onClick={() => [
                                            getDetailsForCalls(
                                              i?.call_id,
                                              "clickOnCall"
                                            ),
                                            setTranscriptClickStatus(
                                              "CallClicked"
                                            ),
                                            getAppScoreData(i?.call_id),
                                            setConversationId(i?._id),
                                          ]}
                                        >
                                          {i.call_id}
                                        </a>
                                      </h4>
                                    </div>
                                    <div className="col-md-2 col-lg-2 col-sm-2">
                                      <i
                                        className="las la-phone mx-2"
                                        style={{ color: "#088AD2" }}
                                      >
                                        {i?.duration}
                                      </i>
                                    </div>
                                    <div className="col-md-2 col-lg-2 col-sm-2">
                                      <span style={{ fontWeight: "bold" }}>
                                        {moment(i?.datetime).format(
                                          "MMM/Do/YYYY"
                                        )}
                                      </span>
                                    </div>
                                    <div className="col-md-2 col-lg-2 col-sm-2">
                                      {(localStorage.getItem("userRole") ===
                                        "Quality Supervisor" ||
                                        localStorage.getItem("userRole") ===
                                          "Quality Evaluator") && (
                                        <div className="col-auto">
                                          <a
                                            data-toggle="modal"
                                            data-target="#addCallModal"
                                            onClick={() => [
                                              setConversationId(i?._id),
                                              getDetailsForCalls(
                                                i?.call_id,
                                                "clickOnAddCall"
                                              ),
                                            ]}
                                            className="btn btn-sm btn-outline-primary"
                                          >
                                            Add Call
                                          </a>
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  {/* <div className="cc-date d-flex align-items-center justify-content-between">
                                                                    {
                                                                        (localStorage.getItem('userRole') === 'Enterprise Manager' || localStorage.getItem('userRole') === 'Quality Evaluator') &&

                                                                        <div className="col-auto">

                                                                            <a data-toggle="modal" data-target="#addCallModal" onClick={() => [setConversationId(i?._id), getDetailsForCalls(i?._id, "clickOnAddCall"),]} className="btn btn-sm btn-outline-primary">Add Call</a>
                                                                        </div>
                                                                    }
                                                                </div> */}
                                  <div className="cc-contents">
                                    <div className="row">
                                      <div className="col-md-12 col-lg-12 col-sm-12">
                                        <span>
                                          {i?.tag?.length > 2 &&
                                          i?.tag?.some(
                                            (j) => j?.tag_name !== ""
                                          ) ? (
                                            <>
                                              <div className="row">
                                                {i?.tag[0]?.tag_name !== "" && (
                                                  <div
                                                    style={{
                                                      backgroundColor:
                                                        "#0788D1",
                                                      color: "white",
                                                      marginLeft: "16px",
                                                      borderRadius: "100px",
                                                      paddingLeft: "6px",
                                                      paddingRight: "6px",
                                                    }}
                                                  >
                                                    {i?.tag[0]?.tag_name}
                                                  </div>
                                                )}
                                                {i?.tag[1]?.tag_name !== "" && (
                                                  <div
                                                    className="mx-2"
                                                    style={{
                                                      backgroundColor:
                                                        "#0788D1",
                                                      marginLeft: "16px",
                                                      color: "white",
                                                      borderRadius: "100px",
                                                      paddingLeft: "6px",
                                                      paddingRight: "6px",
                                                    }}
                                                  >
                                                    {i?.tag[1]?.tag_name}
                                                  </div>
                                                )}

                                                {/* {i?.applications[0]?.application_name + ", " + i?.applications[1]?.application_name} */}

                                                <div>
                                                  <OverlayPopover
                                                    trigger="click"
                                                    key="bottom"
                                                    placement="bottom"
                                                    rootClose={true}
                                                    overlay={
                                                      <Popover>
                                                        <Popover.Header as="h1">
                                                          All Tags
                                                        </Popover.Header>
                                                        <Popover.Body
                                                          bsPrefix="pop-body"
                                                          as="div"
                                                          style={{
                                                            maxHeight: "200px",
                                                            overflowY: "auto",
                                                          }}
                                                        >
                                                          <div className="col">
                                                            {i?.tag?.map(
                                                              (j, index) => (
                                                                <div
                                                                  style={{
                                                                    fontSize:
                                                                      "12px",
                                                                  }}
                                                                  key={index}
                                                                >
                                                                  {j?.tag_name}
                                                                </div>
                                                              )
                                                            )}
                                                          </div>
                                                        </Popover.Body>
                                                      </Popover>
                                                    }
                                                  >
                                                    <span
                                                      style={{
                                                        color: "#088BD2",
                                                        cursor: "pointer",
                                                        marginTop: "2px",
                                                      }}
                                                      className="search-check-label"
                                                    >
                                                      Show More
                                                    </span>
                                                  </OverlayPopover>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            // Render all items if the length is not greater than 2
                                            <div className="row">
                                              {/* {
                                                                                            Object.values(i?.tag || {}).map((item, index) => (
                                                                                                <div className="" style={{ backgroundColor: "#0788D1", marginLeft: "16px", color: "white", borderRadius: "100px", paddingLeft: "6px", paddingRight: "6px" }}>
                                                                                                    {item?.tag_name}
                                                                                                </div>


                                                                                            ))
                                                                                        } */}

                                              {/* {
                                                                                            Object.values(i?.tag || {}).map((item, index) => (

                                                                                                <div className="" style={{ backgroundColor: "#0788D1", marginLeft: "16px", color: "white", borderRadius: "100px", paddingLeft: "6px", paddingRight: "6px" }}>
                                                                                                    {item?.tag_name}
                                                                                                </div>


                                                                                            ))
                                                                                        } */}
                                              {i?.tag?.map((item, index) => (
                                                <div
                                                  className=""
                                                  style={{
                                                    backgroundColor: "#0788D1",
                                                    marginLeft: "16px",
                                                    color: "white",
                                                    borderRadius: "100px",
                                                    paddingLeft: "6px",
                                                    paddingRight: "6px",
                                                  }}
                                                >
                                                  {item?.tag_name}
                                                </div>
                                              ))}
                                              {/* {
    Object.values(i?.tag || {}).map((item, index) => (
        <div className="" style={{ backgroundColor: "#0788D1", marginLeft: "16px", color: "white", borderRadius: "100px", paddingLeft: "6px", paddingRight: "6px" }}>
            {item?.tag_name}
        </div>
    ))
} */}
                                            </div>
                                          )}
                                        </span>
                                      </div>
                                    </div>

                                    {/* {i?.diarize_transcript && i?.diarize_transcript[0]?.text.slice(0, 100)} */}
                                    {/* <Link data-toggle="modal" data-target="#callModal" onClick={() => setConversationId(i?._id)} ><span className="mx-2" style={{ color: '#007bff' }}>Read more...</span></Link> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      }
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-5 col-lg-5 col-sm-5"></div>
                  <div className="col-md-4 col-lg-4 col-sm-4"></div>
                  <div className="col-md-3 col-lg-3 col-sm-3 my-3">
                    {isDataLength && allCalls?.length > 0 ? (
                      <button
                        onClick={() => handleAllFilter(true, false)}
                        style={{
                          color: "#057FCE",
                          fontWeight: "600",
                          marginLeft: "54px",
                        }}
                      >
                        Show More Data ...
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-5 col-xl-4 vs-right d-flex flex-column">
                <div className="vs-top vs-top2">
                  <div className="ip-box d-flex flex-column p-0">
                    <div
                      className="box-header"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        isMetaCollapse
                          ? setIsMetaCollapse(false)
                          : setIsMetaCollapse(true)
                      }
                    >
                      <h4 className="form-title">
                        Meta Fields <i className="las fa-chevron-down"></i>
                      </h4>
                    </div>

                    {isMetaCollapse ? (
                      <div className="box-contents flex-grow-1">
                        {clickOnCallLoader && (
                          <div className="loader-container ">
                            <div className="position-absolute top-0 start-0 w-100 h-100 d-flex p-5 justify-content-center bg-white align-items-center opacity-75">
                              <div
                                className="spinner-border text-black "
                                role="status"
                              ></div>
                            </div>
                          </div>
                        )}

                        <div
                          className="form-meta"
                          style={{
                            overflowY: "scroll",
                            maxHeight: "45vh",
                            overflowX: "auto",
                          }}
                        >
                          <div
                            className="form-row"
                            style={{ lineHeight: "0px", marginTop: "10px" }}
                          >
                            <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                              <label style={{ fontSize: "11px" }}>
                                <b>Call Id : </b>
                                <span
                                  title={
                                    callInfo[0]?.call_id.length > 40 &&
                                    callInfo[0]?.call_id
                                  }
                                  style={{ fontWeight: "500" }}
                                >
                                  {callInfo[0]?.call_id.length > 40
                                    ? `${callInfo[0]?.call_id.substring(
                                        0,
                                        60
                                      )}...`
                                    : callInfo[0]?.call_id
                                    ? callInfo[0]?.call_id
                                    : "NA"}
                                </span>
                              </label>
                              <hr />
                            </div>
                            {/* <div className="col-sm-6 form-group d-none">
                                                                <label style={{ fontSize: "12px" }}><span>Call Recording Id</span></label>
                                                                <input type="text" className="form-control" value="" />
                                                            </div> */}
                            <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                              <label style={{ fontSize: "11px" }}>
                                <span>
                                  <b>Call Date & Time :</b>{" "}
                                </span>
                                {callInfo[0]?.datetime === "" ||
                                !callInfo[0]?.datetime ? (
                                  <span style={{ fontWeight: "500" }}>NA</span>
                                ) : (
                                  <span
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "11px",
                                    }}
                                  >
                                    {moment(callInfo[0]?.datetime).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </span>
                                )}
                              </label>
                              {/* <input type="text" className="form-control" value="" /> */}
                              <hr />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 form-group duration-group">
                              <label style={{ fontSize: "11px" }}>
                                <b>Call Duration :</b>{" "}
                                {callInfo[0]?.duration === "" ||
                                !callInfo[0]?.duration ? (
                                  <span style={{ fontWeight: "500" }}>NA</span>
                                ) : (
                                  <span style={{ fontWeight: "500" }}>
                                    {callInfo[0]?.duration}
                                  </span>
                                )}
                              </label>
                              <hr />
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                              <label style={{ fontSize: "11px" }}>
                                <b>LOB : </b>
                                {callInfo[0]?.lob === "" ||
                                !callInfo[0]?.lob ? (
                                  <span style={{ fontWeight: "500" }}>NA</span>
                                ) : (
                                  <span style={{ fontWeight: "500" }}>
                                    {callInfo[0]?.lob}
                                  </span>
                                )}
                              </label>
                              <hr />
                            </div>

                            {/* <div className="col-sm-6 form-group">
                                                                <label style={{ fontSize: "12px" }}><b>LOB : </b>{allCalls?.filter(i=>i?.call_id===callInfo[0]?.call_id)[0]?.meta_data?.Lob === "" || !allCalls?.filter(i=>i?.call_id===callInfo[0]?.call_id)[0]?.meta_data?.Lob ? <span style={{fontWeight:"500"}}>NA</span> : <span style={{fontWeight:'500'}}>{allCalls?.filter(i=>i?.call_id===callInfo[0]?.call_id)[0]?.meta_data?.Lob}</span>}</label>
                                                                <hr />
                                                            </div> */}

                            <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                              <label style={{ fontSize: "11px" }}>
                                <b>Campaign : </b>
                                {callInfo[0]?.campaign === "" ||
                                !callInfo[0]?.campaign ? (
                                  <span style={{ fontWeight: "500" }}>NA</span>
                                ) : (
                                  <span style={{ fontWeight: "500" }}>
                                    {
                                      //callInfo[0]?.campaign
                                    }
                                    NA
                                  </span>
                                )}
                              </label>
                              <hr />
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                              <label style={{ fontSize: "11px" }}>
                                <b>Vendor : </b>
                                {callInfo[0]?.vendor === "" ||
                                !callInfo[0]?.vendor ? (
                                  <span style={{ fontWeight: "500" }}>NA</span>
                                ) : (
                                  <span style={{ fontWeight: "500" }}>
                                    {callInfo[0]?.vendor}
                                  </span>
                                )}
                              </label>
                              <hr />
                            </div>

                            {/* <div className="col-sm-6 form-group">
                                                                <label style={{ fontSize: "12px" }}><b>Campaign : </b>{allCalls?.filter(i=>i?.call_id===callInfo[0]?.call_id)[0]?.meta_data?.Campaign === "" || !allCalls?.filter(i=>i?.call_id===callInfo[0]?.call_id)[0]?.meta_data?.Campaign ? <span style={{fontWeight:"500"}}>NA</span> : <span style={{fontWeight:'500'}}>{allCalls?.filter(i=>i?.call_id===callInfo[0]?.call_id)[0]?.meta_data?.Campaign}</span>}</label>
                                                                <hr />
                                                            </div> */}

                            <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                              <label style={{ fontSize: "11px" }}>
                                <b>Location : </b>
                                {callInfo[0]?.location === "" ||
                                !callInfo[0]?.location ? (
                                  <span style={{ fontWeight: "500" }}>NA</span>
                                ) : (
                                  <span style={{ fontWeight: "500" }}>
                                    {callInfo[0]?.location}
                                  </span>
                                )}
                              </label>
                              <hr />
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                              <label style={{ fontSize: "11px" }}>
                                <b>Evaluator Id : </b>
                                {callInfo[0]?.evaluator_id === "" ||
                                !callInfo[0]?.evaluator_id ? (
                                  <span style={{ fontWeight: "500" }}>NA</span>
                                ) : (
                                  <span style={{ fontWeight: "500" }}>
                                    {callInfo[0]?.evaluator_id}
                                  </span>
                                )}
                              </label>
                              <hr />
                              {/* <input type="text" className="form-control" value={callInfo[0]?.evaluator_id} /> */}
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                              <label style={{ fontSize: "11px" }}>
                                <b>Agent Id : </b>
                                {callInfo[0]?.agent_id === "" ||
                                !callInfo[0]?.agent_id ? (
                                  <span style={{ fontWeight: "500" }}>NA</span>
                                ) : (
                                  <span style={{ fontWeight: "500" }}>
                                    {callInfo[0]?.agent_id}
                                  </span>
                                )}
                              </label>

                              <hr />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                              <label style={{ fontSize: "11px" }}>
                                <b>Agent Name : </b>
                                {callInfo[0]?.agent_name === "" ||
                                !callInfo[0]?.agent_name ? (
                                  <span style={{ fontWeight: "500" }}>NA</span>
                                ) : (
                                  <span style={{ fontWeight: "500" }}>
                                    {callInfo[0]?.agent_name}
                                  </span>
                                )}
                              </label>
                              <hr />
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                              <label style={{ fontSize: "11px" }}>
                                <b>Supervisor Id : </b>
                                {callInfo[0]?.supervisorid === "" ||
                                !callInfo[0]?.supervisorid ? (
                                  <span style={{ fontWeight: "500" }}>NA</span>
                                ) : (
                                  <span style={{ fontWeight: "500" }}>
                                    {callInfo[0]?.supervisorid}
                                  </span>
                                )}
                              </label>
                              <hr />
                              {/* <input type="text" className="form-control" value={callInfo[0]?.supervisorid} /> */}
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                              <label style={{ fontSize: "11px" }}>
                                <b>Supervisor Name : </b>
                                {callInfo[0]?.supervisorname === "" ||
                                !callInfo[0]?.supervisorname ? (
                                  <span style={{ fontWeight: "500" }}>NA</span>
                                ) : (
                                  <span style={{ fontWeight: "500" }}>
                                    {callInfo[0]?.supervisorname}
                                  </span>
                                )}
                              </label>
                              {/* <input type="text" className="form-control" value={callInfo[0]?.supervisorname} /> */}
                              <hr />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                              <label style={{ fontSize: "11px" }}>
                                <b>Ref No : </b>
                                {callInfo[0]?.meta_data?.refno === "" ||
                                !callInfo[0]?.meta_data?.refno ? (
                                  <span style={{ fontWeight: "500" }}>NA</span>
                                ) : (
                                  <span style={{ fontWeight: "500" }}>
                                    {callInfo[0]?.meta_data?.refno}
                                  </span>
                                )}
                              </label>
                              {/* <input type="text" className="form-control" value={callInfo[0]?.supervisorname} /> */}
                              <hr />
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                              <label style={{ fontSize: "11px" }}>
                                <b>Borrower Name : </b>
                                {callInfo[0]?.meta_data?.FullName === "" ||
                                !callInfo[0]?.meta_data?.FullName ? (
                                  <span style={{ fontWeight: "500" }}>NA</span>
                                ) : (
                                  <span style={{ fontWeight: "500" }}>
                                    {callInfo[0]?.meta_data?.FullName}
                                  </span>
                                )}
                              </label>
                              {/* <input type="text" className="form-control" value={callInfo[0]?.supervisorname} /> */}
                              <hr />
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                              <label style={{ fontSize: "11px" }}>
                                <b>Service : </b>
                                {callInfo[0]?.meta_data?.other6 === "" ||
                                !callInfo[0]?.meta_data?.other6 ? (
                                  <span style={{ fontWeight: "500" }}>NA</span>
                                ) : (
                                  <span style={{ fontWeight: "500" }}>
                                    {callInfo[0]?.meta_data?.other6}
                                  </span>
                                )}
                              </label>
                              {/* <input type="text" className="form-control" value={callInfo[0]?.supervisorname} /> */}
                              <hr />
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                              <label style={{ fontSize: "11px" }}>
                                <b>Agent Username : </b>
                                {callInfo[0]?.meta_data?.AgentName === "" ||
                                !callInfo[0]?.meta_data?.AgentName ? (
                                  <span style={{ fontWeight: "500" }}>NA</span>
                                ) : (
                                  <span style={{ fontWeight: "500" }}>
                                    {callInfo[0]?.meta_data?.AgentName}
                                  </span>
                                )}
                              </label>
                              {/* <input type="text" className="form-control" value={callInfo[0]?.supervisorname} /> */}
                              <hr />
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                              <label style={{ fontSize: "11px" }}>
                                <b>Customer response : </b>
                                {callInfo[0]?.meta_data?.Customerresponse ===
                                  "" ||
                                !callInfo[0]?.meta_data?.Customerresponse ? (
                                  <span style={{ fontWeight: "500" }}>NA</span>
                                ) : (
                                  <span style={{ fontWeight: "500" }}>
                                    {callInfo[0]?.meta_data?.Customerresponse}
                                  </span>
                                )}
                              </label>
                              {/* <input type="text" className="form-control" value={callInfo[0]?.supervisorname} /> */}
                              <hr />
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                              <label style={{ fontSize: "11px" }}>
                                <b>Client Call ID : </b>
                                {callInfo[0]?.meta_data?.CallID === "" ||
                                !callInfo[0]?.meta_data?.CallID ? (
                                  <span style={{ fontWeight: "500" }}>NA</span>
                                ) : (
                                  <span style={{ fontWeight: "500" }}>
                                    {callInfo[0]?.meta_data?.CallID}
                                  </span>
                                )}
                              </label>
                              {/* <input type="text" className="form-control" value={callInfo[0]?.supervisorname} /> */}
                              <hr />
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                              <label style={{ fontSize: "11px" }}>
                                <b>Hunt Group : </b>
                                {callInfo[0]?.meta_data?.HuntGroup === "" ||
                                !callInfo[0]?.meta_data?.HuntGroup ? (
                                  <span style={{ fontWeight: "500" }}>NA</span>
                                ) : (
                                  <span style={{ fontWeight: "500" }}>
                                    {callInfo[0]?.meta_data?.HuntGroup}
                                  </span>
                                )}
                              </label>
                              {/* <input type="text" className="form-control" value={callInfo[0]?.supervisorname} /> */}
                              <hr />
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                              <label style={{ fontSize: "11px" }}>
                                <b>Dialled Number : </b>
                                {(callInfo[0]?.meta_data?.DialledNumber ===
                                  "" &&
                                  !callInfo[0]?.meta_data?.DialledNumber) ||
                                (callInfo[0]?.meta_data?.DialedNumber === "" &&
                                  !callInfo[0]?.meta_data?.DialedNumber) ? (
                                  <span style={{ fontWeight: "500" }}>NA</span>
                                ) : (
                                  <span style={{ fontWeight: "500" }}>
                                    {callInfo[0]?.meta_data?.DialedNumber
                                      ? callInfo[0]?.meta_data?.DialedNumber
                                      : callInfo[0]?.meta_data?.DialledNumber}
                                  </span>
                                )}
                              </label>
                              {/* <input type="text" className="form-control" value={callInfo[0]?.supervisorname} /> */}
                              <hr />
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                              <label style={{ fontSize: "11px" }}>
                                <b>Duration : </b>
                                {callInfo[0]?.meta_data?.Duration === "" ||
                                !callInfo[0]?.meta_data?.Duration ? (
                                  <span style={{ fontWeight: "500" }}>NA</span>
                                ) : (
                                  <span style={{ fontWeight: "500" }}>
                                    {callInfo[0]?.meta_data?.Duration}
                                  </span>
                                )}
                              </label>
                              {/* <input type="text" className="form-control" value={callInfo[0]?.supervisorname} /> */}
                              <hr />
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                              <label style={{ fontSize: "11px" }}>
                                <b>Meta Call Date : </b>
                                {callInfo[0]?.meta_data?.CallDate === "" ||
                                !callInfo[0]?.meta_data?.CallDate ? (
                                  <span style={{ fontWeight: "500" }}>NA</span>
                                ) : (
                                  <span style={{ fontWeight: "500" }}>
                                    {callInfo[0]?.meta_data?.CallDate}
                                  </span>
                                )}
                              </label>
                              {/* <input type="text" className="form-control" value={callInfo[0]?.supervisorname} /> */}
                              <hr />
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                              <label style={{ fontSize: "11px" }}>
                                <b>Meta Call Time : </b>
                                {callInfo[0]?.meta_data?.CallTime === "" ||
                                !callInfo[0]?.meta_data?.CallTime ? (
                                  <span style={{ fontWeight: "500" }}>NA</span>
                                ) : (
                                  <span style={{ fontWeight: "500" }}>
                                    {callInfo[0]?.meta_data?.CallTime}
                                  </span>
                                )}
                              </label>
                              {/* <input type="text" className="form-control" value={callInfo[0]?.supervisorname} /> */}
                              <hr />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                              <label style={{ fontSize: "11px" }}>
                                <b>Cost Center ID : </b>
                                {callInfo[0]?.meta_data?.CostCenterID === "" ||
                                !callInfo[0]?.meta_data?.CostCenterID ? (
                                  <span style={{ fontWeight: "500" }}>NA</span>
                                ) : (
                                  <span style={{ fontWeight: "500" }}>
                                    {callInfo[0]?.meta_data?.CostCenterID}
                                  </span>
                                )}
                              </label>
                              {/* <input type="text" className="form-control" value={callInfo[0]?.supervisorname} /> */}
                              <hr />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                              <label style={{ fontSize: "11px" }}>
                                <b>Cost Center Name : </b>
                                {callInfo[0]?.meta_data?.CostCenterName ===
                                  "" ||
                                !callInfo[0]?.meta_data?.CostCenterName ? (
                                  <span style={{ fontWeight: "500" }}>NA</span>
                                ) : (
                                  <span style={{ fontWeight: "500" }}>
                                    {callInfo[0]?.meta_data?.CostCenterName}
                                  </span>
                                )}
                              </label>
                              {/* <input type="text" className="form-control" value={callInfo[0]?.supervisorname} /> */}
                              <hr />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                              <label style={{ fontSize: "11px" }}>
                                <b>Client Location : </b>
                                {callInfo[0]?.meta_data?.Location === "" ||
                                !callInfo[0]?.meta_data?.Location ? (
                                  <span style={{ fontWeight: "500" }}>NA</span>
                                ) : (
                                  <span style={{ fontWeight: "500" }}>
                                    {callInfo[0]?.meta_data?.Location}
                                  </span>
                                )}
                              </label>
                              {/* <input type="text" className="form-control" value={callInfo[0]?.supervisorname} /> */}
                              <hr />
                            </div>
                            {window.location.hostname ==
                              "mcap-tcn.neqqo.com" && (
                              <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                                <label style={{ fontSize: "11px" }}>
                                  <b>CLDBNO : </b>
                                  {callInfo[0]?.meta_data?.CLDBNO === "" ||
                                  !callInfo[0]?.meta_data?.CLDBNO ? (
                                    <span style={{ fontWeight: "500" }}>
                                      NA
                                    </span>
                                  ) : (
                                    <span style={{ fontWeight: "500" }}>
                                      {callInfo[0]?.meta_data?.CLDBNO}
                                    </span>
                                  )}
                                </label>
                                {/* <input type="text" className="form-control" value={callInfo[0]?.supervisorname} /> */}
                                <hr />
                              </div>
                            )}

                            {window.location.host?.split(".")[0] ===
                              "recruitment" && (
                              <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                                <label style={{ fontSize: "11px" }}>
                                  <b>Callee Number : </b>
                                  {callInfo[0]?.meta_data?.callee_number ===
                                    "" ||
                                  !callInfo[0]?.meta_data?.callee_number ? (
                                    <span style={{ fontWeight: "500" }}>
                                      NA
                                    </span>
                                  ) : (
                                    <span style={{ fontWeight: "500" }}>
                                      {callInfo[0]?.meta_data?.callee_number}
                                    </span>
                                  )}
                                </label>
                                {/* <input type="text" className="form-control" value={callInfo[0]?.supervisorname} /> */}
                                <hr />
                              </div>
                            )}

                            {window.location.host?.split(".")[0] ===
                              "recruitment" && (
                              <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                                <label style={{ fontSize: "11px" }}>
                                  <b>Caller Name : </b>
                                  {callInfo[0]?.meta_data?.caller_name === "" ||
                                  !callInfo[0]?.meta_data?.caller_name ? (
                                    <span style={{ fontWeight: "500" }}>
                                      NA
                                    </span>
                                  ) : (
                                    <span style={{ fontWeight: "500" }}>
                                      {callInfo[0]?.meta_data?.caller_name}
                                    </span>
                                  )}
                                </label>
                                {/* <input type="text" className="form-control" value={callInfo[0]?.supervisorname} /> */}
                                <hr />
                              </div>
                            )}

                            {window.location.host?.split(".")[0] ===
                              "recruitment" && (
                              <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                                <label style={{ fontSize: "11px" }}>
                                  <b>Direction : </b>
                                  {callInfo[0]?.meta_data?.direction === "" ||
                                  !callInfo[0]?.meta_data?.direction ? (
                                    <span style={{ fontWeight: "500" }}>
                                      NA
                                    </span>
                                  ) : (
                                    <span style={{ fontWeight: "500" }}>
                                      {callInfo[0]?.meta_data?.direction}
                                    </span>
                                  )}
                                </label>
                                {/* <input type="text" className="form-control" value={callInfo[0]?.supervisorname} /> */}
                                <hr />
                              </div>
                            )}

                            {window.location.host?.split(".")[0] ===
                              "recruitment" && (
                              <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                                <label style={{ fontSize: "11px" }}>
                                  <b>Owner Extension Number : </b>
                                  {callInfo[0]?.meta_data
                                    ?.owner_extension_number === "" ||
                                  !callInfo[0]?.meta_data
                                    ?.owner_extension_number ? (
                                    <span style={{ fontWeight: "500" }}>
                                      NA
                                    </span>
                                  ) : (
                                    <span style={{ fontWeight: "500" }}>
                                      {
                                        callInfo[0]?.meta_data
                                          ?.owner_extension_number
                                      }
                                    </span>
                                  )}
                                </label>
                                {/* <input type="text" className="form-control" value={callInfo[0]?.supervisorname} /> */}
                                <hr />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div
                  className="vs-bottom"
                  style={{ height: isMetaCollapse ? "33vh" : "75vh" }}
                >
                  <div className="ip-box d-flex flex-column p-0">
                    <div className="box-header">
                      <div
                        className="player-outer"
                        style={{ position: "relative" }}
                      >
                        <audio
                          controlsList="nodownload"
                          id="sf2"
                          onTimeUpdate={onTimeUpdate}
                          ref={audioRef}
                          style={{
                            width: "110%",
                            height: "50px",
                            marginTop: "-20px",
                            marginLeft: "-24px",
                          }}
                          src={
                            callInfo[0]?.s3bucketpath
                              ? callInfo[0]?.s3bucketpath
                              : ""
                          }
                          preload="auto"
                          type="audio/mp3"
                          controls
                        >
                          Your browser does not support the audio element.
                        </audio>

                        <>
                          <button
                            onClick={jumpBackward}
                            style={{
                              position: "absolute",
                              marginLeft: "7px",
                              left: "0",
                              top: "35%",
                              transform: "translateY(-50%)",
                            }}
                          >
                            <i
                              className="las fa-backward"
                              style={{ fontSize: "15px" }}
                            ></i>
                          </button>
                          <button
                            onClick={jumpForward}
                            style={{
                              position: "absolute",
                              marginLeft: "50px",
                              left: "0",
                              top: "35%",
                              transform: "translateY(-50%)",
                            }}
                          >
                            <i
                              className="las fa-forward"
                              disabled={
                                callInfo[0]?.s3bucketpath ? false : true
                              }
                              style={{ fontSize: "15px", fontWeight: "bold" }}
                            ></i>
                          </button>
                        </>
                      </div>
                    </div>
                    <div className="box-contents flex-grow-1">
                      <div className="tt-area">
                        <ul
                          className="nav nav-tabs tc-tabs"
                          id="transcriptsTabs"
                          role="tablist"
                        >
                          {!isActive ? (
                            <>
                              <li className="nav-item" role="presentation">
                                <a
                                  className="nav-link active"
                                  id="overview-tab"
                                  onClick={() => [
                                    setOverview("Overview"),
                                    setTranscriptClickStatus(
                                      "OverviewedClicked"
                                    ),
                                  ]}
                                  data-toggle="tab"
                                  href="#overview"
                                  role="tab"
                                  aria-controls="overview"
                                  aria-selected="ture"
                                >
                                  Overview
                                </a>
                              </li>
                              <li className="nav-item" role="presentation">
                                <a
                                  className="nav-link"
                                  id="transcript-tab"
                                  data-toggle="tab"
                                  onClick={() => [
                                    setOverview("Transcript"),
                                    setTranscriptClickStatus(
                                      "TranscriptClicked"
                                    ),
                                  ]}
                                  href="#transcript"
                                  role="tab"
                                  aria-controls="transcript"
                                  aria-selected="false"
                                >
                                  Transcript
                                </a>
                              </li>
                            </>
                          ) : (
                            <>
                              <li className="nav-item" role="presentation">
                                <a
                                  className="nav-link"
                                  id="overview-tab"
                                  onClick={() => setOverview("Overview")}
                                  data-toggle="tab"
                                  href="#overview"
                                  role="tab"
                                  aria-controls="overview"
                                  aria-selected="ture"
                                >
                                  Overview
                                </a>
                              </li>
                              <li className="nav-item" role="presentation">
                                <a
                                  className="nav-link active"
                                  id="transcript-tab"
                                  data-toggle="tab"
                                  onClick={() => setOverview("Transcript")}
                                  href="#transcript"
                                  role="tab"
                                  aria-controls="transcript"
                                  aria-selected="false"
                                >
                                  Transcript
                                </a>
                              </li>
                            </>
                          )}
                          <li className="nav-item" role="presentation">
                            {/* <a className="nav-link" id="transcript-tab" data-toggle="tab" href="#" role="tab" aria-controls="transcript" aria-selected="false">Export</a> */}
                            <OverlayPopover
                              trigger="click"
                              key="bottom"
                              placement="bottom"
                              rootClose={true}
                              overlay={
                                <Popover>
                                  <Popover.Header as="h1">
                                    Download
                                  </Popover.Header>
                                  <Popover.Body bsPrefix="pop-body" as="div">
                                    <div className="transcript-download my-4">
                                      <a
                                        href={callInfo?.[0]?.s3bucketpath}
                                        download
                                        className="d-link"
                                      >
                                        Mp3
                                      </a>
                                      {/* <a href="#" className="d-link">Json</a> */}
                                      <a
                                        onClick={() =>
                                          getJSONRes(callInfo[0]?.call_id)
                                        }
                                        className="d-link"
                                        id="json"
                                        type="button"
                                        role="tab"
                                        aria-controls="json"
                                        aria-selected="false"
                                      >
                                        JSON
                                      </a>
                                      <a
                                        onClick={() =>
                                          getTextRes(callInfo[0]?.call_id)
                                        }
                                        className="d-link"
                                        id="text"
                                        type="button"
                                        role="tab"
                                        aria-controls="text"
                                        aria-selected="false"
                                      >
                                        Text
                                      </a>
                                    </div>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {/* <i style={{ fontSize: 20 }} className="las la-trash">call duration</i> */}
                              <div className="col searchbox-check">
                                {/* <span className="search-check-label nav-link" style={{ paddingBottom: "10px", height: "28px", paddingTop: "5px", marginLeft: "-15px" }}>Export</span> */}
                              </div>
                            </OverlayPopover>
                          </li>
                        </ul>
                        <div
                          className="tab-content"
                          id="transcriptsTabsContents"
                        >
                          <div
                            className="tab-pane fade show active"
                            id="overview"
                            role="tabpanel"
                            aria-labelledby="overview-tab"
                          >
                            <div className="d-flex align-item-center">
                              {/* <div className="ip-switch">
                                                                <h5 className="ip-switch-label">Show Emotion</h5>
                                                                <div className="ip-switch-control">
                                                                    <label className="switch-text">Off</label>
                                                                    <button type="button" className="btn-switch attribute-switch" data-ref="escalation"></button>
                                                                    <label className="switch-text">On</label>
                                                                </div>
                                                            </div>
                                                            <div className="ip-switch">
                                                                <h5 className="ip-switch-label">Skip Silence</h5>
                                                                <div className="ip-switch-control">
                                                                    <label className="switch-text">Off</label>
                                                                    <button type="button" className="btn-switch attribute-switch" data-ref="opportunity"></button>
                                                                    <label className="switch-text">On</label>
                                                                </div>
                                                            </div> */}
                            </div>
                            {/* <div className="transcript-download my-4">
                                                            <h5 className="ip-switch-label">Download</h5>
                                                            <a href="#" className="d-link">Mp3</a>
                                                            <a href="#" className="d-link">Json</a>
                                                            <a href="#" className="d-link">Text</a>
                                                        </div> */}
                            <div className="overview-tabs">
                              <ul
                                className="nav nav-tabs ov-tabs"
                                id="overviewTabs"
                                role="tablist"
                              >
                                <li className="nav-item" role="presentation">
                                  <a
                                    className="nav-link active"
                                    id="file-tab"
                                    data-toggle="tab"
                                    href="#file"
                                    role="tab"
                                    aria-controls="file"
                                    aria-selected="ture"
                                  >
                                    File Details
                                  </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                  <a
                                    className="nav-link"
                                    id="application-tab"
                                    data-toggle="tab"
                                    href="#application"
                                    role="tab"
                                    aria-controls="application"
                                    aria-selected="false"
                                  >
                                    Tags
                                  </a>
                                </li>

                                {/* <li className="nav-item" role="presentation">
                                                                    <a className="nav-link" id="tags-tab" data-toggle="tab" href="#tags" role="tab" aria-controls="tags" aria-selected="false">Tags</a>
                                                                </li> */}
                              </ul>
                              <div
                                className="tab-content"
                                id="overviewTabsContents"
                              >
                                {clickOnCallLoader && (
                                  <div className="loader-container ">
                                    <div className="position-absolute top-0 start-0 w-100 h-100 d-flex p-5 justify-content-center bg-white align-items-center opacity-75">
                                      <div
                                        className="spinner-border text-black "
                                        role="status"
                                        style={{ marginTop: "-140px" }}
                                      ></div>
                                    </div>
                                  </div>
                                )}
                                <div
                                  className="tab-pane fade show active"
                                  id="file"
                                  role="tabpanel"
                                  aria-labelledby="file-tab"
                                >
                                  {callInfo?.length > 0 ? (
                                    <div className="row">
                                      <div className="col-sm-6">
                                        <h5
                                          className="mx-4 my-3"
                                          style={{ fontSize: "11px" }}
                                        >
                                          <b>Agent Emotion : &nbsp; &nbsp;</b>
                                          {callInfo[0]?.agent_emotion === "" ||
                                          !callInfo[0]?.agent_emotion
                                            ? "NA"
                                            : callInfo[0]?.agent_emotion}
                                        </h5>
                                        <hr />
                                      </div>
                                      <div className="col-sm-6">
                                        <h5
                                          className="mx-2 my-3"
                                          style={{ fontSize: "11px" }}
                                        >
                                          <b>Client Emotion : &nbsp;&nbsp;</b>
                                          {callInfo[0]?.client_emotion === "" ||
                                          !callInfo[0]?.client_emotion
                                            ? "NA"
                                            : callInfo[0]?.client_emotion}
                                        </h5>
                                        <hr />
                                      </div>
                                      <div className="col-sm-6">
                                        <h5
                                          className="mx-4"
                                          style={{ fontSize: "11px" }}
                                        >
                                          <b>Agent Gender : &nbsp; &nbsp;</b>
                                          {callInfo[0]?.agent_gender === "" ||
                                          !callInfo[0]?.agent_gender
                                            ? "NA"
                                            : callInfo[0]?.agent_gender
                                                .charAt(0)
                                                .toUpperCase() +
                                              callInfo[0]?.agent_gender.slice(
                                                1
                                              )}
                                        </h5>
                                        <hr />
                                      </div>
                                      <div className="col-sm-6">
                                        <h5
                                          className="mx-2"
                                          style={{ fontSize: "11px" }}
                                        >
                                          <b>Client Gender : &nbsp; &nbsp;</b>
                                          {callInfo[0]?.client_gender === "" ||
                                          !callInfo[0]?.client_gender
                                            ? "NA"
                                            : callInfo[0]?.client_gender
                                                .charAt(0)
                                                .toUpperCase() +
                                              callInfo[0]?.client_gender.slice(
                                                1
                                              )}
                                        </h5>
                                        <hr />
                                      </div>
                                      <div className="col-sm-6">
                                        <h5
                                          className="my-2 mx-4"
                                          style={{ fontSize: "11px" }}
                                        >
                                          <b>Overall Emotion : &nbsp; &nbsp;</b>
                                          {callInfo[0]?.overall_emotion ===
                                            "" || !callInfo[0]?.overall_emotion
                                            ? "NA"
                                            : callInfo[0]?.overall_emotion}
                                        </h5>
                                      </div>
                                    </div>
                                  ) : (
                                    <p
                                      style={{
                                        textAlign: "center",
                                        marginTop: "20px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      No File Details Found
                                    </p>
                                  )}
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="application"
                                  role="tabpanel"
                                  aria-labelledby="application-tab"
                                >
                                  <div
                                    className="accordion app-score-accordion"
                                    id="parent_accordion"
                                  >
                                    {appScoreData?.length > 0 ? (
                                      appScoreData.map((app, index) => {
                                        return (
                                          <div className="card">
                                            <div
                                              className="card-header"
                                              id={`head_${app.tag_id}`}
                                            >
                                              <h2 className="mb-0">
                                                <i
                                                  style={{ fontSize: "15px" }}
                                                  className="las la-headset mx-1 transcript-link"
                                                  id="transcript-tab"
                                                  data-toggle="tab"
                                                  //onClick={() => setOverview("Transcript")}
                                                  href="#transcript"
                                                  role="tab"
                                                  aria-controls="transcript"
                                                  aria-selected="false"
                                                  //data-toggle="modal"
                                                  //data-target=".matches-phrases-modal"
                                                  onClick={() => [
                                                    addClick(app?.tag_name),
                                                    setIsActive(true),
                                                    setTranscriptClickStatus(
                                                      "OverviewedClicked"
                                                    ),
                                                    setTagNameForTrans(
                                                      app?.tag_name
                                                    ),
                                                  ]}
                                                ></i>
                                                <button
                                                  className="btn btn-link btn-block text-left"
                                                  style={{
                                                    marginTop: "-25px",
                                                    backgroundColor:
                                                      storeAllTags.some(
                                                        (tag) =>
                                                          tag.label ===
                                                          app.tag_name
                                                      )
                                                        ? "#80c904"
                                                        : "#ccc",
                                                    color: storeAllTags.some(
                                                      (tag) =>
                                                        tag?.label ===
                                                        app?.tag_name
                                                    )
                                                      ? "white"
                                                      : "black",
                                                  }}
                                                  type="button"
                                                  //data-toggle="collapse" data-target={`#app_${app.tag_id}`} aria-expanded="true" aria-controls={`app_${app.tag_id}`}
                                                >
                                                  {app.tag_name}
                                                </button>
                                              </h2>
                                            </div>

                                            {/* <div id={`app_${app.app_id}`} className="collapse" aria-labelledby={`head_${app.app_id}`} data-parent="#parent_accordion">
                                                                                            <div className="card-body">
                                                                                                <div className="accordion" id="cat_accordion">
                                                                                                    {app.category.map(cat => {
                                                                                                        return (
                                                                                                            <div className="card">
                                                                                                                <div className="card-header" id={`head_${cat.cat_id}`}>
                                                                                                                    <h2 className="mb-0">
                                                                                                                        <button className="btn btn-link btn-block text-left collapsed" style={{
                                                                                                                            backgroundColor: storeAllTags.some((tag) => tag.label === app.tag_name) ? '#80c904' : '#ccc',
                                                                                                                            color: storeAllTags.some((tag) => tag?.label === app?.tag_name) ? 'white' : 'black',
                                                                                                                        }} type="button" data-toggle="collapse" data-target={`#cat_${cat.cat_id}`} aria-expanded="true" aria-controls={`cat_${cat.cat_id}`}>
                                                                                                                            {cat?.cat_name} <span className="badge badge-pill badge-primary">{cat.phrase_count_percentage}</span>
                                                                                                                        </button>
                                                                                                                    </h2>
                                                                                                                </div>

                                                                                                                <div id={`cat_${cat?.cat_id}`} className="collapse" aria-labelledby={`head_${cat?.cat_id}`} data-parent="#cat_accordion">
                                                                                                                    <div className="card-body">
                                                                                                                        {cat?.sub_category?.map(subcat => {
                                                                                                                            return (
                                                                                                                                <div style={{ marginTop: 5 }}>
                                                                                                                                    <li style={{
                                                                                                                                        backgroundColor: storeAllTags.some((tag) => tag.label === app.app_name) ? '#80c904' : '#ccc',
                                                                                                                                        color: storeAllTags.some((tag) => tag?.label === app?.app_name) ? 'white' : 'black', padding: "4px"
                                                                                                                                    }}>{`${subcat.sub_cat_name} : ${subcat.sub_cat_wise_phrase_count}`}</li>
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        })}

                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )
                                                                                                    })
                                                                                                    }
                                                                                                </div>
                                                                                            </div>

                                                                                        </div> */}
                                          </div>
                                        );
                                      })
                                    ) : (
                                      <p
                                        style={{
                                          textAlign: "center",
                                          marginTop: "20px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        No Tags Found
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="tags"
                                  role="tabpanel"
                                  aria-labelledby="tags-tab"
                                >
                                  <div className="row">
                                    <div className="col-md-8 col-lg-8 col-xl-8">
                                      <input
                                        type="text"
                                        className="form-control"
                                        style={{ height: "27px" }}
                                        value={callTags}
                                        onInput={(e) =>
                                          setCallTags(e.target.value)
                                        }
                                        placeholder="Enter Tags"
                                      />
                                    </div>
                                    <div className="col-md-4 col-lg-3 col-xl-2">
                                      <ButtonComp
                                        className="add-tag-btn-2"
                                        onClick={addCallTags}
                                        rounded
                                        outline
                                        disabled={callTags ? false : true}
                                      >
                                        <span>
                                          <i className="las la-plus la-2x"></i>
                                        </span>
                                      </ButtonComp>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="transcript"
                            role="tabpanel"
                            aria-labelledby="transcript-tab"
                          >
                            {/* <div className={`tab-pane fade ${transcriptTabVisible ? 'show active' : ''}`} id="transcript" role="tabpanel" aria-labelledby="transcript-tab"> */}
                            <div className="chatbot-summary">
                              <div className="chatbot-msg">
                                {clickOnCallLoader && (
                                  <div className="loader-container">
                                    <div className="position-absolute top-0 start-0 w-100 h-100 d-flex p-5 justify-content-center bg-white align-items-center opacity-75">
                                      <div
                                        className="spinner-border text-black "
                                        role="status"
                                        style={{ marginTop: "-70px" }}
                                      ></div>
                                    </div>
                                  </div>
                                )}
                                {console.log(
                                  "filterShowPhrase",
                                  filterShowPhrase
                                )}
                                {callInfo?.length > 0 ? (
                                  // Assuming you have an outer loop like this
                                  callInfo?.map((j, jIndex) => {
                                    return j?.diarize_transcript?.map(
                                      (k, kIndex) => {
                                        const matchArray = j?.tag?.filter(
                                          (tag) => {
                                            return storeAllTags?.some(
                                              (storeTag) =>
                                                storeTag.label === tag.tag_name
                                            );
                                          }
                                        );

                                        const data = j?.tag?.filter((tag) => {
                                          return storeAllTags?.some(
                                            (storeTag) =>
                                              storeTag.label === tag.tag_name
                                          );
                                        });

                                        if (
                                          transcriptClickStatus !==
                                          "TranscriptClicked"
                                        ) {
                                          return (
                                            <div
                                              key={kIndex}
                                              onClick={() =>
                                                timeUpdate(
                                                  k.sentence_start,
                                                  k.sentence_end,
                                                  allCalls?.filter(
                                                    (i) =>
                                                      i?._id === conversationId
                                                  )[0].diarize_transcript
                                                    ?.length,
                                                  kIndex
                                                )
                                              }
                                            >
                                              <h5
                                                onClick={() =>
                                                  highLightChatBox(
                                                    k.sentence_start,
                                                    kIndex
                                                  )
                                                }
                                                className={
                                                  currentTime >=
                                                    k.sentence_start &&
                                                  currentTime <= k.sentence_end
                                                    ? "transcript-text active"
                                                    : "transcript-text"
                                                }
                                              >
                                                <span
                                                  style={{ fontWeight: 700 }}
                                                >
                                                  {k?.type} :{" "}
                                                </span>
                                                <span
                                                  style={{
                                                    textAlign: "justify",
                                                  }}
                                                  dangerouslySetInnerHTML={{
                                                    __html: (() => {
                                                      let highlightedText =
                                                        k.text;
                                                      j?.tag
                                                        ?.filter(
                                                          (i) =>
                                                            i?.tag_name ===
                                                            tagNameForTrans
                                                        )
                                                        ?.forEach((i) => {
                                                          i?.match_tag?.forEach(
                                                            (j) => {
                                                              const regex =
                                                                new RegExp(
                                                                  `\\b(${j})\\b`,
                                                                  "gi"
                                                                );
                                                              highlightedText =
                                                                highlightedText.replace(
                                                                  regex,
                                                                  '<span style="background-color: yellow; font-weight: bold;">$1</span>'
                                                                );
                                                            }
                                                          );
                                                        });
                                                      return highlightedText;
                                                    })(),
                                                  }}
                                                ></span>
                                              </h5>
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div
                                              key={kIndex}
                                              onClick={() =>
                                                timeUpdate(
                                                  k.sentence_start,
                                                  k.sentence_end,
                                                  allCalls?.filter(
                                                    (i) =>
                                                      i?._id === conversationId
                                                  )[0].diarize_transcript
                                                    ?.length,
                                                  kIndex
                                                )
                                              }
                                            >
                                              <h5
                                                onClick={() =>
                                                  highLightChatBox(
                                                    k.sentence_start,
                                                    kIndex
                                                  )
                                                }
                                                className={
                                                  currentTime >=
                                                    k.sentence_start &&
                                                  currentTime <= k.sentence_end
                                                    ? "transcript-text active"
                                                    : "transcript-text"
                                                }
                                              >
                                                <span
                                                  style={{ fontWeight: 700 }}
                                                >
                                                  {k?.type} :{" "}
                                                </span>
                                                <span
                                                  style={{
                                                    textAlign: "justify",
                                                  }}
                                                  dangerouslySetInnerHTML={{
                                                    __html: (() => {
                                                      let highlightedText =
                                                        k.text;

                                                      // Iterate through tags and match tags
                                                      j?.tag
                                                        ?.filter((tagItem) =>
                                                          storeAllTags.some(
                                                            (storedTag) =>
                                                              storedTag.label ===
                                                              tagItem.tag_name
                                                          )
                                                        )
                                                        ?.forEach((tagItem) => {
                                                          tagItem?.match_tag?.forEach(
                                                            (match) => {
                                                              const regex =
                                                                new RegExp(
                                                                  `\\b(${match})\\b`,
                                                                  "gi"
                                                                );
                                                              highlightedText =
                                                                highlightedText.replace(
                                                                  regex,
                                                                  '<span style="background-color: yellow; font-weight: bold;">$1</span>'
                                                                );
                                                            }
                                                          );
                                                        });

                                                      return highlightedText;
                                                    })(),
                                                  }}
                                                ></span>
                                              </h5>
                                            </div>
                                          );
                                        }
                                        return;
                                        console.log("matchArray", matchArray);

                                        j?.tag?.map((i) => {
                                          i?.match_tag?.map((j) => {
                                            console.log(
                                              "DDDDDDDDDDDDD",
                                              k?.text
                                            );
                                            if (j?.search(" ") !== -1) {
                                              console.log("kTextkText", j);
                                              j =
                                                k?.text?.search("-") !== -1
                                                  ? j?.replace(/\s/, "-")
                                                  : j;
                                              k.text = k.text
                                                ?.toLowerCase()
                                                ?.replace(
                                                  new RegExp(j, "g"),
                                                  j.replace(/\s/g, "-")
                                                );

                                              console.log(
                                                "ZZZZZZZZZZZZZZZZZZ",
                                                k.text?.search(j)
                                              );
                                            }
                                          });
                                        });

                                        console.log(
                                          "dataaaaaaaaaaaaaa",
                                          k?.text
                                        );

                                        return (
                                          <div
                                            key={kIndex}
                                            onClick={() =>
                                              timeUpdate(
                                                k.sentence_start,
                                                k.sentence_end,
                                                allCalls?.filter(
                                                  (i) =>
                                                    i?._id === conversationId
                                                )[0].diarize_transcript?.length,
                                                kIndex
                                              )
                                            }
                                          >
                                            <h5
                                              onClick={() =>
                                                highLightChatBox(
                                                  k.sentence_start,
                                                  kIndex
                                                )
                                              }
                                              className={
                                                currentTime >=
                                                  k.sentence_start &&
                                                currentTime <= k.sentence_end
                                                  ? "transcript-text active"
                                                  : "transcript-text"
                                              }
                                            >
                                              <span style={{ fontWeight: 700 }}>
                                                {k?.type} :{" "}
                                              </span>
                                              <span
                                                style={{ textAlign: "justify" }}
                                              >
                                                {transcriptClickStatus ===
                                                  "TranscriptClicked" &&
                                                j?.tag?.some((storeTag) =>
                                                  storeAllTags?.some(
                                                    (tag) =>
                                                      tag.tag_name ===
                                                      storeTag.label
                                                  )
                                                ) ? (
                                                  <React.Fragment>
                                                    {console.log("PPPPPPPPPPP")}
                                                    {k?.text
                                                      .split(/[_ .,]/)
                                                      .map((word, index) => {
                                                        // Check if the word is in matchArray

                                                        if (
                                                          matchArray?.some(
                                                            (i) =>
                                                              i?.match_tag?.includes(
                                                                word
                                                                  ?.replace(
                                                                    /-/g,
                                                                    " "
                                                                  )
                                                                  ?.toLowerCase()
                                                              )
                                                          )
                                                        ) {
                                                          return (
                                                            <span
                                                              key={index}
                                                              style={{
                                                                backgroundColor:
                                                                  "yellow",
                                                                fontWeight:
                                                                  "bold",
                                                              }}
                                                            >
                                                              {word?.replace(
                                                                /-/g,
                                                                " "
                                                              ) + " "}
                                                            </span>
                                                          );
                                                        } else {
                                                          console.log(
                                                            "dfffdfdfdfdf"
                                                          );
                                                          return (
                                                            <React.Fragment
                                                              key={index}
                                                            >
                                                              {" " +
                                                                word?.replace(
                                                                  /-/g,
                                                                  " "
                                                                )}{" "}
                                                            </React.Fragment>
                                                          );
                                                        }
                                                      })}
                                                  </React.Fragment>
                                                ) : (
                                                  <React.Fragment>
                                                    {console.log(
                                                      "k?.text",
                                                      k?.text
                                                    )}
                                                    {k?.text
                                                      .split(/[_ .,]/)
                                                      .map((word, index) => {
                                                        console.log(
                                                          "WWWWWWWWWWWW123",
                                                          j?.tag?.filter(
                                                            (i) =>
                                                              i?.tag_name ===
                                                              tagNameForTrans
                                                          )
                                                        );
                                                        // Check if the word is in matchArray
                                                        console.log(
                                                          "LELELELELLELE",
                                                          word
                                                        );
                                                        if (
                                                          j?.tag
                                                            ?.filter(
                                                              (i) =>
                                                                i?.tag_name ===
                                                                tagNameForTrans
                                                            )
                                                            ?.some((i) =>
                                                              i?.match_tag?.includes(
                                                                word
                                                                  ?.replace(
                                                                    /-/g,
                                                                    " "
                                                                  )
                                                                  ?.toLowerCase()
                                                              )
                                                            )
                                                        ) {
                                                          console.log(
                                                            "UYTRTYUIOUYT"
                                                          );
                                                          return (
                                                            <span
                                                              key={index}
                                                              style={{
                                                                backgroundColor:
                                                                  "yellow",
                                                                fontWeight:
                                                                  "bold",
                                                              }}
                                                            >
                                                              {word?.replace(
                                                                /-/g,
                                                                " "
                                                              ) + " "}
                                                            </span>
                                                          );
                                                        } else {
                                                          console.log(
                                                            "KJHJKLKJHJK",
                                                            word?.replace(
                                                              /-/g,
                                                              " "
                                                            )
                                                          );
                                                          return (
                                                            <React.Fragment
                                                              key={index}
                                                            >
                                                              {" " +
                                                                word?.replace(
                                                                  /-/g,
                                                                  " "
                                                                )}{" "}
                                                            </React.Fragment>
                                                          );
                                                        }
                                                      })}
                                                  </React.Fragment>
                                                )}

                                                <br />
                                                {k?.event_silence_time > 0 && (
                                                  <span>
                                                    [
                                                    <b>
                                                      SILENCE :{" "}
                                                      {Math.round(
                                                        k?.event_silence_time
                                                      )}{" "}
                                                    </b>
                                                    ]
                                                  </span>
                                                )}
                                              </span>
                                            </h5>
                                            <hr />
                                          </div>
                                        );

                                        console.log("TRANSCRIPT", k?.text);
                                      }
                                    );
                                  })
                                ) : (
                                  // callInfo?.map((j, jIndex) => {
                                  //     return j?.diarize_transcript?.map((k, kIndex) => {
                                  //         const searchText = k?.text?.toLowerCase();
                                  //         let highlightedText = k?.text; // Initialize with the original text
                                  //         console.log("searchText",highlightedText);
                                  //         // Iterate through applications and match_appl_phrase
                                  //         j?.tag && j?.tag?.filter?.((tagName) => {
                                  //             if (addNamePhrase !== "") {
                                  //                 console.log("UUUUUUUUUUUU", tagName?.tag_name === addNamePhrase);
                                  //                 return tagName?.tag_name === addNamePhrase;
                                  //             }
                                  //             else {
                                  //                 // allMatches?.push(storeAllTags?.filter(i=>i?.label===appName?.application_name))
                                  //                 console.log("111111111111", tagName?.tag_name !== addNamePhrase && tagName?.tag_name);
                                  //                 return tagName?.tag_name !== addNamePhrase && filterShowPhrase?.filter(i => i?.label === tagName?.tag_name) && tagName?.tag_name
                                  //             }
                                  //         })
                                  //             ?.forEach((m) => {
                                  //                 console.log("LLLLLLLLLLLL", addNamePhrase);
                                  //                 m?.match_tag && m?.match_tag?.forEach((n, index) => {
                                  //                     const searchTerm = n !== null && n?.toLowerCase();
                                  //                     if (addNamePhrase !== "" && filterShowPhrase?.length === 0 && searchText?.split(/[_ .,]/).includes(searchTerm?.split(" ").find(k => k))) {
                                  //                         console.log("OOOOOOOOO",searchText?.split(" "));
                                  //                         const parts = searchText.split(searchTerm);
                                  //                         highlightedText = (
                                  //                             <span key={index}>
                                  //                                 {parts[0]}
                                  //                                 <span style={{ backgroundColor: "#ffff00", fontWeight: "600" }}>{n}</span>
                                  //                                 {parts[1]}
                                  //                             </span>
                                  //                         );
                                  //                     }
                                  //                     else if (addNamePhrase === "" && filterShowPhrase?.length > 0 && searchText?.split(/[_ .,]/).includes(searchTerm?.split(" ").find(k => k))) {
                                  //                         let highlightedTextArray = [];
                                  //                         const filteredTags = filterShowPhrase?.map(i => i?.label)
                                  //                         const matchingObjects = filteredTags?.map((tagName) => {
                                  //                             return j?.tag?.find((tag) => tag?.tag_name === tagName);
                                  //                         });
                                  //                         matchingObjects?.forEach((mp) => {
                                  //                             console.log("mpmpmp", mp);
                                  //                             mp?.match_tag?.forEach((dmp, index) => {
                                  //                                 const searchTermLowerCase = dmp !== null && dmp?.toLowerCase();
                                  //                                 let currentIndex = 0;
                                  //                                 while (currentIndex < searchText.length) {
                                  //                                     const startIndex = searchText.indexOf(searchTermLowerCase, currentIndex);

                                  //                                     if (startIndex === -1) {
                                  //                                         // If no more matches found, push the remaining text
                                  //                                         highlightedTextArray.push(
                                  //                                             <span key={`remaining-${currentIndex}`}>
                                  //                                                 {searchText.substring(currentIndex)}
                                  //                                             </span>
                                  //                                         );
                                  //                                         break;
                                  //                                     }

                                  //                                     const before = searchText.substring(currentIndex, startIndex);
                                  //                                     const match = searchText.substr(startIndex, searchTermLowerCase.length);
                                  //                                     currentIndex = startIndex + searchTermLowerCase.length;

                                  //                                     highlightedTextArray.push(
                                  //                                         <span key={index}>
                                  //                                             {before}
                                  //                                             <span style={{ backgroundColor: "#ffff00", fontWeight: "600" }}>{match}</span>
                                  //                                         </span>
                                  //                                     );
                                  //                                 }
                                  //                             })
                                  //                         })
                                  //                         highlightedText = highlightedTextArray
                                  //                     }
                                  //                     else if (addNamePhrase !== "" && filterShowPhrase?.length > 0 && searchText?.split(/[_ .,]/).includes(searchTerm?.split(" ").find(k => k))) {
                                  //                         console.log("8888888888888");
                                  //                         const parts = searchText.split(searchTerm);
                                  //                         highlightedText = (
                                  //                             <span key={index}>
                                  //                                 {parts[0]}
                                  //                                 <span style={{ backgroundColor: "#ffff00", fontWeight: "600" }}>{n}</span>
                                  //                                 {parts[1]}
                                  //                             </span>
                                  //                         );
                                  //                     }

                                  //                 });

                                  //             });
                                  //         //matchingObjects

                                  //         return (
                                  //             <div key={kIndex} onClick={() => timeUpdate(k.sentence_start, k.sentence_end, allCalls?.filter(i => i?._id === conversationId)[0].diarize_transcript?.length, kIndex)}>
                                  //                 <h5
                                  //                     onClick={() => highLightChatBox(k.sentence_start, kIndex)}
                                  //                     className={currentTime >= k.sentence_start && currentTime <= k.sentence_end ? "transcript-text active" : "transcript-text"}
                                  //                 >
                                  //                     <span style={{ fontWeight: 700 }}>{k?.type} : </span>
                                  //                     <span style={{ textAlign: "justify" }}>

                                  //                         {highlightedText}<br/>
                                  //                         {k?.event_silence_time > 0 && <span>[<b>SILENCE : {Math.round(k?.event_silence_time)} </b>]</span>}

                                  //                     </span>
                                  //                 </h5>
                                  //                 <hr />
                                  //             </div>
                                  //         );
                                  //     });
                                  // })

                                  <p
                                    style={{
                                      textAlign: "center",
                                      fontWeight: "600",
                                      fontSize: "12px",
                                    }}
                                  >
                                    No Transcript Found
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {isLoading && <div className="page-loader align-items-center justify-content-center loading">
                <div className="loader-dots d-flex align-items-center justify-content-center">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>} */}
    </main>
  );
};
export default SSCallListNew;
